/**
 * @file
 * Adds Carousel Block functionality.
 */

(function ($) {

  'use strict';

  /**
   * Attach Section Row Init function to Window Load event.
   */
  $(window).on('load', carouselBlockInit);

  /**
   * Initializes Carousel Blocks.
   */
  function carouselBlockInit() {
    $('.press-release-block').each(carouselBlockItemInit);
  }

  /**
   * Inits a Carousel Block Instance.
   */
  function carouselBlockItemInit(i, item) {

    var paramsRight = {
      slides: '.right-slide',
      fx: 'carousel',
      swipe: true,
      speed: 500,
      paused: true,
      caption: '.caption-template',
      captionTemplate: '<span class="pagination-count">{{slideNum}}</span><span class="pagination-divider">/</span><span class="pagination-limit">{{slideCount}}</span>',
      log: false
    };

    var cycleRight = $(item).find('.right-slider').cycle(paramsRight);

    $(item).find('button.left-arrow').on('click', function () {
      $(cycleRight).cycle('prev');
    });

    $(item).find('button.right-arrow').on('click', function () {
      $(cycleRight).cycle('next');
    });
  }

  /**
   * Resets all Cycle Instances.
   */
  function carouselCycleResetAll() {
    $('.press-release-block').each(carouselCycleReset);
  }

  /**
   * Resets a Cycle Instance.
   */
  function carouselCycleReset(i, item) {
    var paramsRight = {
      slides: '.right-slide',
      fx: 'carousel',
      swipe: true,
      speed: 500,
      paused: true,
      caption: '.caption-template',
      captionTemplate: '<span class="pagination-count">{{slideNum}}</span><span class="pagination-divider">/</span><span class="pagination-limit">{{slideCount}}</span>',
      log: false
    };

    $(item).find('.right-slider').cycle('destroy');
    $(item).find('.right-slider').cycle(paramsRight);
  }

  /**
     * Resets Cycle.
     */
    var resizeTimer;
    $(window).on('resize', function () {
      clearTimeout(resizeTimer);

      resizeTimer = setTimeout(function () {
        var widthW = parseInt($(window).width(), 10);

        if(widthW <= 1023) {
          carouselCycleResetAll();
        }
      }, 500);
    });

  /**
   * Resets Cycle Instances on breakpoint-change.
   */
  $(document).on('breakpoint-change', function () {
    carouselCycleResetAll();
  });

})(jQuery);
