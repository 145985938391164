(function ($){

  'use strict';

  /**
   * Avoids the sidebar to be over the footer.
   */
  function locateSidebar() {
    // Declare variables.
    if ($('.sidebar-menu').length < 1) {
      return false;
    }

    var windowTop = $(window).scrollTop();
    var footerTop = $('.footer').offset().top;
    var headerHeight = $('header').height();
    var sidebarTop = $('.top-sidebar').offset().top;
    var sideBarWrapperHeight = $(document).find('.sidebar-wrapper').height();
    var heroHeight = 0;
    var positionHeight = 0;
    var firstRowHeight = $(document).find('.first-row .section-row').height();
    var bodyMargin = parseInt($('body').css('margin-top').replace('px', ''));

    if (heroHeight == null){
      heroHeight = 0;
      positionHeight = positionHeight - sidebarTop;
    }else {
      positionHeight = heroHeight;
    }

    if ((windowTop + headerHeight) >= sidebarTop){
      if ((windowTop + headerHeight + 70 + sideBarWrapperHeight) >= footerTop){
        $('.sidebar-menu').removeClass('stick');
        $('.sidebar-menu').addClass('position-absolute');
        $('.sidebar-menu').css({top: (footerTop - sideBarWrapperHeight - 70 - firstRowHeight - 76 - bodyMargin)});
      } else {
        $('.sidebar-menu').addClass('stick');
        $('.sidebar-menu').removeClass('position-absolute');
        $('.sidebar-menu').removeAttr('style');
      }
    } else {
      $('.sidebar-menu').removeClass('stick');
      $('.sidebar-menu').addClass('position-absolute');
      $('.sidebar-menu').removeAttr('style');
    }
  }

  $(document).on('click', '.sidebar-menu .sidebar-wrapper .links li .link-heading', function () {
    $(document).find('.sidebar-menu .sidebar-wrapper .links li .link-heading').removeClass('active');
    var block = $(document).find('.section-row[data-id="' + $(this).attr('data-href') + '"]');
    $(this).addClass('active');
    var headerHeight = $('header').height();
    var positionTop = $(block).offset().top;

    // Animate the scrolling.
    $('html, body').animate({
      scrollTop: positionTop - headerHeight
    }, 500);
  });

  $(window).on('scroll', function () {
    locateSidebar();
  });

})( jQuery );
