/**
 * @file
 * Generic Page - Adds Image Gallery Slider Block functionality.
 */

$(function ($) {
  'use strict';

  $(window).on('load', initImageGallerySlider);

  /**
   * Initializes Image Gallery Slider Blocks.
   */
  function initImageGallerySlider() {
    $('.modal-featured-content').each(initImageSlides);
  }

  /**
   * Inits a Image Gallery Slider Block Instance.
   */
  function initImageSlides(i, item) {
    var slides = $(item).find('.slider-images .slide-image');
    var captionTemplate = $(item).find('.caption-featured-template');
    var options = {
      slides: slides,
      fx: 'scrollHorz',
      swipe: true,
      carouselVisible: 1,
      carouselFluid: false,
      caption: captionTemplate,
      captionTemplate: '<span class="pagination-count">{{slideNum}}</span><span>/</span><span class="pagination-limit">{{slideCount}}</span>',
      speed: 250,
      paused: true,
    };

    var cycle = $(item).find('.slider-images').cycle(options);

    $(item).find('button.left-arrow').on('click', function () {
      $(cycle).cycle('prev');
    });

    $(item).find('button.right-arrow').on('click', function () {
      $(cycle).cycle('next');
    });
  }
});
