/**
 * @file
 * Adds Article Teaser Slider.
 */

(function ($) {

  'use strict';

  /**
   * Attach Article Teaser Slider.
   */
  $(window).on('load', articleTeaserSliderInit);

  /**
   * Initializes Article Teaser Slider.
   */
  function articleTeaserSliderInit() {
    setTimeout(function () {
      $(document).find('.article-teaser-slider').each(articleTeaserSliderInitItem);
    }, 350);
  }

  /**
   * Ajax Restart callback.
   */
  $(window).on('ajax-restart', function () {
    articleTeaserSliderInit();
  });

  /**
   * Inits an Article Teaser Slider Instance.
   */
  function articleTeaserSliderInitItem(i, item) {
    if ($(item).hasClass('setup-ready')) {
      return false;
    }

    var cycleWrapper = $(item).closest('.article-teaser-slider');
    var pagination = $(cycleWrapper).find('.pagination');
    var currentpage = $(pagination).find('.scroll-content .page.active:last');
    var current = $(currentpage).find('.slider-page').attr('data-page');
    var btnStoryContent = $('.article-teaser').find('.slider-wrapper .slider-items .article-slide-item .scroll-box .story-content');

    if (current == 1) {
      $(btnStoryContent).find('.prev').prop('disabled', true);
      $(btnStoryContent).find('.prev').addClass('disabled');
      $(btnStoryContent).find('.next').prop('disabled', false);
      $(btnStoryContent).find('.next').removeClass('disabled');
    }

    $(item).addClass('setup-ready');

    var params = {
      slides: '.article-slide-item',
      fx: 'fade',
      swipe: true,
      carouselVisible: 1,
      carouselFluid: false,
      speed: 350,
      paused: true,
      allowWrap: false,
      log: false
    };

    $(item).find('.slider-items').cycle(params);

    $(document).on('keyup', function (evt) {
      if (evt.keyCode == 27) {
        $(item).find('.slider-items').cycle('goto', 0);
        var btnStoryContent = $('.article-teaser').find('.slider-wrapper .slider-items .article-slide-item .scroll-box .story-content');
        var pagination = $(cycleWrapper).find('.pagination');
        var currentpage = $(pagination).find('.scroll-content .page.active:last');
        var containerScrollBar = $(pagination).find('.scroll-content');
        $(containerScrollBar).find('.page').removeClass('active');
        $(containerScrollBar).find('.page.first-page').addClass('active');

        if (current == 1) {
          $(btnStoryContent).find('.prev').prop('disabled', true);
          $(btnStoryContent).find('.prev').addClass('disabled');
          $(btnStoryContent).find('.next').prop('disabled', false);
          $(btnStoryContent).find('.next').removeClass('disabled');
        }
      }
    });

   $(document).on('click', '.modal .material-icons', function(){
     $(item).find('.slider-items').cycle('goto', 0);

     var btnStoryContent = $('.article-teaser').find('.slider-wrapper .slider-items .article-slide-item .scroll-box .story-content');
     var pagination = $(cycleWrapper).find('.pagination');
     var currentpage = $(pagination).find('.scroll-content .page.active:last');
     var containerScrollBar = $(pagination).find('.scroll-content');
     $(containerScrollBar).find('.page').removeClass('active');
     $(containerScrollBar).find('.page.first-page').addClass('active');

     if (current == 1) {
       $(btnStoryContent).find('.prev').prop('disabled', true);
       $(btnStoryContent).find('.prev').addClass('disabled');
       $(btnStoryContent).find('.next').prop('disabled', false);
       $(btnStoryContent).find('.next').removeClass('disabled');
     }
   })

   $(document).on('click', '.modal .close-article', function(){
     $(item).find('.slider-items').cycle('goto', 0);

     var btnStoryContent = $('.article-teaser').find('.slider-wrapper .slider-items .article-slide-item .scroll-box .story-content');
     var pagination = $(cycleWrapper).find('.pagination').cycle('goto', 0);
     var currentpage = $(pagination).find('.scroll-content .page.active:last');
     var containerScrollBar = $(pagination).find('.scroll-content');
     $(containerScrollBar).find('.page').removeClass('active');
     $(containerScrollBar).find('.page.first-page').addClass('active');

     if (current == 1) {
       $(btnStoryContent).find('.prev').prop('disabled', true);
       $(btnStoryContent).find('.prev').addClass('disabled');
       $(btnStoryContent).find('.next').prop('disabled', false);
       $(btnStoryContent).find('.next').removeClass('disabled');
     }
   })

   $(document).on('click', '.modal .close-featured-stories', function(){
     $(item).find('.slider-items').cycle('goto', 0);

     var btnStoryContent = $('.article-teaser').find('.slider-wrapper .slider-items .article-slide-item .scroll-box .story-content');
     var pagination = $(cycleWrapper).find('.pagination').cycle('goto', 0);
     var currentpage = $(pagination).find('.scroll-content .page.active:last');
     var containerScrollBar = $(pagination).find('.scroll-content');
     $(containerScrollBar).find('.page').removeClass('active');
     $(containerScrollBar).find('.page.first-page').addClass('active');

     if (current == 1) {
       $(btnStoryContent).find('.prev').prop('disabled', true);
       $(btnStoryContent).find('.prev').addClass('disabled');
       $(btnStoryContent).find('.next').prop('disabled', false);
       $(btnStoryContent).find('.next').removeClass('disabled');
     }
   })
  }

  /**
   * Click callback for Prev Button.
   */
  $(document).on('click', '.article-teaser-slider .pagination button.prev', function (e) {
    e.preventDefault();

    var cycle = $(this).closest('.article-teaser-slider').find('.slider-items');

    if ($(this).closest('.article-slide-item').hasClass('cycle-slide')) {
      $(cycle).cycle('prev');
    } else {
      articleTeaserSliderInitItem(1, $(this).closest('.article-teaser-slider'));
      $(cycle).cycle('prev');
    }
  });

  $(document).on('click', '.article-teaser .slider-wrapper .slider-items .article-slide-item .scroll-box .story-content .prev', function (e) {
    e.preventDefault();

    var cycle = $(this).closest('.article-teaser-slider').find('.slider-items');
    var cycleWrapper = $(this).closest('.article-teaser-slider');
    var pagination = $(cycleWrapper).find('.pagination');
    var currentpage = $(pagination).find('.scroll-content .page.active:last');
    var countpage = $(cycleWrapper).find('.pagination .scroll-content .page:last');
    var count = $(countpage).find('.slider-page').attr('data-page');
    var current = $(currentpage).find('.slider-page').attr('data-page');
    var btnStoryContent = $('.article-teaser').find('.slider-wrapper .slider-items .article-slide-item .scroll-box .story-content');

    if (current == 2) {
      $(btnStoryContent).find('.prev').prop('disabled', true);
      $(btnStoryContent).find('.prev').addClass('disabled');
    }

    if (current == count) {
      $(btnStoryContent).find('.next').prop('disabled', false);
      $(btnStoryContent).find('.next').removeClass('disabled');
    }

    if ($(this).closest('.article-slide-item').hasClass('cycle-slide')) {
      $(cycle).cycle('prev');
    } else {
      articleTeaserSliderInitItem(1, $(this).closest('.article-teaser-slider'));
      $(cycle).cycle('prev');
    }
  });

  /**
   * Click callback for Next Button.
   */
  $(document).on('click', '.article-teaser-slider .pagination button.next', function (e) {
    e.preventDefault();

    var cycle = $(this).closest('.article-teaser-slider').find('.slider-items');

    if ($(this).closest('.article-slide-item').hasClass('cycle-slide')) {
      $(cycle).cycle('next');
    } else {
      articleTeaserSliderInitItem(1, $(this).closest('.article-teaser-slider'));
      $(cycle).cycle('next');
    }
  });

  $(document).on('click', '.article-teaser .slider-wrapper .slider-items .article-slide-item .scroll-box .story-content .next', function (e) {
    e.preventDefault();

    var cycle = $(this).closest('.article-teaser-slider').find('.slider-items');
    var cycleWrapper = $(this).closest('.article-teaser-slider');
    var pagination = $(cycleWrapper).find('.pagination');
    var currentpage = $(pagination).find('.scroll-content .page.active:last');
    var countpage = $(cycleWrapper).find('.pagination .scroll-content .page:last');
    var count = parseInt($(countpage).find('.slider-page').attr('data-page'), 10);
    var current = parseInt($(currentpage).find('.slider-page').attr('data-page'), 10);
    count = count - 1;
    var btnStoryContent = $('.article-teaser').find('.slider-wrapper .slider-items .article-slide-item .scroll-box .story-content');

    if (current < 1) {
      $(btnStoryContent).find('.prev').prop('disabled', true);
      $(btnStoryContent).find('.prev').addClass('disabled');
    } else {
      $(btnStoryContent).find('.prev').prop('disabled', false);
      $(btnStoryContent).find('.prev').removeClass('disabled');
    }

    if (current == count) {
      $(btnStoryContent).find('.next').prop('disabled', true);
      $(btnStoryContent).find('.next').addClass('disabled');
    }

    if ($(this).closest('.article-slide-item').hasClass('cycle-slide')) {
      $(cycle).cycle('next');
    } else {
      articleTeaserSliderInitItem(1, $(this).closest('.article-teaser-slider'));
      $(cycle).cycle('next');
    }
  });

  /**
   * Attach function to cycle-before event.
   */
  $(document).on('cycle-before', function (event, optionHash, outgoingSlideEl, incomingSlideEl, forwardFlag) {
    var cycleWrapper = $(incomingSlideEl).closest('.article-teaser-slider');
    var pagination = $(cycleWrapper).find('.pagination');
    var current = parseInt(optionHash.slideNum, 10);
    var currentPage = $(pagination).find('.slider-page[data-page="' + current + '"]').parent();

    $(currentPage).addClass('active');

    if (!forwardFlag) {
      var previous = current + 1;
      var previousPage = $(cycleWrapper).find('.pagination .slider-page[data-page="' + previous + '"]').parent();
      $(previousPage).removeClass('active');
    }

    // Update scroll position of pagination.
    articleTeaserSliderPagePositionUpdate(pagination, current);
  });

  /**
   * Updates scroll position of Pagination.
   */
  function articleTeaserSliderPagePositionUpdate(pagination, page) {
    var scrollArea = $(pagination).find('.scroll-area');
    var wWidth = parseInt($(window).width(), 10);
    var wWidthHalf = parseInt(wWidth / 2);
    var itemWidth = 210;
    var scroll;

    var left = parseInt(25 + (page * itemWidth) - (itemWidth / 2), 10);

    if (left <= wWidthHalf) {
      scroll = 0;
    }
    else {
      scroll = left - wWidthHalf;
    }

    // Set Scroll.
    $(scrollArea).stop().animate({scrollLeft: scroll});
  }

})(jQuery);
