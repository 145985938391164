/**
 * @file
 * Adds margin functionality to body.
 */

(function ($) {
  'use strict';

  $(window).on('load', headerSpacing);

  /**
   * This function obtains the height of header and the notification
   * and apply a spacing for both elements
   */
  function headerSpacing(){
    var sectionRows = $(document).find('.section-rows');

    if (!$(sectionRows).hasClass('slide-cover-effect')) {
      $(document).find('body').addClass('not-animation');
      var obtainHeight = $(document).find('.notifications-block').innerHeight();

      var headerHeight = $(document).find('header').innerHeight();
      var marginOffset = headerHeight + obtainHeight;

      $(document).find('body').css({'margin-top' : marginOffset });

      /**
       *This section is for the event after close the notification resize the header
       */
      $(document).on('click','.notifications-block .bar-wrap .bar .close', function () {
        obtainHeight = $(document).find('.notifications-block').innerHeight();
        marginOffset = headerHeight + obtainHeight;
        $(document).find('body').css({'margin-top' : marginOffset });
      });
    }
  }

  $(document).on('click', '#mainnav .document-finder .circle button', function () {
    $(this).toggleClass('active');
  });

  $(document).on('click', 'header .navbar .menu-opener', function () {
    $(document).find('body').addClass('fixed');
  });

  $(document).on('click', '.megamenu .mainnav-close', function () {
    $(document).find('body').removeClass('fixed');
  });

})(jQuery);
