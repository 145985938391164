/**
 * @file
 * Adds Carousel Functionality for the Hero at Home.
 */

(function ($) {

  'use strict';

  /**
   * Attach Carousel Functionality to Hero Home.
   */
  $(window).on('load', heroCarouselInit);

  /**
   * Initializes Carousel for Hero Home.
   */
  function heroCarouselInit() {
    $('.hero-home').each(heroCarouselInitItem);
  }

  /**
   * Inits a Carousel Hero Home Instance.
   */
  function heroCarouselInitItem(i, item) {
    var wWidth = parseInt($(window).width(), 10);
    var cycleFx;
    if (wWidth < 768) {
      cycleFx = 'scrollHorz';
    }
    else {
      cycleFx = 'fade';
    }

    var params = {
      slides: '.hero-slide-item',
      fx: cycleFx,
      swipe: true,
      carouselVisible: 1,
      carouselFluid: false,
      speed: 350,
      paused: true,
      allowWrap: false,
      log: false
    };

    $(item).find('.slide-items').cycle(params);
  }

  /**
   * Click callback for the Hero Home Panel left Options.
   */
  $(document).on('click', '.hero-home .featured-stories .featured-stories-wrapper .related-stories .related-story-item', function (e) {
    e.preventDefault();

    var dataPage = parseInt($(this).attr('data-page'));
    var cycle = $(this).closest('.hero-home').find('.slide-items');
    $(cycle).cycle('goto', dataPage);
  });

  /**
   * Click callback for the Hero Home Pagination buttons.
   */
  $(document).on('click', '.hero-home .pagination button.page', function (e) {
    e.preventDefault();

    var page = parseInt($(this).attr('data-page')) - 1;
    var cycle = $(this).closest('.hero-home').find('.slide-items');
    $(cycle).cycle('goto', page);

    // Deactivate previous page.
    /*
    $(this).parent().find('.active').removeClass('active');
    $(this).addClass('active');
    */
  });

  /**
   * Attach function to cycle-before event.
   */
  $(document).on('cycle-before', function (event, optionHash, outgoingSlideEl, incomingSlideEl, forwardFlag) {
    var count = parseInt(optionHash.slideCount, 10);
    var i = parseInt(optionHash.slideNum, 10);
    var j;
    if (forwardFlag) {
      j = i + 1;

      if (i == count) {
        j = 1;
      }
    }
    else {
      j = i - 2;

      if (j == 0) {
        j = count;
      }
      else if (j == -1) {
        j = count - 1;
      }
    }

    var cycle = $(outgoingSlideEl).closest('.slide-items');
    var prev = $(cycle).find('.slide-item.active');
    $(prev).removeClass('active');

    var next = $(cycle).find('.slide-item[data-page="' + j + '"]');
    $(next).addClass('active');

    // Update Pagination.
    $(cycle).parent().parent().find('.pagination button.page.active').removeClass('active');
    $(cycle).parent().parent().find('.pagination button.page[data-page="' + i + '"]').addClass('active');
  });

  /**
   * Resets all Cycle Instances.
   */
  function carouselCycleResetAll() {
    $('.carousel-block').each(carouselCycleReset);
  }

  /**
   * Resets a Cycle Instance.
   */
  function carouselCycleReset(i, item) {
    var params = {
      slides: '.slide-item',
      fx: 'carousel',
      swipe: true,
      carouselVisible: 3,
      carouselFluid: false,
      speed: 250,
      paused: false,
      log: false
    };

    $(item).find('.slide-items').cycle('destroy');
    $(item).find('.slide-items').cycle(params);
  }

  /**
   * Resets Cycle Instances on breakpoint-change.
   */
  $(document).on('breakpoint-change', function () {
    carouselCycleResetAll();
  });

})(jQuery);
