/**
 * @file
 * Bio Block Functionality.
 */

(function ($) {

  'use strict';

  /**
   * Attach function to Window Load event.
   */
  $(window).on('load', setModals());

  /**
   * Set function to change modal.
   */
  function setModals() {
    if (window.location.hash != '') {
      if (window.location.hash.indexOf('modal-bio-detail-content') == -1) {

      } else {
        setTimeout(function () {
          var notificationHeight = $(document).find('.notifications-block').height();
          var headerHeight = $(document).find('header').height();
          var positionTop = $(document).find('a[href="' + window.location.hash + '"]').closest('.card-bio').offset().top - ( notificationHeight + headerHeight );

          $('html, body').animate({
            scrollTop: positionTop
          }, 300);

          setTimeout(function () {
            $(document).find('a[href="' + window.location.hash + '"]').first().click();
          }, 1200);
        }, 100);
      }
    }

    var idBlock;
    $(document).on('click', '.bioBlock .card-bio .bio-generic .bio-info .add-circle-icon-bio', function () {
      idBlock = $(this).closest('.section-block').attr('id');
      $(this).modal({
        fadeDuration: 350,
        fadeDelay: 0.50
      });
    });

    $(document).on('click', '.jquery-modal .modal-bio-detail .modal-dialog .modal-content .modal-footer-content button', function (e) {
      e.preventDefault();
      var id = $(this).attr('data-id-previous-element') != undefined ? $(this).attr('data-id-previous-element') : $(this).attr('data-id-next-element');
      var parentElement = $('body').find('main #main-container .section-rows .bioBlock');

      $(parentElement).find('.card-bio').each(function () {
        if ($(this).find('.add-circle-icon-bio').attr('href').substring(1) == id) {
          $(document).find($(this).find('.add-circle-icon-bio').attr('href')).modal({
            fadeDuration: 350,
            fadeDelay: 0.50
          });
        }
      });
    });
  }

})(jQuery);

