/**
 * @file
 * Hero image get height and width functionality.
 */

(function ($) {

  'use strict';

  /**
   * Start functionality that gives padding to the image.
   */
  $(document).on('load', $(document).find('.hero-article-block').each(heroBlogPost));

  function heroBlogPost(index, item) {
    var slides = $(item).find('.slide-items .slide-item');
    var options = {
      slides: slides,
      fx: 'scrollHorz',
      swipe: true,
      carouselVisible: 1,
      carouselFluid: false,
      speed: 250,
      paused: true,
      log: false,
    };

    var cycle = $(item).find('.slide-items').cycle(options);

    $(item).find('.hero-article-arrow-buttons .arrow-left').on('click', function () {
      $(cycle).cycle('prev');
    });

    $(item).find('.hero-article-arrow-buttons .arrow-right').on('click', function () {
      $(cycle).cycle('next');
    });

    $(item).find('.dots .dot').on('click', function () {
      var page = parseInt($(this).attr('data-dot')) - 1;
      var cycle = $(this).closest('.hero-article-block').find('.slide-items');
      $(cycle).cycle('goto', page);
    });
  }
})(jQuery);
