/**
 * @file
 * Adds functionality to get the page type.
 */

(function ($) {
  'use strict';

  $(window).on('load', setModalNewsroom);

  /**
   * This function obtains the page type.
   *
   */
  function setModalNewsroom(){
    var mainTag = $(document).find('main');

    $(document).on('click', '.hero-with-cta button',function () {
      var formCloned = ($(mainTag).find('.section-rows .shortHeroBlock .newsroomUpdatesForm')).clone();
      $(mainTag).find('.section-rows').append(formCloned);
      $(mainTag).find('.section-rows .shortHeroBlock .newsroomUpdatesForm').addClass('d-none');
      $(mainTag).find('.section-rows > .newsroomUpdatesForm').addClass('open');
      $(mainTag).find('.section-rows > .newsroomUpdatesForm').removeClass('d-none');
      $('html').addClass('fixed');
      $('body').append('<a href="" class="overlay-form"></a>');
    });

    $(document).on('click', '.formulate__field--button button', function (e) {
      var email = $(this).closest('.formulate__form').find('.formulate__field--text input').val();

      if ($(this).closest('.formulate__form').find('.formulate__field--text input').val() != '' && isEmail(email)) {
        // empty
        $.ajax({
          url: '/umbraco/surface/ConstantContactIntegration/CreateContact?email=' + email,
          type: 'post',
          success: function (data) {
            console.log(data);
          },
          error: function () {
            console.log('there was an error.');
          }
        });

      } else {
        e.preventDefault();
        if ($(this).closest('.formulate__form').find('.formulate__field--text .error-message').length <= 0) {
          $(this).closest('.formulate__form').find('.formulate__field--text').append('<div class="error-message">Enter a valid email address</div>');
        }
      }
    });

    $(document).on('click', '.overlay-form', function (e) {
      e.preventDefault();
      $('html').removeClass('fixed');
      $(mainTag).find('.section-rows > .newsroomUpdatesForm').removeClass('open');
      $(mainTag).find('.section-rows > .newsroomUpdatesForm').remove();
      $(this).remove();
    });

    $(document).on('click', '.newsroomUpdatesForm .newsroom-updates button.close', function (e) {
      e.preventDefault();
      $('html').removeClass('fixed');
      $(mainTag).find('.section-rows > .newsroomUpdatesForm').removeClass('open');
      $(mainTag).find('.section-rows > .newsroomUpdatesForm').remove();
      $('.overlay-form').remove();
    });

    function isEmail(email) {
      var regex = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      return regex.test(email);
    }
  }

})(jQuery);
