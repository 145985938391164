/**
  *@file
  *Adds Accordion Block functionality.
*/

$(function ($) {
  'use strict';

  $(window).on('load', accordionInit);

  function accordionInit() {
    $(document).on('click', '.accordion-generic-block .accordion .card button', function (e) {
      e.preventDefault();

      var isCollapsed = !$(this).hasClass('collapsed');

      if (isCollapsed) {
        $(document).find('.card').removeClass('active');
        $(this).closest('.card').addClass('active');
      } else {
        $(this).closest('.card').removeClass('active');
      }
    });
  }
});
