/**
 * @file
 * Adds Breakpoint functionality.
 */

(function ($) {

  'use strict';

  /**
   * Attach Section Row Init function to Window Load event.
   */
  $(window).on('load', breakpointInit);

  window.breakpoint = '';

  /**
   * Initializes Breakpoint.
   */
  function breakpointInit() {
    var wWidth = parseInt($(window).width(), 10);
    window.breakpoint = getBreakpoint(wWidth);
  }

  /**
   * Sets a timer for resize event.
   */
  var breakpointTimer;
  $(window).on('resize', function () {
    clearTimeout(breakpointTimer);
    breakpointTimer = setTimeout(breakpointResize, 250);
  });

  /**
   * Returns Breakpoint out of width.
   */
  function getBreakpoint(width) {
    if (width < 768) {
      return 'mobile';
    }
    else if (width < 1024) {
      return 'tablet';
    }
    else if (width < 1440) {
      return 'tablet-landscape';
    }

    return 'desktop';
  }

  /**
   * Resize callback for Breakpoint.
   */
  function breakpointResize() {
    // Validate if we need to reset Cycle Instances.
    var nWidth = parseInt($(window).width(), 10);
    var oBreakpoint = window.breakpoint;
    var nBreakpoint = getBreakpoint(nWidth);
    if (oBreakpoint != nBreakpoint) {
      window.breakpoint = nBreakpoint;

      $(document).trigger('breakpoint-change', [nBreakpoint, oBreakpoint]);
    }
  }

})(jQuery);
