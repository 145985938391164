$(function ($) {

  'use strict';

  var url = $(location).attr('href');

  /*
  * Gives the option to print all the page.
  */
  $('.sharing-panel-block .printer').on('click', function () {
    window.print();
  });

  $('.sharing-panel-block-bio .printer').on('click', function () {
    print_bio($(this).closest('.modal-content').find('.modal-body'));
  });

  function print_bio(bio){
    var w = window.open();
    var bioId = $(bio).attr('id');
    var bioItem = document.getElementById(bioId);
    w.document.write(bioItem.innerHTML);
    w.focus(); // necessary for IE >= 10
    w.setTimeout( function () {
      w.print();
      w.close();
    }, 500 );
    return true;
  };

  /*
  * Sends a message with subject and previews body.
  */
  $('.sharing-panel-block .mail').on('click', function () {
    var titlePage = 'Be informed.  Be involved.';
    $(this).attr('href', 'mailto:?subject=' + titlePage + '&body=Thought this page would be of interest to you: ' + url);
  });

  /*
  * Shows the message and copy the url to the clipboard.
  */
  $('.sharing-panel-block .url-link').on('click', function () {
    var temp = $('<input>');

    $('.url-link').addClass('visible');
    setTimeout(function () {
      $('.url-link').removeClass('visible');
    },
    2000);

    $('body').append(temp);
    temp.val(url).select();
    document.execCommand('copy');
    temp.remove();
  });

  /*
  * Shows the message and copy the url to the clipboard.
  */
  $('.sharing-panel-block-bio .url-link').on('click', function () {
    var temp = $('<input>');

    $('.url-link').addClass('visible');
    setTimeout(function () {
      $('.url-link').removeClass('visible');
    },
    2000);

    var linkUrl = $(this).find('button').attr('data-url');

    $('body').append(temp);
    temp.val(linkUrl).select();
    document.execCommand('copy');
    temp.remove();
  });
});

