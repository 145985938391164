/**
 * @file
 * Adds Megamenu Functionality.
 */

(function ($) {

  'use strict';

  /**
  * Attach Carousel Functionality to Hero Home.
  */
  $(window).on('load', megaMenu);

  /**
  * Initializes Megamenu on Navbar.
  */
  function megaMenu() {
    $('.circle .navbar-toggler').on('click', function (event) {
      event.preventDefault();
      $('.document-finder').toggleClass('active');
    });

    var pathName = window.location.pathname;

    $(document).find('#mainnav .menus .menu-link').each(function (index, item) {
      var data_href = $(item).attr('data-href');

      if (data_href.slice(-1) == '/' && data_href.length > 0) {
        data_href = $(item).attr('data-href').slice(0,-1);
      }

      if (data_href.length <= 0) {
        data_href = "#no-reference";
      }

      if (pathName.slice(-1) == '/') {
        pathName = pathName.slice(0,-1);
      }

      if (pathName == data_href) {
        $(item).closest('li').addClass('breadcrumb-active');
        $(item).closest('.menu-list').addClass('active');

        var data_menu = $(item).closest('.menu-list');
        setActiveStateMenus($(data_menu).closest('.menu-content-titles')[0].classList[0], $(data_menu)[0].classList[1], data_menu, true);
      }
    });

    function setActiveStateMenus(parentClass, classActualElement, element, isFirstFunctionCalled) {
      switch (parentClass) {
        case 'menu-content':
          $(document).find('#mainnav .menus .menu-titles p a#' + classActualElement).addClass('active');
          $(document).find('#mainnav .menus .menu-titles').addClass('hide-me');
          $(element).closest('.menu-content').addClass('open');
          break;
        case 'menu-content-level-two':
          $(element).addClass('active');
          $(document).find('#mainnav .menus .menu-content').addClass('hide-me');
          $(element).closest('.menu-content-titles').addClass('open');
          $(element).closest('.menus').find('.menu-content').addClass('open');
          $(element).closest('.menus').find('.menu-content ul li a#' + $(element)[0].classList[1]).closest('li').addClass('active');
          $(element).closest('.menus').find('.menu-content ul li a#' + $(element)[0].classList[1]).closest('.menu-list').addClass('active');
          $(element).closest('.menus').find('.menu-content ul.' + classActualElement).addClass('open');
          setActiveStateMenus($(element).closest('.menus').find('.menu-content ul li a#' + $(element)[0].classList[1]).closest('.menu-content-titles')[0].classList[0],
                              $(element).closest('.menus').find('.menu-content ul li a#' + $(element)[0].classList[1]).closest('.menu-list')[0].classList[1],
                              $(element).closest('.menus').find('.menu-content ul li a#' + $(element)[0].classList[1]).closest('.menu-list'),
                              false);
          break;
        case 'menu-content-level-three':
          $(document).find('#mainnav .menus .menu-content-level-two').addClass('hide-me');
          $(element).addClass('active');
          $(element).closest('.menu-content-titles').addClass('open');
          $(element).closest('.menus').find('.menu-content-level-two').addClass('open');
          $(element).closest('.menus').find('.menu-content-level-two ul li a#' + $(element)[0].classList[1]).closest('li').addClass('active');
          $(element).closest('.menus').find('.menu-content-level-two ul li a#' + $(element)[0].classList[1]).closest('.menu-list').addClass('active');
          $(element).closest('.menus').find('.menu-content-level-two ul.' + classActualElement).addClass('open');
          setActiveStateMenus($(element).closest('.menus').find('.menu-content-level-two ul li a#' + $(element)[0].classList[1]).closest('.menu-content-titles')[0].classList[0],
                              $(element).closest('.menus').find('.menu-content-level-two ul li a#' + $(element)[0].classList[1]).closest('.menu-list')[0].classList[1],
                              $(element).closest('.menus').find('.menu-content-level-two ul li a#' + $(element)[0].classList[1]).closest('.menu-list'),
                              false);
          break;
      }
    }

    $(document).on('click', '.megamenu .menus .menu-titles p a', function (e) {
      e.preventDefault();
      var data_ID = $(this).attr('id');

      $(document).find('.megamenu .menus .menu-titles p a').removeClass('active');
      $(this).addClass('active');
      $(this).closest('.menu-titles').addClass('open');
      $(this).closest('.menu-titles').addClass('hide-me');
      $(document).find('.megamenu .menus .menu-content-level-two ul').removeClass('active');
      $(document).find('.megamenu .menus .menu-content ul li').removeClass('active');
      $(document).find('.megamenu .menus .menu-content-level-three ul').removeClass('active');

      $(this).closest('.d-flex').find('.menu-content .menu-list').removeClass('active');

      $(this).closest('.d-flex').find('.menu-content .menu-list').each(function () {
        if ($(this).hasClass(data_ID)) {
          $(this).addClass('active');
          $(this).closest('.menu-content').addClass('open');
        }
      });

      setCurrentPage($(this).closest('.d-flex').find('.menu-content .menu-list.' + data_ID));
    });

    function setCurrentPage(activeMenu) {
      var pathname = window.location.pathname;

      var menu = $(activeMenu).find('li');

      $(menu).each(function (index, item) {
        var pathLink = $(item).find('> a').attr('href');

        if (pathLink === pathname) {
          $(item).addClass('active');
        }
      });
    }

    $(document).on('click', '.megamenu .menus .menu-content ul li a.has-level-three', function (e) {
      e.preventDefault();
      var data_ID = $(this).attr('id');

      $(document).find('.megamenu .menus .menu-content ul li').removeClass('active');
      $(document).find('.megamenu .menus .menu-content-level-two ul').removeClass('active');
      $(document).find('.megamenu .menus .menu-content-level-two ul li').removeClass('active');
      $(document).find('.megamenu .menus .menu-content-level-three ul').removeClass('active');
      $(this).closest('.menu-content').addClass('hide-me');

      $(this).closest('li').addClass('active');
      $(this).closest('.menus').find('.menu-content-level-two ul').each(function () {
        if ($(this).hasClass(data_ID)) {
          $(this).addClass('active');
          $(this).closest('.menu-content-level-two').addClass('open');
        }
      });
    });

    $(document).on('click', '.megamenu .menus .menu-content-level-two ul li a.has-level-four', function (e) {
      e.preventDefault();
      var data_ID = $(this).attr('id');

      $(document).find('.megamenu .menus .menu-content-level-two ul li').removeClass('active');
      $(document).find('.megamenu .menus .menu-content-level-three ul').removeClass('active');
      $(this).closest('.menu-content-level-two').addClass('hide-me');

      $(this).closest('li').addClass('active');
      $(this).closest('.menus').find('.menu-content-level-three ul').each(function () {
        if ($(this).hasClass(data_ID)) {
          $(this).addClass('active');
          $(this).closest('.menu-content-level-three').addClass('open');
        }
      });
    });

    $(document).on('click', '.menus .menu-content-titles .back', function (e) {
      $(this).closest('.menu-content-titles').removeClass('open');
      $(this).closest('.menu-content-titles').prev().find('.menu-list.active .menu-item').removeClass('active');
      $(this).closest('.menu-content-titles').prev().removeClass('hide-me');
      $(this).closest('.menu-content-titles').prev().addClass('open');
    });
  }

  $(document).on('click', '.mainnav-close', function (e) {
    setTimeout(function () {
      megaMenu();
    }, 500);
  })
})(jQuery);
