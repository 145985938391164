/**
 * @file
 * Adds functionality to get the page type.
 */

(function ($) {
  'use strict';

  $(window).on('load', function () {
    getPageType();
    setTimeout(scrollOnLoad, 600);
  });

  var popstateToken = true;
  window.onpopstate = function () {
    // Validate event state.
    if (popstateToken) {
      sessionStorage.setItem('returned', true);
      popstateToken = false;
      history.back();
    }
  };

  /**
   * This function obtains the page type.
   *
   */
  function getPageType(){
    var mainTag = $(document).find('main');

    $(mainTag).each(function () {
      var storiesSlides = $(this).find('stories-slides');

      var attrTag = $(this).attr('data-page');
      if (attrTag === 'blog-post') {
        $(this).closest('body').addClass('bg-white');
      }
    });

    if (navigator.userAgent.indexOf('.NET') != -1) {
      $('body').addClass('is-ie');
    }
  }

  $(document).on('click', '.newsroom-cta', function () {
    $(this).addClass('active');
  });

  $(document).on('click', '.close', function () {
    $(document).find('.newsroom-cta').removeClass('active');
  });

  $(document).on('click', '.jquery-modal.blocker', function () {
    $(document).find('.newsroom-cta').removeClass('active');
  });

  $(document).on('keyup',function(evt) {
    if (evt.keyCode == 27) {
      $(document).find('.newsroom-cta').removeClass('active');
    }
  });

  function scrollOnLoad() {
    var hash = window.location.hash;

    if (hash != '') {
      setTimeout(function () {
        var targetId = hash;
        var item = $(document).find(targetId);
        var position = $(item).offset() != undefined ? $(targetId).offset().top : 0;
        var notificationHeight = $(document).find('.notifications-block').height() ?? 0;
        var headerHeight = $(document).find('header').height();

        var positionTop = position - ( notificationHeight + headerHeight );

        // Animate the scrolling.
        if (targetId.indexOf('modal-bio-detail-content') == -1) {
          $('html, body').animate({
            scrollTop: positionTop
          }, 250);
        }
      }, 250);
    }
  }

  $(document).on('click', 'a', function (e) {
    if ($(this).attr('data-anchor') !== undefined) {
      if ($(this).attr('data-anchor') == $(this).attr('href')) {
        e.preventDefault();

        var targetId = $(this).attr('data-anchor');
        var notificationHeight = $(document).find('.notifications-block').height() ?? 0;
        var headerHeight = $(document).find('header').height();

        var positionTop = $(targetId).offset().top - ( notificationHeight + headerHeight );

        // Animate the scrolling.
        if (targetId.indexOf('modal-bio-detail-content') == -1) {
          $('html, body').animate({
            scrollTop: positionTop
          }, 250);
        }
      }
    }
  });

})(jQuery);
