/**
 * @file
 * Generic Page - Adds Video Block functionality.
 */
$(function ($) {

  'use strict';

  /**
  * Attach Video Block Init function to Window Load event.
  */
  $(window).on('load', videoBlockInit);

  /**
   * Setups lightbox.
   */
  var maxWidth = 345;
  var maxHeight = 194;
  var widthwindows = $(document).width();

  if (widthwindows > 375 && widthwindows < 768){
    var sumWidth = widthwindows - 375;
    maxWidth = maxWidth + sumWidth;
    maxHeight = Math.round((194 * maxWidth) / 345);
    maxWidth = maxWidth + 'px';
    maxHeight = maxHeight + 'px';
  }

  if (widthwindows >= 768){
    maxWidth = '95%';
    maxHeight = '85%';
  }

  var cboxOptions = {
    iframe: true,
    width: '95%',
    height: '85%',
    maxWidth: maxWidth,
    maxHeight: maxHeight,
    fixed: true,
    closeButton: true
  };

  /**
  * Initializes Video Block.
  */
  function videoBlockInit() {
    if ($(document).find('.faceted-filter-block[data-document="video"]').length > 0) {
      $('.faceted-filter-block[data-document="video"]').each(function (index, value) {
        $(value).find('a.video-selector-iframe').colorbox(cboxOptions);
        $(value).find('a.video-selector-iframe').addClass('ready');
      });

      $('a.video-selector-iframe').on('click', function (event) {
        event.preventDefault();
        $(this).colorbox(cboxOptions);
      });

      $(window).on('resize', function () {
        maxWidth = 345;
        maxHeight = 194;
        widthwindows = $(document).width();

        if (widthwindows > 375 && widthwindows < 768){
          var sumWidth = widthwindows - 375;
          maxWidth = maxWidth + sumWidth;
          maxHeight = Math.round((194 * maxWidth) / 345);
          maxWidth = maxWidth + 'px';
          maxHeight = maxHeight + 'px';
        }

        if (widthwindows >= 768){
          maxWidth = '95%';
          maxHeight = '85%';
        }

        cboxOptions = {
          iframe: true,
          width: '95%',
          height: '85%',
          maxWidth: maxWidth,
          maxHeight: maxHeight,
          fixed: true,
          closeButton: true
        };

        $.colorbox.resize({
          width: window.innerWidth > parseInt(cboxOptions.maxWidth) ? cboxOptions.maxWidth : cboxOptions.width,
          height: window.innerHeight > parseInt(cboxOptions.maxHeight) ? cboxOptions.maxHeight : cboxOptions.height
        });
      });
    }

    if ($(document).find('.video-block').length > 0) {
      $('.video-block').each(function (value) {
        $(value).find('a.video-selector-iframe').colorbox(cboxOptions);
      });

      $('a.video-selector-iframe').on('click', function (event) {
        event.preventDefault();
        $(this).colorbox(cboxOptions);
      });

      $(window).on('resize', function () {
        maxWidth = 345;
        maxHeight = 194;
        widthwindows = $(document).width();

        if (widthwindows > 375 && widthwindows < 768){
          var sumWidth = widthwindows - 375;
          maxWidth = maxWidth + sumWidth;
          maxHeight = Math.round((194 * maxWidth) / 345);
          maxWidth = maxWidth + 'px';
          maxHeight = maxHeight + 'px';
        }

        if (widthwindows >= 768){
          maxWidth = '95%';
          maxHeight = '85%';
        }

        cboxOptions = {
          iframe: true,
          width: '95%',
          height: '85%',
          maxWidth: maxWidth,
          maxHeight: maxHeight,
          fixed: true,
          closeButton: true
        };

        $.colorbox.resize({
          width: window.innerWidth > parseInt(cboxOptions.maxWidth) ? cboxOptions.maxWidth : cboxOptions.width,
          height: window.innerHeight > parseInt(cboxOptions.maxHeight) ? cboxOptions.maxHeight : cboxOptions.height
        });
      });
    }
  }
});
