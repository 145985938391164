(function ($) {
  'use strict';

  function setSizes() {
    var getHeight = $(document).find('.notifications-block');
    var positionHeader = $('.fixed-top');
    var mainContainer = $('body');
    var notificatioHeight = 0;
    /*
    * Does a for each to detect the height of a notification alert.
    */
    $(document).find('.notifications-block').each(function (index, item) {
      var itemHeight = $(item).height() + parseInt($(item).css('padding-top')) + parseInt($(item).css('padding-bottom'));
      if (!$(item).hasClass('d-none')) {
        notificatioHeight += itemHeight;
      }
    });

    $(positionHeader).css('top', notificatioHeight);
    $(mainContainer).css('marginTop', notificatioHeight + $(positionHeader).height());

    $('.hPHero').each(function() {
      if ($('body').find('.section-rows').hasClass('slide-cover-effect')) {
        $(this).css('marginTop',notificatioHeight);
        $(mainContainer).css('marginTop',notificatioHeight);
      }
    });
  }
  /*
  * Positions header and alerts.
  */
  function validateCookies() {
    // Get existent cookie otherwise create an empty array.
    var createdCookie = Cookies.get('notifArray') === undefined
                        ? []
                        : JSON.parse(Cookies.get('notifArray'));
    var notifications = $(document).find('.notifications-block .notification-item');
    var activeNotifications = [];

    // Check if there are ids stored in the cookie.
    if(createdCookie.length > 0) {
      // Get all existent alerts ids.
      for(var i = 0; i < notifications.length; i++) {
        // Get id from each item.
        var alert_id = $($(notifications)[i]).attr('data-history-node-id');
        activeNotifications.push(alert_id);
      }

      // Remove from existent alerts those are in the stored cookie.
      for (var i = 0; i < createdCookie.length; i++) {
        for (var ii = 0; ii < activeNotifications.length; ii++) {
          // If value stored in cookie is same as the current notification, remove id from array.
          if(createdCookie[i] === activeNotifications[ii]) {
            activeNotifications.splice(ii, 1);
          }
        }
      }

      // If there are active notifications show them.
      if(activeNotifications.length > 0) {
        $('.notifications-block').css('display', 'block');
        $('.notifications-block .notification-item').addClass('d-none');

        $(activeNotifications).each(function (notification) {
          var target = document.querySelectorAll("[data-history-node-id='"
                                                  + activeNotifications[notification]
                                                  + "']");
          $(target).removeClass('d-none');
        });
      }
      else{
        $('.notifications-block').addClass('d-none');;
        $('html').attr('notifications', 'off');
      }
    } else {
      // Check stored ids with existent notifications to hide them.
      for(var i = 0; i < notifications.length; i++) {
        // Get notification ID.
        var target = $(notifications)[i];
        // Show all the notifications by default.
        $(target).removeClass('d-none');
      }
    }

    setSizes();
  }

  /**
   * Notification banner initialize cookie and hide element selected.
   */
  $(document).on('click',
  '.notifications-block .bar-wrap .bar .close',
  function () {
    var notification = $(this).closest('.notification-item');
    var id = $(this).attr('data-id');
    updateCookie(id, $(this));
  });

  // Update the cookie.
 function updateCookie (obj, button) {
   var notification = $(button).closest('.notification-item');
   $(notification).addClass('d-none');
   var createdCookie = Cookies.get('notifArray') === undefined
                       ? []
                       : JSON.parse(Cookies.get('notifArray'));

   // Add new item and clean if repeated id exist.
   createdCookie.push(obj);

   // Remove duplicates just if there are more than one item.
   if (createdCookie.length > 1) {
     createdCookie = createdCookie.filter(function (elem, index, self) {
       return index === self.indexOf(elem);
     });
   }

   // Replace with new cookie.
   Cookies.remove('notifArray');
   Cookies.set('notifArray', createdCookie, { expires : 30 });

   setSizes();
 }

  /*
  * Declare variables for the notification block and for the header.
  */
  $(window).on('load', function () {
    $('.notifications-block').each(function (i, obj) {
      var items = $(obj).find('.notification-item');
      if (parseInt($(items).length, 10) > 0) {
        $('html').attr('notifications', 'on');
      } else {
        $('html').attr('notifications', 'off');
      }
    });

    validateCookies();
  });

  var resizeTimer;
  $(window).on('resize', function () {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function () {
      setSizes();
    }, 250);
  });

})(jQuery);
