/**
 * @file
 * Adds Popup Article functionality.
 */

(function ($) {

  'use strict';

  /**
   * Attach Popup Functionality.
   */
  $(window).on('load', popupArticleInit);

  /**
   * Initializes Popup Article.
   */
  function popupArticleInit() {
    $('body').prepend('<div id="popup-articles"></div>');
    $('body').prepend('<div id="popup-article"></div>');
    $('.popup-article').each(popupArticleInitItem);
  }

  /**
   * Inits a Popup article.
   */
  function popupArticleInitItem(i, item) {
    var href = $(item).attr('data-ref');

    // Preload pages.
    $.ajax({
      url: href,
      success: function (data) {
        $('#popup-articles').append(data);
        var article = $('#popup-articles .article-teaser:last');
        $(article).removeClass('article-teaser-slider');
        $(article).attr('data-i', i);
        $(item).addClass('ready').attr('data-i', i);
      }
    });
  }

  /**
   * Click callback for popup article link.
   */
  $(document).on('click', 'a.popup-article.ready', function (e) {
    e.preventDefault();

    var notificationHeight = $(document).find('.notifications-block').innerHeight() ?? 0;
    var hHeight = $('header').height();

    var popupContainer = $('#popup-article');
    $(popupContainer).css('margin-top', notificationHeight + hHeight);

    // Reset.
    $('#popup-article').html('');

    // Grab new article.
    var i = $(this).attr('data-i');
    var article = $('.article-teaser[data-i="' + i + '"]').clone();
    $(article).addClass('article-teaser-slider');
    $(article).appendTo('#popup-article');

    // Recall ajax callback.
    $(window).trigger('ajax-restart');

    // Activate Article Display.
    $('html').addClass('popup-article-on');
    setTimeout(function () {
      $('html').addClass('popup-article-ready');
      setTimeout(function () {
        $('html').addClass('popup-article-full');
        $(popupContainer).find('.article-teaser').css('--rest', notificationHeight + hHeight + 'px');
      }, 500);
    }, 50);
  });

  /**
   * Click callback for closing article popup.
   */
  $(document).on('click', '.article-slide-item button.close-article', function (e) {
    e.preventDefault();

    $('html').addClass('popup-article-off');
    setTimeout(function () {
      $('html').removeClass('popup-article-full');
      $('html').removeClass('popup-article-ready');
      $('html').removeClass('popup-article-on');
      $('html').removeClass('popup-article-off');
    }, 600);
  });

  $(window).on('resize', function () {
    var notificationHeight = $(document).find('.notifications-block').innerHeight() ?? 0;
    var hHeight = $('header').height();

    var popupContainer = $('#popup-article');
    $(popupContainer).css('margin-top', notificationHeight + hHeight);
    $(popupContainer).find('.article-teaser').css('--rest', notificationHeight + hHeight + 'px');
  });

})(jQuery);
