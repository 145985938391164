/**
 * @file
 * Pagination Functionality.
 */

(function ($) {

  'use strict';

  /**
   * Attach function to add a pagination.
   */
  $(window).on('load', setPagination);

  /**
   * Set function to set pagination.
   */
  function setPagination() {
    var arr = [];
    var elementSearchBlock = $(document).find('.post-teaser-block .card-view');
    if (elementSearchBlock.length > 0) {
      $(elementSearchBlock).find('.card-container').each(function () {
        insertItems(arr, $(this).find('.card-title')[0].innerHTML, $(this).find('.card-date')[0].innerHTML, $(this).find('.card-text')[0].innerHTML);
      });

      if (arr.length > 0) {
        $(elementSearchBlock).closest('.post-teaser-block').removeClass('d-none');
        var dataContainer = $('#results').find('.content');

        $('#results').pagination({
          dataSource: arr,
          totalNumber: arr.length,
          pageSize: 5,
          classPrefix: 'nav-item',
          ulClassName: 'nav nav-tabs d-flex justify-content-center t-pagination',
          disableClassName: 'disabled',
          activeClassName: 'current',
          callback: function (data) {
            // template method.
            var html = renderTemplate(data);
            dataContainer.html(html);
            observeElements();
          }
        });

        if (arr.length < 5) {
          $('#results').find('.paginationjs').addClass('d-none');
        }
      } else {
        $(elementSearchBlock).closest('.post-teaser-block').addClass('d-none');
      }
    }
  }

  function observeElements() {
    var element = document.querySelector('#results .paginationjs');

    var observer = new MutationObserver(function (mutations) {

      $(mutations).each(function () {
        var parentElement = $(document).find('.post-teaser-block').offset().top - 60;

        $('html, body').animate({
          scrollTop: parentElement
        }, 600);
      });

      observer.disconnect();
    });

    observer.observe(element, {
      attributes: true,
      childList: true,
      characterData: true
    });
  }

  function insertItems(arr, title, date, description) {
    arr.push({
      title: title,
      date: date,
      description: description
    });
  }

  function renderTemplate(data) {
    var html = '';

    $(data).each(function () {
      var title = $(this)[0].title;
      var description = $(this)[0].description;
      var date = $(this)[0].date;

      var cardTitleContent = '';
      if (title != '') {
        cardTitleContent = '<div class="card-title p-0">' + title + '</div>';
      }

      var cardDescriptionContent = '';
      if (description != '') {
        cardDescriptionContent = '<p class="card-text h5">' + description + '</p>';
      }

      var cardDateContent = '';
      if (date != '') {
        cardDateContent = '<div class="card-date p-0">' + date + '</div>';
      }

      var element = '<div class="row card-container"><div class="card col-12">' +
                        '<div class="row"><div class="card-content col-sm-12"><div class="card-body p-0">' +
                              cardTitleContent +
                              cardDateContent +
                              cardDescriptionContent +
                            '</div></div></div></div></div>';

      html += element;
    });

    return html;
  }

})(jQuery);
