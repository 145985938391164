/**
 * @file
 * Adds Section Row functionality.
 */

(function ($) {

  'use strict';

  /**
   * Global Vars.
   */
  var sectionRowHeights = 0;
  var dHeight = 0;
  var cScroll = 0;
  var oWWidth = 0;
  var oWHeight = 0;
  var resizeTimer;
  var defaultPadding = 0;
  var notificationHeight = 0;
  var bodyMargin = 0;
  var safariDeviceOffset = 125;

  /**
   * Attach Section Row Init function to Window Load event.
   */
  $(window).on('load', function () {
    if ($(document).find('#main-container .section-rows').hasClass('slide-cover-effect')) {
      $('html').addClass('scroll-block');
      $('.first-view-port').addClass('active');
      $('<div class="hero-spacer"></div>').insertAfter('.first-row')
      setTimeout(sectionRowInit, 500);
    } else {
      $(document).find('#main-container').addClass('not-fx');
      setTimeout(playAllVideos, 500);
    }
  });

  /**
   * Initializes Section Rows.
   */
  function sectionRowInit() {
    // Add default Padding.
    addDefaultPadding();
    addNotificationHeight();
    addBodyMargin();
    updateWindowVars();
    sectionRowDestroyAll();

    setTimeout(function () {
      $('.section-row').each(sectionRowWaypointInit);
      waypointsRecreateOnView();
      waypointsFourColumnContent();
      waypointsFx();
      $('html').removeClass('scroll-block');
    }, 250);
  }

  /**
  * Add functionality to autoplay videos.
  */
  function playAllVideos() {
    const media = document.querySelectorAll('video');

    $(media).each(function (video) {
      // Show loading animation.
      var playPromise = media[video].play();
      if (playPromise !== undefined) {
        playPromise.then(function() {
          // Autoplay started!
          console.log('Autoplay was started');
        }).catch(function(error) {
          // Autoplay was prevented.
          console.log('Autoplay was prevented', error);
        });
      }
    });
  }

  /**
  *Add functionality when dont exist notifications.
  */
  $(document).on('click','.notifications-block .bar-wrap .bar .close', function (){
    if ($(document).find('#main-container .section-rows').hasClass('slide-cover-effect')) {
      setTimeout(sectionRowInit, 500);
    }
  })

  /**
   * Adds default padding.
   */
  function addDefaultPadding() {
    defaultPadding = $('.section-row.first-view-port').height() - $(document).find('header').height();
  }

  function addNotificationHeight() {
    notificationHeight = $('.notifications-block').height();
  }

  function addBodyMargin() {
    bodyMargin = parseInt($('body').css('marginTop').replace('px', ''));
  }

  /**
   * Updates Window Width and Height vars.
   */
  function updateWindowVars() {
    oWWidth = parseInt($(window).width(), 10);
    oWHeight = parseInt($(window).height(), 10);
  }

  /**
   * Has fixed effect.
   */
  function sectionRowFXStatus(row) {
    var wWidth = parseInt($(window).width(), 10);
    var classFX;
    if (wWidth < 1200) {
      classFX = 'reveal-fx-mobile';
    }
    else{
      classFX = 'reveal-fx-desktop';
    }

    if ($(row).hasClass(classFX)) {
      return true;
    }

    return false;
  }

  function sectionTop(i) {
    var cScroll = 0;

    if ( i < 1) {
      return cScroll;
    }

    var start = 0;
    var end = i;

    var pSections = $('.section-row').slice(start, end);
    var pHeight = 0;
    $(pSections).each(function (j, div) {
      pHeight += parseInt($(div).height(), 10);
    });

    return pHeight;
  }

  var disableWaypoint = 0;

  /**
   * Inits the Waypoint for a Section Row.
   */
  function sectionRowWaypointInit(i, item) {
    // Add Waypoint on top edge.
    var waypoint = new Waypoint({
      element: $(item)[0],
      handler: function (direction) {
        // Lock #main-container height.
        if (direction == 'down') {
          if (disableWaypoint >= 0 && disableWaypoint <= 1) {
            var itemHeight = parseInt($(this.element).height(), 10);
            if (sectionRowFXStatus(this.element)) {
              if (!$(this.element).hasClass('first-view-port')) {
                sectionRowHeights += itemHeight;
              }

              // Add padding to Main Container.
              disableWaypoint = 1;
              $('#main-container').css('padding-top', (sectionRowHeights));
              $(this.element).addClass('section-fixed');

              setTimeout(function () {
                disableWaypoint = 0;
              }, 250);
            }
          }
        }
        else if (direction == 'up') {
          if (disableWaypoint >= -1 && disableWaypoint <= 0) {
            var itemHeight = parseInt($(this.element).height(), 10);
            if (sectionRowFXStatus(this.element)) {
              if (!$(this.element).hasClass('first-view-port')) {
                sectionRowHeights -= itemHeight;
              }

              // Take padding out to Main Container.
              disableWaypoint = -1;
              $('#main-container').css('padding-top', (sectionRowHeights));
              $(this.element).removeClass('section-fixed');

              setTimeout(function () {
                disableWaypoint = 0;
              }, 250);
            }
          }
        }
      },
    });

    // Add Waypoint on dissappear event.
    if ($(item).hasClass('hidden-out-of-view-port')) {
      var waypoint2 = new Waypoint({
        element: $(item)[0],
        handler: function (direction) {
          if (direction == 'down') {
            $(this.element).addClass('transparent');
          }
          else if (direction == 'up') {
            $(this.element).removeClass('transparent');
          }
        },
        offset: function () {
          var itemHeight = parseInt($(this.element).height(), 10);
          var offset = itemHeight + 30;
          return -offset;
        }
      });
    }
  }

  /**
   * Inits the Waypoint for a Section Row.
   */
  function waypointsFourColumnContent() {
    // Adds Intro Animation.
    $('.four-column-content .col-content-item').each(function (i, item) {
      var waypoint4 = new Waypoint({
        element: $(item)[0],
        handler: function (direction) {
          var itemHeight = parseInt($(item.element).height(), 10);

          if (direction == 'down') {
            $(this.element).addClass('on');
          }
        },
        offset: function () {
          var $wHeight = parseInt($(window).height(), 10) + safariDeviceOffset;
          return $wHeight - 100;
        }
      });
    });
  }

  /**
   * Inits FX.
   */
  function waypointsFx() {
    // Adds Intro Animation.
    $('.fx-intro').each(function (i, item) {
      // Add waypoint for Intro.
      var waypointFx = new Waypoint({
        element: $(item)[0],
        handler: function (direction) {
          var fx = $(this.element).attr('data-fx');

          if (direction == 'down') {
            $(this.element).addClass('fx-intro-' + fx);
          }
        },
        offset: function () {
          var $wHeight = parseInt($(window).height(), 10) + safariDeviceOffset;
          return $wHeight;
        }
      });

      // Add Default state.
      var fx = $(item).attr('data-fx');
      var defaultClass = 'fx-intro-' + fx + '-default';
      var onClass = 'fx-intro-' + fx + '-on';
      $(item).addClass(defaultClass);
      setTimeout(function () {
        $(item).addClass(onClass);
      }, 50);
    });
  }

  /**
   * Waypoint for gif-block.
   */
  function waypointsRecreateOnView() {
    // Gif Block.
    $('.recreate-on-viewport').each(function (i, obj) {
      var waypointGif = new Waypoint({
        element: $(obj)[0],
        handler: function (direction) {
          if (direction == 'down') {
            var height = parseInt($(this.element).height(), 10);
            var img = $(this.element).find('img');
            var src = $(img).attr('src');
            var pos = src.indexOf('?');

            if (pos > -1) {
              var src = src.substring(0, pos);
            }

            // Add Query.
            src = src + '?var=' + Math.random();
            $(img).attr('src', src);
          }
        },
        offset: function () {
          var wHeight = parseInt($(window).height(), 10);
          return wHeight;
        }
      });
    });
  }

  /**
   * Resets all.
   */
  function sectionRowDestroyAll() {
    // Reset.
    $('.section-row').removeClass('section-fixed');
    sectionRowHeights = 0;
    $('#main-container').removeAttr('style');
    Waypoint.destroyAll();

    // Detect window height.
    dHeight = parseInt($('main').height(), 10);
    cScroll = parseInt(document.documentElement.scrollTop, 10);
  }

  /**
   * Resize Event.
   */
  $(window).on('resize', function (e) {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function () {
      var nWWidth = parseInt($(window).width(), 10);
      if (nWWidth != oWWidth) {
        if ($(document).find('#main-container .section-rows').hasClass('slide-cover-effect')) {
          sectionRowInit();
        }
      }
    }, 250);
  });

})(jQuery);
