(function ($) {
  'use strict';

  /*
  * Verify if all images are ready before initialize masonry.
  */
  $(window).on('load', function () {
    setTimeout(checkLoadedImages, 250);
  });

  /*
  * Re-initialize masonry on windows resize.
  */
  var resizeTimer;
  $(window).on('resize', function () {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(masonryInit, 250);
  });

  /*
  * Verify the amount of images to calculate the waiting time to initialize.
  */
  function checkLoadedImages() {
    var images = $(document).find('.masonry-block .masonry-container .masonry-img img');
    var imgCount = images.length;

    // Instantiate and image obj for each image url.
    $(images).each(function (index, image) {
      var img = new Image();
      img.src = $(image).prop('src');

      // Listen to each image load event.
      img.onload = function () {
        // If image index reach image total count, intialize gallery.
        if (imgCount - 1 == index) {
          masonryInit();
        }
      }
    });
  }

  //This function obtains the masonry elements and puts a negative margin-bottom to hide
  //the white space
  function masonryInit(){
    $('.masonry-container').each(function(){
      var galleryObj = this;
      var images = $(galleryObj).find('.masonry-img img');
      var imageItems = $(galleryObj).find('.masonry-img a');
      var height = getHeight(images);

      $('.masonry-container').css('height:' + height + 'px;');

      new Masonry(galleryObj, {});

      $(imageItems).on('click', function (e) {
        e.preventDefault();

        var optionBox = '';

        optionBox = setAspectRatio('90%', '85%');

        $(this).colorbox(optionBox);
      });
    });
  }

  function setAspectRatio(width, height, classBox) {
    if(width == undefined) {
      width == 0;
    }

    if(height == undefined) {
      height = 0;
    }

    if(classBox == undefined) {
      classBox == false;
    }

    var maxWidth = width;
    var maxHeight = height;

    var cboxOptions = {
      iframe: false,
      maxWidth: maxWidth,
      maxHeight: maxHeight,
      className: classBox,
      fixed: true,
      closeButton: true,
      scalePhotos: true
    };

    return cboxOptions;
  }

  function getHeight(imagesArray) {
    var count = 1;
    var height = 0;

    for (let i = 1; i < imagesArray.length; i++) {
      count = count + 1;
      if(count == 4){
        height = height + 1;
        count = 0;
      }
    }

    height = height * 200;
    return height;
  }

})(jQuery);
