/*
 * Define variable to set srcset from data-srcset.
 */

$(window).on('load', function () {
  setTimeout(initializeLazy, 350);
});

function initializeLazy() {
  setTimeout(function () {
    $('.section-row').each(lazyLoad);
    var bLazy = new Blazy({
       // Options
    });
  }, 350);
}


function lazyLoad(i, item) {
  // Add Waypoint on top edge.
  var lazyWaypoint = new Waypoint({
    element: $(item)[0],
    handler: function (direction) {
      // Get all pictures.
      var pictures = $(item).find('picture.lazy-load');

      if (direction == 'down') {
        if ($(pictures).length > 0) {
          $(pictures).each(function (indexPicture, picture) {
            var srcsets = $(picture).find('source');

            $(srcsets).each(function (indexSrcset, srcset) {
              var singleSrcset = $(srcset).attr('data-srcset');
              $(srcset).attr('srcset', singleSrcset);
            });
          })
          this.destroy();
        }
      }
    },
    offset: '100%',
  });
}
