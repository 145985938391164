(function ($) {

  'use strict';

  /*
    * Builds the carousel.
    */
  function buildCarousel(visibleSlides) {
    var blogBlock = $('.blog-block');

    $(blogBlock).find('.content-block').cycle({
      fx: 'carousel',
      swipe: true,
      allowWrap: false,
      slides: '.slide-item',
      carouselVisible: visibleSlides,
      pager: 'div.nav',
      paused: true
    });

    relatedEventsSetMaxHeight(blogBlock);
  }

  /*
    * Get height of item.
    */
  function relatedEventsSetMaxHeight(obj) {
    var relatedEventsItem = $(obj).find('.content-block').find('.cycle-carousel-wrap');
    var relatedEventHeight = 0;
    relatedEventHeight = parseInt($(relatedEventsItem).height(), 10);
    $(obj).find('.content-block').css({'min-height': relatedEventHeight});
  }

  /*
    * Shows items on slider depend to its nreakpoint.
    */
  function initCycle() {
    var width = parseInt($(window).width(), 10);
    var visibleSlides = 3;

    if ( width <= 767 ) {
      visibleSlides = 1;
    }
    else if(width <= 1023 ) {
      visibleSlides = 2;
    }
    else {
      visibleSlides = 3;
    }

    buildCarousel(visibleSlides);
  }

  /*
    * Reinitializes the slider when do resize for execute the slider..
    */
  $(document).on('breakpoint-change', function () {
    var wWidth = parseInt($(window).width(), 10);
    reinit_cycle(wWidth);
  });

  /*
    * Redoes the slider depend to the width of the window.
    */
  function reinit_cycle(wWidth) {
    var width = parseInt($(window).width(), 10);

    function destroyCarousel() {
      $('.content-block').cycle('destroy');
    }

    if (wWidth <= 767) {
      destroyCarousel();
      reinitCycle(1);
    }
    else if (wWidth >= 767 && width <= 1023) {
      destroyCarousel();
      reinitCycle(2);
    }
    else {
      destroyCarousel();
      reinitCycle(3);
    }
  }

  /*
    * Sends value to inject to build the slider according..
    */
  function reinitCycle(visibleSlides) {
    buildCarousel(visibleSlides);
  }

  /*
    * Initialize the slider.
    */
  $(document).ready(function () {
    initCycle();
  });

})(jQuery);
