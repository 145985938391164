$(document).ready(function () {

  'use strict';

  var firstRender = true;

  $(window).bind('load', function() {
    setTimeout(scrollToKeywords(), 1000);
  });

  function scrollToKeywords() {
    var searchParams = new URLSearchParams(window.location.search);
    var pathName = window.location.pathname;

    if (searchParams.has('keywords') && pathName != '/search' && pathName != '/search/') {
      var keywords = searchParams.get('keywords');
      var sectionBlocks = $('body').find('.section-rows .section-block');
      var targetBlock = "";

      // Loop trough all page blocks.
      $(sectionBlocks).each(function (index, block) {
        var search = [];

        if ($(block).text().toLowerCase().indexOf(keywords.toLowerCase()) >= 0 && firstRender) {
          search = $(block);
          firstRender = !firstRender;

          // If match exist set main block target.
          if (search.length > 0) {
            targetBlock = $(search);
            return;
          }
        }
      });

      // If target block is found scroll to position.
      if (targetBlock != "") {
        var offsetTop = $(targetBlock).offset().top;
        var header = $(document).find('header').innerHeight() ?? 0;

        // Scroll with animation to target block.
        $('html, body').animate({
          scrollTop: offsetTop - header
        }, 500, 'easeOutExpo');
      }
    }
  }
});
