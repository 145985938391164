/**
 * @file
 * Button Top Functionality.
 */

(function ($) {

  'use strict';

  /**
   * Attach Section Row Init function to Window Load event.
   */
  $(window).on('load', initButton);

  /**
   * Set function to scroll top.
   */
  function initButton() {
    $('.floating-upward').on('click', function (event) {
      event.preventDefault();

      // Add effect to scroll top.
      $('html, body').animate({
        scrollTop: 0
      }, 1000 );
    });
  }

})(jQuery);
