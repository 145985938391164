/**
 * @file
 * Adds Carousel Functionality for the Hero at Home.
 */

'use strict';

(function ($) {

  $(document).on('click', '.hero-home .featured-stories button.open-stories', function (e) {
    e.preventDefault();

    if (!$(this).find('.icon-open').hasClass('active')) {
      $(this).find('.icon-open').addClass('active');
    } else {
      $(this).find('.icon-open').removeClass('active');
    }

    if ($(document).find('.hero-home .featured-stories').hasClass('active')) {
      var cycle = $('.hero-home').find('.slide-items');
      $(cycle).cycle('goto', 0);
    }

    if (!$(document).find('.hero-home .featured-stories').hasClass('active')) {
      $(document).find('.hero-home .featured-stories').addClass('active');
      $(document).find('.hero-home .items .item .info').addClass('active');
    } else {
      $(document).find('.hero-home .featured-stories').removeClass('active');
      $(document).find('.hero-home .items .item .info').removeClass('active');

      if ($('.related-story-item').hasClass('active')) {
        $('.related-story-item').removeClass('active');
      }
    }
  });

  $(document).on('click', '.hero-home .featured-stories .featured-stories-wrapper .related-stories .related-story-item', function (e) {
    e.preventDefault();

    $(document).find('.hero-home .featured-stories .featured-stories-wrapper .related-stories .related-story-item').removeClass('active');
    $(this).addClass('active');
  });

  function setNormalState() {
    $(document).find('.hero-home .featured-stories').removeClass('active');
    $(document).find('.hero-home .items .item .info').removeClass('active');
    $('.hero-home .featured-stories button.open-stories').find('.icon-open').removeClass('active');
    $(document).find('.hero-home .featured-stories .featured-stories-wrapper .related-stories .related-story-item').removeClass('active');
  }

  var timer;
  $(window).on('resize', function () {
    clearTimeout(timer);

    timer = setTimeout(function () {
      if($(window).width() < 1024){
        setNormalState();
      }
    }, 400);
  });

})(jQuery);

function animateNumber(element, number, time) {
  var countStart = Math.random() * (number / 2) + 1;
  jQuery({ Counter: countStart }).animate({ Counter: $(element).text() }, {
    duration: time,
    easing: 'linear',
    step: function () {
      $(element).text(Math.floor(this.Counter));
    },
    complete: function() {
      $(element).text(number);
    }
  });
}

// Animate
$(window).on('load', function () {
  var time = 0;

  // Call open side panel after 3 seconds.
  setTimeout(openHomepageHeroSidePanel, 3000);

  $('.stat .count').each(function() {
    var fullNumber = $(this).text();
    var fullNumberSize = fullNumber.length;
    var num = fullNumber.match(/[\d\.]+/g);
    var element = this;

    var start;
    var number;
    var end;
    time = time + 1500;

    if (num != null){
      var number = num[0];
      var numberSize = number.length;
      var subString = fullNumber.indexOf(number);

      if (subString == 0) {
        if (fullNumberSize == numberSize) {
          animateNumber(element, number, time);
        }
        else {
          end = fullNumber.substring(numberSize);
          $(element).html("<span>" + number + "</span>" + end);
          var span = $(element).find("span");
          animateNumber(span, number, time);
        }
      }
      else {
        start = fullNumber.substring(0, subString);
        end = fullNumber.substring(numberSize + subString);
        $(element).html(start + "<span>" + number + "</span>" + end);
        var span = $(element).find("span");
        animateNumber(span, number, time);
      }
    }
  });

  /**
   * Automcatically open side panel.
   */
  function openHomepageHeroSidePanel() {
    $(document).find("button.open-stories").click();
  }

});
