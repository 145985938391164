/**
 * @file
 * Adds Carousel Block functionality.
 */

(function ($) {

  'use strict';

  /**
   * Attach Section Row Init function to Window Load event.
   */
  $(window).on('load', carouselBlockInit);

  /**
   * Initializes Carousel Blocks.
   */
  function carouselBlockInit() {
    var carouselSlides = document.querySelectorAll('.slide-actions button');
    if(carouselSlides.length > 1) {
      $(document).find('.carousel-block').each(carouselBlockItemInit);
    }
  }

  /**
   * Inits a Carousel Block Instance.
   */
  function carouselBlockItemInit(i, item) {
    var params = {
      slides: '.slide-item',
      fx: 'carousel',
      swipe: true,
      carouselVisible: 3,
      carouselFluid: false,
      speed: 500,
      paused: false,
      log: false
    };

    $(item).find('.slide-items').cycle(params);

    $($(item).find('.btn-container-left button')).on('click', function (e) {
      e.preventDefault();

      $(this).closest('.slides-block').find('.slide-items').cycle('prev');
    });

    $($(item).find('.btn-container-right button')).on('click', function (e) {
      e.preventDefault();

      $(this).closest('.slides-block').find('.slide-items').cycle('next');
    });
  }

  /**
   * Attach function to cycle-before event.
   */
  $(document).on('cycle-before', function (event, optionHash, outgoingSlideEl, incomingSlideEl, forwardFlag) {
    var count = parseInt(optionHash.slideCount, 10);
    var i = parseInt(optionHash.slideNum, 10);
    var j;
    if (forwardFlag) {
      j = i + 1;

      if (i == count) {
        j = 1;
      }
    }
    else {
      j = i - 2;

      if (j == 0) {
        j = count;
      }
      else if (j == -1) {
        j = count - 1;
      }
    }

    var cycle = $(outgoingSlideEl).closest('.slide-items');
    var prev = $(cycle).find('.slide-item.active');
    $(prev).removeClass('active');

    // Add active class.
    var next = $(cycle).find('.slide-item[data-i="' + j + '"]');
    setTimeout(function () {
      $(next).addClass('active');
    }, 50);

    // Update Pagination.
    $(cycle).parent().parent().find('.slide-actions .slide-dot.active').removeClass('active');
    $(cycle).parent().parent().find('.slide-actions .slide-dot[data-i="' + i + '"]').addClass('active');
  });

  /**
   * Carousel Dots Click.
   */
  $(document).on('click', '.carousel-block .slide-actions .slide-dot', function (e) {
    e.preventDefault();
    var item = parseInt($(this).attr('data-i'), 10) - 1;
    var slider = $(this).parent().parent().find('.slide-items');
    $(slider).cycle('goto', item);
  });

  var rtime;
  var timeout = false;
  var delta = 200;

  $(window).on('resize',function() {
    rtime = new Date();

    if(timeout === false) {
      timeout = true;
      setTimeout(resizeend, delta);
      var params = {
        slides: '.slide-item',
        fx: 'carousel',
        swipe: true,
        carouselVisible: 3,
        carouselFluid: false,
        speed: 250,
        paused: false,
        log: false
      };

      $(document).find('.carousel-block').each(function(index, item) {
        if($(item).find('.slide-items').cycle !== undefined) {
          $(item).find('.slide-items').cycle('destroy');
          $(item).find('.slide-items').cycle(params);
        }
      });
    }
  });

  function resizeend() {
    if(new Date() - rtime < delta) {
      setTimeout(resizeend, delta);
    }else{
      timeout = false;
    }
  }
})(jQuery);
