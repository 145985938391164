/**
 * @file
 * Adds GTranslate Functionality.
 */

(function ($) {

  'use strict';

  /**
  * Attach Carousel Functionality to GTranslate.
  */
  $(window).on('load', function () {
    setTimeout(function () {
      initGTranslate();
    }, 500);
  });

  /**
  * Initializes GTranslate.
  */
  function initGTranslate() {
    if ($(document).find('#g-translate-section').length > 0) {
      setSelectedLang();
      $(document).find('#g-translate-section .lang-result .lang-results-wrapper').append('<div class="item-link"><button data-value="en|en" data-lang="en" class="notranslate">English</button></div>');
      $(document).find('#g-translate-section .lang-result .lang-results-wrapper').append('<div class="item-link"><button data-value="en|ja" data-lang="ja" class="notranslate">日本語</button></div>');
      $(document).find('#g-translate-section .lang-result .lang-results-wrapper').append('<div class="item-link"><button data-value="en|es" data-lang="es" class="notranslate">Español</button></div>');
      $(document).find('#g-translate-section .lang-result .lang-results-wrapper').append('<div class="item-link"><button data-value="en|km" data-lang="km" class="notranslate">ខ្មែរ</button></div>');
      $(document).find('#g-translate-section .lang-result .lang-results-wrapper').append('<div class="item-link"><button data-value="en|fr" data-lang="fr" class="notranslate">Français</button></div>');
      $(document).find('#g-translate-section .lang-result .lang-results-wrapper').append('<div class="item-link"><button data-value="en|ko" data-lang="ko" class="notranslate">한국어</button></div>');
      $(document).find('#g-translate-section .lang-result .lang-results-wrapper').append('<div class="item-link"><button data-value="en|ar" data-lang="ar" class="notranslate">عرب</button></div>');
      $(document).find('#g-translate-section .lang-result .lang-results-wrapper').append('<div class="item-link"><button data-value="en|pl" data-lang="pl" class="notranslate">Polskie</button></div>');
      $(document).find('#g-translate-section .lang-result .lang-results-wrapper').append('<div class="item-link"><button data-value="en|zh-CN" data-lang="zh-CN" class="notranslate">中文</button></div>');
      $(document).find('#g-translate-section .lang-result .lang-results-wrapper').append('<div class="item-link"><button data-value="en|ru" data-lang="ru" class="notranslate">Pусский</button></div>');
      $(document).find('#g-translate-section .lang-result .lang-results-wrapper').append('<div class="item-link"><button data-value="en|de" data-lang="de" class="notranslate">Deutsche</button></div>');
      $(document).find('#g-translate-section .lang-result .lang-results-wrapper').append('<div class="item-link"><button data-value="en|tl" data-lang="tl" class="notranslate">Tagalog</button></div>');
      $(document).find('#g-translate-section .lang-result .lang-results-wrapper').append('<div class="item-link"><button data-value="en|el" data-lang="el" class="notranslate">Ελληνικά</button></div>');
      $(document).find('#g-translate-section .lang-result .lang-results-wrapper').append('<div class="item-link"><button data-value="en|vi" data-lang="vi" class="notranslate">Tiếng Việt</button></div>');
      $(document).find('#g-translate-section .lang-result .lang-results-wrapper').append('<div class="item-link"><button data-value="en|hi" data-lang="hi" class="notranslate">हिंदी</button></div>');

      $(document).find('#g-translate-section .lang-result button').on('click', function () {
        var data_value = $(this).attr('data-value');
        var data_lang = $(this).attr('data-lang');
        $('#g-translate-section > select option:selected').removeAttr('selected');
        $('#g-translate-section > select option[value="' + data_value + '"]').attr('selected', true);
        $('#g-translate-section > select').val(data_value);
        $('html').attr('lang', data_lang);
        $('#g-translate-section .lang-result').removeClass('opened');
        $('#g-translate-section .toggle-language-select-control').removeClass('opened');

        doGTranslate(data_lang);

        setSelectedLang();
      });
    }
  }

  function doGTranslate(lang_pair) {
    if (lang_pair != '') {
      var lang = lang_pair;
      var plang = location.hostname.split('.')[0];
      if (plang.length !=2 && plang.toLowerCase() != 'zh-cn' && plang.toLowerCase() != 'zh-tw') plang = 'en';
      location.href = location.protocol + '//' + (lang == 'en' ? '' : lang+'.') + location.hostname.replace('www.', '').replace(RegExp('^' + plang + '\\.'), '') + location.pathname + location.search;
    }
  }

  function setSelectedLang() {
    if ($(document).find('#g-translate-section').length > 0) {
      switch ($('html').attr('lang')) {
        case 'fr':
          $(document).find('#g-translate-section .toggle-language-select-control .lang-selected').text('FR');
          $(document).find('.goog-te-combo option[value="fr"]').attr('selected', true);
          break;
        case 'es':
          $(document).find('#g-translate-section .toggle-language-select-control .lang-selected').text('ES');
          $(document).find('.goog-te-combo option[value="es"]').attr('selected', true);
          break;
        case 'ar':
          $(document).find('#g-translate-section .toggle-language-select-control .lang-selected').text('AR');
          $(document).find('.goog-te-combo option[value="ar"]').attr('selected', true);
          break;
        case 'zh-CN':
          $(document).find('#g-translate-section .toggle-language-select-control .lang-selected').text('CN');
          $(document).find('.goog-te-combo option[value="zh-TW"]').attr('selected', true);
          break;
        case 'de':
          $(document).find('#g-translate-section .toggle-language-select-control .lang-selected').text('DE');
          $(document).find('.goog-te-combo option[value="de"]').attr('selected', true);
          break;
        case 'el':
          $(document).find('#g-translate-section .toggle-language-select-control .lang-selected').text('EL');
          $(document).find('.goog-te-combo option[value="el"]').attr('selected', true);
          break;
        case 'hi':
          $(document).find('#g-translate-section .toggle-language-select-control .lang-selected').text('HI');
          $(document).find('.goog-te-combo option[value="hi"]').attr('selected', true);
          break;
        case 'ja':
          $(document).find('#g-translate-section .toggle-language-select-control .lang-selected').text('JA');
          $(document).find('.goog-te-combo option[value="ja"]').attr('selected', true);
          break;
        case 'km':
          $(document).find('#g-translate-section .toggle-language-select-control .lang-selected').text('KM');
          $(document).find('.goog-te-combo option[value="km"]').attr('selected', true);
          break;
        case 'ko':
          $(document).find('#g-translate-section .toggle-language-select-control .lang-selected').text('KO');
          $(document).find('.goog-te-combo option[value="ko]').attr('selected', true);
          break;
        case 'pl':
          $(document).find('#g-translate-section .toggle-language-select-control .lang-selected').text('PL');
          $(document).find('.goog-te-combo option[value="pl"]').attr('selected', true);
          break;
        case 'ru':
          $(document).find('#g-translate-section .toggle-language-select-control .lang-selected').text('RU');
          $(document).find('.goog-te-combo option[value="ru"]').attr('selected', true);
          break;
        case 'tl':
          $(document).find('#g-translate-section .toggle-language-select-control .lang-selected').text('TL');
          $(document).find('.goog-te-combo option[value="tl"]').attr('selected', true);
          break;
        case 'vi':
          $(document).find('#g-translate-section .toggle-language-select-control .lang-selected').text('VI');
          $(document).find('.goog-te-combo option[value="vi"]').attr('selected', true);
          break;
        default:
          $(document).find('#g-translate-section .toggle-language-select-control .lang-selected').text('EN');
          $(document).find('.goog-te-combo option[value="en"]').attr('selected', true);
      }
    }
  }

   $(document).find('#g-translate-section .toggle-language-select-control').on('click', function () {
     $(document).find('#g-translate-section .lang-result').toggleClass('opened');
     $(this).toggleClass('opened');
     console.log($(document).find('.dropdown-zip-toggler'));
     $(document).find('.dropdown-zip .dropdown-zip-toggler').removeClass('opened');
     $(document).find('.dropdown-zip .dropdown-zip-toggler').addClass('closed');

     $(document).find('.dropdown-zip .dropdown-menu-zip').removeClass('opened');
     $(document).find('.dropdown-zip .dropdown-menu-zip').addClass('closed');
   });

  $('html').on('change', function () {
    setTimeout(function () {
      setSelectedLang();
    }, 500);
  });

})(jQuery);
