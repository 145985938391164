/**
 * @file
 * Adds Timeline Block functionality.
 */
(function ($) {

  'use strict';

  $(window).setBreakpoints({
    distinct: true,
    breakpoints: [
      0,
      768,
      1024,
      1440
    ]
  });

  //Attach Timeline Block Init function to Window Load event.
  $(window).on('load', function () {
    initTimelineBlock();
  });

  var windowWidth = $(window).width();
  var left, leftCycle, clearTime;

  //Initialize Timeline Block.
  function initTimelineBlock() {
    $('.timeline-block').each(function () {
      // Get the slider components.
      var windowWidth = $(window).width();
      var block = $(this);
      var imageItemButton = $(block).find('.image-gallery-slider .image-gallery-item button');
      var imgSlider = $(block).find('.image-gallery-slider');
      var dateSlider = $(block).find('.conference-timeline-content');
      var timelineArticleYear = $(block).find('.timeline-article-year');
      var itemsCount = $(block).find('.image-gallery-slider .image-gallery-item').length;
      var startSlide = parseInt((itemsCount / 2) - 1);
      var timelineArticle = $(block).find('.timeline-article');

      $('.prev.timeline').on('click', function () {
        var dataSlide = $(this).attr("value-data-slide");
        dataSlide = dataSlide - 1;
        $(timelineArticle).removeClass("active-slide");
        $(imgSlider).cycle('goto', dataSlide);
        $(dateSlider).cycle('goto', dataSlide);
      });

      $('.next.timeline').on('click', function () {
        var dataSlide = $(this).attr('value-data-slide');
        dataSlide = parseInt(dataSlide) + 1;
        $(timelineArticle).removeClass('active-slide');
        $(imgSlider).cycle('goto', dataSlide);
        $(dateSlider).cycle('goto', dataSlide);
      });

      $(timelineArticleYear).on('click', function () {
        $(timelineArticle).removeClass('active-slide');
        var valueDataSlide = $(this).attr('value-data-slide');
        $(imgSlider).cycle('goto', valueDataSlide);
        $(dateSlider).cycle('goto', valueDataSlide);
      });

      $(imgSlider).on('swipeleft', function () {
        var itemsNum = $(block).find('.image-gallery-slider .cycle-carousel-wrap').find('.image-gallery-item.cycle-slide.active-slide');
        var valueDataSlide = $(itemsNum).attr('data-slide');
        valueDataSlide = parseInt(valueDataSlide) + 1;
        $(timelineArticle).removeClass('active-slide');

        if (valueDataSlide == itemsCount) {
          $(dateSlider).cycle('goto', 0);
        } else {
          $(dateSlider).cycle('goto', valueDataSlide);
        }
      });

      $(imgSlider).on('swiperight', function () {
        var itemsNum = $(block).find('.image-gallery-slider .cycle-carousel-wrap').find('.image-gallery-item.cycle-slide.active-slide');
        var valueDataSlide = $(itemsNum).attr('data-slide');

        if (valueDataSlide != 0) {
          valueDataSlide = parseInt(valueDataSlide) - 1;
          $(timelineArticle).removeClass('active-slide');
          $(dateSlider).cycle('goto', valueDataSlide);
        }
      });

      // Now start the initializations.
      imgSlidersInit(imgSlider, startSlide, windowWidth);
      dateSlidersInit(dateSlider, startSlide, windowWidth);
    });
  }

  $(document).on('click', '.timeline-block .image-gallery-slider-wrapper .image-gallery-slider .image-gallery-item .image .summary .date button', function () {
    if ($(this).closest('.image-gallery-item').hasClass('open-active')) {
      $(this).closest('.image-gallery-item').removeClass('open-active');
    } else {
      $(this).closest('.image-gallery-item').addClass('open-active');
    }
  });

  //Delet Class open-active.
  function deletedClassMobile() {
    $('.timeline-block').each(function () {
      var windowWidth = $(window).width();
      var block = $(this);
      var imageItemButton = $(block).find('.image-gallery-slider .image-gallery-item');

      if (windowWidth >= 1024) {
        if ($(imageItemButton).hasClass('open-active')) {
          $(imageItemButton).removeClass('open-active');
        }
      }
    });
  }

  //Initialize Images Slider.
  function imgSlidersInit(imgSlider, startSlide, windowWidth) {
    $(imgSlider).cycle({
      log: false,
      paused: true,
      allowWrap: false,
      slideActiveClass: 'active-slide',
      slides: '.image-gallery-item',
      fx: 'carousel',
      carouselFluid: true,
      swipe: true
    });

    leftCycle = $(imgSlider).find('.cycle-carousel-wrap');

    if(windowWidth < 768) {
      left = (windowWidth - 330) / 2;
    } else {
      left = (windowWidth - 614) / 2;
    }

    $(leftCycle).offset({left: left});
  }

  //Initialize the dates slider.
  function dateSlidersInit(dateSlider, startSlide, windowWidth) {
    $(dateSlider).cycle({
      log: false,
      paused: true,
      allowWrap: false,
      slideActiveClass: 'active-slide',
      slides: '.timeline-article',
      fx: 'carousel',
      carouselFluid: true,
    });

    leftCycle = $(dateSlider).find('.cycle-carousel-wrap');
    if(windowWidth < 768) {
      left = (windowWidth - 234) / 2;
    } else {
      left = (windowWidth - 294) / 2;
    }

    $(leftCycle).offset({left: left});
  }

  function destroyCarousel() {
    $('.timeline-block').each(function () {
      $(this).find('.image-gallery-slider').cycle('destroy');
      $(this).find('.conference-timeline-content').cycle('destroy');
    });
  }

  /**
 * Resets Cycle Instances on breakpoint-change.
 */
  var clearProjectFeaturedTime;
  $(window).bind('enterBreakpoint0',function() {
    clearProjectFeaturedTime = setTimeout(function () {
      destroyCarousel();
      initTimelineBlock();
      deletedClassMobile();
    }, 250);
  });

  $(window).bind('enterBreakpoint768',function() {
    clearProjectFeaturedTime = setTimeout(function () {
      destroyCarousel();
      initTimelineBlock();
      deletedClassMobile();
    }, 250);
  });

  $(window).bind('enterBreakpoint1024',function() {
    clearProjectFeaturedTime = setTimeout(function () {
      destroyCarousel();
      initTimelineBlock();
      deletedClassMobile();
    }, 250);
  });

  $(window).bind('enterBreakpoint1440',function() {
    clearProjectFeaturedTime = setTimeout(function () {
      destroyCarousel();
      initTimelineBlock();
      deletedClassMobile();
    }, 250);
  });

})(jQuery);

