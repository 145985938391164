/**
 * @file
 * Generic Page - Adds Image Gallery Slider Block functionality.
 */

$(function ($) {
  'use strict';

  $(document).ready(function() {
    setTimeout(function() {
      initImageGallerySlider();
    }, 750);
  });

  /**
   * Initializes Image Gallery Slider Blocks.
   */
  function initImageGallerySlider() {
    $('.image-gallery-generic-block').each(initImageGallerySlides);

    $('.image-gallery-generic-block .show-more-circle').on( 'click', function ( event ) {
      event.preventDefault();
      $('#gallery-modal').modal('show');
      var buttonPrevArrow = $('.modal.modal-gallery-detail .image-gallery-detail-arrow-buttons').find('button.prev-arrow');
      $(buttonPrevArrow).blur();
      $(buttonPrevArrow).focus();
    });
  }

  /**
   * Inits a Image Gallery Slider Block Instance.
   */
  function initImageGallerySlides(i, item) {
    var slides = $(item).find('.image-gallery-item');
    var captionTemplate = $(item).find('.caption-template');
    var options = {
      slides: slides,
      fx: 'carousel',
      swipe: true,
      carouselVisible: 2,
      carouselFluid: false,
      caption: captionTemplate,
      captionTemplate: '<span class="pagination-count notranslate">{{slideNum}}</span><span>/</span><span class="pagination-limit">{{slideCount}}</span>',
      speed: 250,
      paused: true,
    };

    var cycle = $(item).find('.image-gallery-slider').cycle(options);

    $(item).find('button.left-arrow').on('click', function () {
      $(cycle).cycle('prev');
    });

    $(item).find('button.right-arrow').on('click', function () {
      $(cycle).cycle('next');
    });

    $(item).find('.image-gallery-item .image a').on('click', function () {
      $(this).modal({
        fadeDuration: 350,
        fadeDelay: 0.50
      });
    });

    $(item).find('.modal-gallery-detail .image-gallery-detail-arrow-buttons .prev-arrow').on('click', function (e) {
      e.preventDefault();

      var id = $(this).attr('data-id');
      var anchorElement = $(this).closest('body').find('main #main-container .section-rows .imageGalleryBlock a');

      $(anchorElement).each(function () {
        if ( $(this).attr('href').substring(1) == id ) {
          $(this).modal({
            fadeDuration: 500,
            fadeDelay: .25
          });
        }
      });

      $(cycle).cycle('prev');
    });

    $(item).find('.modal-gallery-detail .image-gallery-detail-arrow-buttons .next-arrow').on('click', function (e) {
      e.preventDefault();

      var id = $(this).attr('data-id');
      var anchorElement = $(this).closest('body').find('main #main-container .section-rows .imageGalleryBlock a');

      $(anchorElement).each(function () {
        if ( $(this).attr('href').substring(1) == id ) {
          $(this).modal({
            fadeDuration: 500,
            fadeDelay: .25
          });
        }
      });

      $(cycle).cycle('next');
    });

  }

  /**
   * Resets a Cycle Instance.
   */
  function carouselCycleReset(i, item) {
    var slides = $(item).find('.image-gallery-item');
    var captionTemplate = $(item).find('.caption-template');
    var options = {
      slides: slides,
      fx: 'carousel',
      swipe: true,
      carouselVisible: 2,
      carouselFluid: false,
      caption: captionTemplate,
      captionTemplate: '<span class="pagination-count notranslate">{{slideNum}}</span><span>/</span><span class="pagination-limit">{{slideCount}}</span>',
      speed: 250,
      paused: false,
    };

    $(item).find('.image-gallery-slider').cycle('destroy');
    $(item).find('.image-gallery-slider').cycle(options);
  }

  /**
   * Resets Cycle Instances on breakpoint-change.
   */
  $(document).on('breakpoint-change', function () {
    $('.image-gallery-generic-block')
      .find('.image-gallery-slider')
      .each(function(i, item) {
        carouselCycleReset(i, item);
    });
  });
});
