/**
 * @file
 * Adds Faceted Filter Form Functionality.
 */

(function ($) {

  'use strict';

  $(window).on('load', function () {

    $(document).find('.umbraco-forms-form').each(function () {
      dateInit($(this));
    });

    $(document).find('.faceted-filter-block').each(function () {
      formInit($(this));
    });
  });

  function formInit(form) {
    $(form).find('.date-left').datetimepicker({
      format: 'MM/DD/YYYY'
    });
    $(form).find('.date-right').datetimepicker({
      useCurrent: false,
      format: 'MM/DD/YYYY'
    });
  }

  function dateInit(date) {
    $(date).find('.date-field').datetimepicker({
      format: 'L'
    });
  }

  $(document).find('.faceted-filter-block .date-left').on('change.datetimepicker', function (e) {
    $(document).find('.faceted-filter-block .date-right').datetimepicker();
  });

  $(document).find('.faceted-filter-block .date-right').on('change.datetimepicker', function (e) {
    $(document).find('.faceted-filter-block .date-left').datetimepicker();
  });
})(jQuery);
