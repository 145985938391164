/**
 * @file
 * Hero image get height and width functionality.
 */

(function ($) {

  'use strict';

  $(window).on('load', function() {
    $(document).find('.featured-stories-block').each(function () {
      initFeaturedSlider(this);
      $('body').addClass('has-stories-block');
    });
  });

  $(document).ready(function() {
    $(document).find('.card-grid-block .card-container-fluid .card-container-row .main-item').each(function () {
      //initStories(this);
    });
  });

  function initFeaturedSlider(parent){
    var itemId = $(parent).find('.cart-container .cart-content .cart-content-text .content-arrow .story-content-anchor').attr('data-id');

    if (itemId !== undefined) {
      $.ajax({
        url: '/umbraco/Surface/Story/GetSlides/' + itemId,
        type: 'get',
        success: function (data) {
          var dataModalStories = $(parent).find('.cart-container .cart-content .content-arrow a').attr('href');
          var modal = $(document).find(dataModalStories);
          $(modal).find('.content').append(data);
          $(document).find('.article-teaser .slider-wrapper .slider-items .article-slide-item.Dark .scroll-box .article-header .close-article').remove();
          $(document).find('.article-teaser .slider-wrapper .slider-items .article-slide-item .scroll-box .story-content .limiter .content .body .article-header-inner .close-article').remove();
          $(document).find('.article-header .close-article').remove();
        },
        error: function () {
          return "";
        }
      });
    }
  }

  $(document).on('click', '.card-grid-block .main-item[data-type="story"]', function () {
    initStories(this);
  })

  function initStories(parent){
    if ($(parent).attr('data-type') !== 'story') {
      return false;
    }

    var itemId = $(parent).find('.teaser-cta a').attr('data-id');
    var dataModalID = $(parent).find('.teaser-cta a').attr('href');
    var modal = $(document).find(dataModalID + ' .content');

    if ($(modal).find('.article-teaser').length > 0) {
      return false;
    }

    if (itemId !== undefined) {
      $.ajax({
        url: '/umbraco/Surface/Story/GetSlides/' + itemId,
        type: 'get',
        success: function (data) {
          $(modal).append(data);
          $(document).find(dataModalID + ' .content .article-teaser .close-article').remove();
          $(document).find('.article-teaser .close-article');
          playAllVideos();
        },
        error: function () {
          return "";
        }
      });
    }
  }

  /**
  * Add functionality to autoplay videos.
  */
  function playAllVideos() {
    const media = document.querySelectorAll('video');

    $(media).each(function (video) {
      // Show loading animation.
      var playPromise = media[video].play();
      if (playPromise !== undefined) {
        playPromise.then(function() {
          // Autoplay started!
          console.log('Autoplay was started');
        }).catch(function(error) {
          // Autoplay was prevented.
          console.log('Autoplay was prevented', error);
        });
      }
    });
  }

  $(document).on('click', '#popup-article .article-teaser .close-modal', function () {
    if ($('html').hasClass('popup-article-on')) {
      $('html').removeClass('popup-article-on popup-article-ready popup-article-full');
    }
  });

})(jQuery);
