/**
 * @file
 * Featured content teaser pagination Functionality.
 */

(function ($) {

  'use strict';

  /**
   * Attach Featured content teaser pagination Functionality to Window Load event.
   */
  $(window).on('load', function () {
    $(document).find('.featuredContentTeaser').each(function () {
      initPagination(this);
    });
  });

  function initPagination(obj) {
    var items = $(obj).find('.card-grid-block .card-container-row .main-item');
    var itemsArray = [];

    $(items).each(function (index, item) {
      itemsArray.push(item);
    });

    $(obj).find('.pagination-generated .pagination-content').pagination({
        dataSource: itemsArray,
        prevText: '<span class="material-icons">keyboard_arrow_left</span>',
        nextText: '<span class="material-icons">keyboard_arrow_right</span>',
        ulClassName: 'nav nav-tabs d-flex justify-content-center t-pagination',
        pageSize: 9,
        pageNumber: 1,
        hideWhenLessThanOnePage: true,
        callback: function(data, pagination) {
          $(obj).find('.pagination-generated .content .card-container-row .main-item').remove();

          data.forEach(function (item, index) {
            $(obj).find('.pagination-generated .content .card-container-row').append(item);
          });

          if (Math.ceil(pagination.totalNumber / 9) <= 1) {
            $(obj).find('.pagination-generated .pagination-content').addClass('d-none');
          }
        }
    });
  }
})(jQuery);

