/**
 * @file
 * Adds functionality to download documents.
 */

(function ($) {
  'use strict';

  $(window).on('load', downloadDocument);

  /**
   * This function obtains the src of the tag and download the file.
   *
   */
  function downloadDocument(){
    $(document).find('.events-calendar-block .schedule-content .wrapper-schedule .schedule .meeting .meeting-info .links .link a.download-link').on('click', function (e) {
      e.preventDefault();
      // eslint-disable-next-line no-undef
      download($(this).attr('href'));
    });

    $(document).on('click', '.link-list-generic-block .section-padding .content .link-items .l-item a', function (e) {
      e.preventDefault();

      if ($(this).attr('data-type') === 'has-file') {
        if (!isExternal($(this).attr('href'))) {
          // eslint-disable-next-line no-undef
          download($(this).attr('href'));
        } else {
          if ($(this).attr('target') == '_blank') {
            window.open($(this).attr('href'), '_blank');
          } else {
            window.open($(this).attr('href'), '_self');
          }
        }
      } else {
        if ($(this).attr('target') == '_blank') {
          window.open($(this).attr('href'), '_blank');
        } else {
          window.open($(this).attr('href'), '_self');
        }
      }
    });

    var checkDomain = function(url) {
      if ( url.indexOf('//') === 0 ) { url = location.protocol + url; }
      return url.toLowerCase().replace(/([a-z])?:\/\//,'$1').split('/')[0];
    };

    var isExternal = function(url) {
      return ( ( url.indexOf(':') > -1 || url.indexOf('//') > -1 ) && checkDomain(location.href) !== checkDomain(url) );
    };
  }

})(jQuery);
