(function ($) {
  'use strict';

  /**
   * Attach search water functionality.
   */
  $(window).on('load', searchWater);

  function searchWater() {
    var menuOpened = false;
    const searchInput = $(document).find('.dropdown-menu-zip input');
    const modal = $(document).find('.dropdown-menu-zip');
    const toggler = $(document).find('.dropdown-zip .dropdown-zip-toggler');
    const message = $(document).find('.dropdown-menu-zip .search-result .message');
    const icon = $(document).find('.dropdown-menu-zip .search-result .icons svg').clone()[0];
    const iconString = '<svg xmlns="http://www.w3.org/2000/svg" class="icon" viewBox="0 0 1024 1024">' + $(icon).html() + '</svg>';

    $(searchInput).on('keydown', function (event) {
      var zip_finded = false;

      if ((event.keyCode >= 48 && event.keyCode <= 57)
        || (event.keyCode >= 96 && event.keyCode <= 105)
        || (event.keyCode == 13)
        || (event.keyCode == 8)
        || (event.keyCode == 27)
      ) {
        if(event.keyCode == 13) {
          $(document).find('.dropdown-menu-zip .search-result .your-water .your-water-info').remove();
          $(document).find('.dropdown-menu-zip .search-result').removeClass('hidden');
          $(document).find('header > .navbar .navbar-2 .dropdown-menu-zip .zip-code-search').addClass('hidden');

          var search = $(this).val();
          var mwd_zip_code_search ="<div class='your-water-info'>";

          var finded = "";
          for (var i = 0; i < mwd_zip_code.length; i++) {
            var item = mwd_zip_code[i];

            if( item.field4 == search){
              zip_finded = true;
              var zip = item.field4;
              var name = item.field2;
              var url = item.field7;
              var searched = "<div class='water'><p class='water-zip'>" + name + "</p>" + url + "</div>";

              finded = finded + searched;
            }
          }

          var output = mwd_zip_code_search + finded + "</div>";

          if( zip_finded == true ){
            $(message).text("Your Local Water Agency is");
            $(document).find('.dropdown-menu-zip .search-result .your-water').append( output );
            $(document).find('.dropdown-menu-zip .search-result .your-water .your-water-info .water a').append(iconString);
          }
          else {
            $(message).text("We can't find your zip code");
          }

          zip_finded = false;
        }
      }
    });

    $( toggler ).on( 'click' ,function () {
      if(menuOpened){
        $(toggler).removeClass('opened');
        $(toggler).addClass('closed');
        $(modal).removeClass('opened');
        $(modal).addClass('closed');
        $(searchInput).val('');
        menuOpened = false;
      }
      else {
        $(toggler).addClass('opened');
        $(toggler).removeClass('closed');
        $(modal).addClass('opened');
        $(modal).removeClass('closed');
        $(document).find('.dropdown-menu-zip .search-result').addClass('hidden');
        $(document).find('header > .navbar .navbar-2 .dropdown-menu-zip .zip-code-search').removeClass('hidden');
        $(searchInput).val('');
        menuOpened = true;
      }

      $(document).find('#g-translate-section .lang-result').removeClass('opened');
      $(document).find('#g-translate-section .toggle-language-select-control.fake-lang').removeClass('opened');
    });
  }

  /**
   * Array for zip codes for local water.
   */
  var mwd_zip_code =[
{"field1":"1","field2":"City of Anaheim","field3":"City of Anaheim","field4":"92801","field5":"NULL","field6":"714-765-4256","field7":"<a href=\"http://www.anaheim.net/savewater\" target='_blank' class=''>Go to Site</a>","field8":"714-765-4256","field9":"Approved"},
{"field1":"2","field2":"City of Anaheim","field3":"City of Anaheim","field4":"92802","field5":"NULL","field6":"714-765-4256","field7":"<a href=\"http://www.anaheim.net/savewater\" target='_blank' class=''>Go to Site</a>","field8":"714-765-4256","field9":"Approved"},
{"field1":"3","field2":"City of Anaheim","field3":"City of Anaheim","field4":"92803","field5":"NULL","field6":"714-765-4256","field7":"<a href=\"http://www.anaheim.net/savewater\" target='_blank' class=''>Go to Site</a>","field8":"714-765-4256","field9":"Approved"},
{"field1":"4","field2":"City of Anaheim","field3":"City of Anaheim","field4":"92804","field5":"NULL","field6":"714-765-4256","field7":"<a href=\"http://www.anaheim.net/savewater\" target='_blank' class=''>Go to Site</a>","field8":"714-765-4256","field9":"Approved"},
{"field1":"5","field2":"City of Anaheim","field3":"City of Anaheim","field4":"92805","field5":"NULL","field6":"714-765-4256","field7":"<a href=\"http://www.anaheim.net/savewater\" target='_blank' class=''>Go to Site</a>","field8":"714-765-4256","field9":"Approved"},
{"field1":"6","field2":"City of Anaheim","field3":"City of Anaheim","field4":"92806","field5":"NULL","field6":"714-765-4256","field7":"<a href=\"http://www.anaheim.net/savewater\" target='_blank' class=''>Go to Site</a>","field8":"714-765-4256","field9":"Approved"},
{"field1":"7","field2":"City of Anaheim","field3":"City of Anaheim","field4":"92807","field5":"NULL","field6":"714-765-4256","field7":"<a href=\"http://www.anaheim.net/savewater\" target='_blank' class=''>Go to Site</a>","field8":"714-765-4256","field9":"Approved"},
{"field1":"8","field2":"City of Anaheim","field3":"City of Anaheim","field4":"92808","field5":"NULL","field6":"714-765-4256","field7":"<a href=\"http://www.anaheim.net/savewater\" target='_blank' class=''>Go to Site</a>","field8":"714-765-4256","field9":"Approved"},
{"field1":"9","field2":"City of Beverly Hills","field3":"City of Beverly Hills","field4":"90210","field5":"NULL","field6":"310-285-2436","field7":"<a href=\"http://www.beverlyhills.org\" target='_blank' class=''>Go to Site</a>","field8":"310-285-2436","field9":"Approved"},
{"field1":"10","field2":"City of Beverly Hills","field3":"City of Beverly Hills","field4":"90209","field5":"NULL","field6":"310-285-2436","field7":"<a href=\"http://www.beverlyhills.org\" target='_blank' class=''>Go to Site</a>","field8":"310-285-2436","field9":"Approved"},
{"field1":"11","field2":"City of Beverly Hills","field3":"City of Beverly Hills","field4":"90213","field5":"NULL","field6":"310-285-2436","field7":"<a href=\"http://www.beverlyhills.org\" target='_blank' class=''>Go to Site</a>","field8":"310-285-2436","field9":"Approved"},
{"field1":"12","field2":"City of Beverly Hills","field3":"City of Beverly Hills","field4":"90035","field5":"NULL","field6":"310-285-2436","field7":"<a href=\"http://www.beverlyhills.org\" target='_blank' class=''>Go to Site</a>","field8":"310-285-2436","field9":"Approved"},
{"field1":"13","field2":"City of Beverly Hills","field3":"City of Beverly Hills","field4":"90048","field5":"NULL","field6":"310-285-2436","field7":"<a href=\"http://www.beverlyhills.org\" target='_blank' class=''>Go to Site</a>","field8":"310-285-2436","field9":"Approved"},
{"field1":"14","field2":"City of Beverly Hills","field3":"City of Beverly Hills","field4":"90211","field5":"NULL","field6":"310-285-2436","field7":"<a href=\"http://www.beverlyhills.org\" target='_blank' class=''>Go to Site</a>","field8":"310-285-2436","field9":"Approved"},
{"field1":"15","field2":"City of Beverly Hills","field3":"City of Beverly Hills","field4":"90212","field5":"NULL","field6":"310-285-2436","field7":"<a href=\"http://www.beverlyhills.org\" target='_blank' class=''>Go to Site</a>","field8":"310-285-2436","field9":"Approved"},
{"field1":"16","field2":"City of Beverly Hills","field3":"City of Beverly Hills","field4":"90069","field5":"NULL","field6":"310-285-2436","field7":"<a href=\"http://www.beverlyhills.org\" target='_blank' class=''>Go to Site</a>","field8":"310-285-2436","field9":"Approved"},
{"field1":"17","field2":"City of Burbank","field3":"City of Burbank","field4":"91507","field5":"NULL","field6":"310-285-2436","field7":"<a href=\"http://www.burbankwaterandpower.com/\" target='_blank' class=''>Go to Site</a>","field8":"310-285-2436","field9":"Approved"},
{"field1":"18","field2":"City of Burbank","field3":"City of Burbank","field4":"91501","field5":"NULL","field6":"310-285-2436","field7":"<a href=\"http://www.burbankwaterandpower.com/\" target='_blank' class=''>Go to Site</a>","field8":"310-285-2436","field9":"Approved"},
{"field1":"19","field2":"City of Burbank","field3":"City of Burbank","field4":"91502","field5":"NULL","field6":"310-285-2436","field7":"<a href=\"http://www.burbankwaterandpower.com/\" target='_blank' class=''>Go to Site</a>","field8":"310-285-2436","field9":"Approved"},
{"field1":"20","field2":"City of Burbank","field3":"City of Burbank","field4":"91503","field5":"NULL","field6":"310-285-2436","field7":"<a href=\"http://www.burbankwaterandpower.com/\" target='_blank' class=''>Go to Site</a>","field8":"310-285-2436","field9":"Approved"},
{"field1":"21","field2":"City of Burbank","field3":"City of Burbank","field4":"91504","field5":"NULL","field6":"310-285-2436","field7":"<a href=\"http://www.burbankwaterandpower.com/\" target='_blank' class=''>Go to Site</a>","field8":"310-285-2436","field9":"Approved"},
{"field1":"22","field2":"City of Burbank","field3":"City of Burbank","field4":"91505","field5":"NULL","field6":"310-285-2436","field7":"<a href=\"http://www.burbankwaterandpower.com/\" target='_blank' class=''>Go to Site</a>","field8":"310-285-2436","field9":"Approved"},
{"field1":"23","field2":"City of Burbank","field3":"City of Burbank","field4":"91506","field5":"NULL","field6":"310-285-2436","field7":"<a href=\"http://www.burbankwaterandpower.com/\" target='_blank' class=''>Go to Site</a>","field8":"310-285-2436","field9":"Approved"},
{"field1":"24","field2":"Calleguas Municipal Water District","field3":"NULL","field4":"90265","field5":"NULL","field6":"805-526-9323","field7":"<a href=\"http://www.calleguas.com\" target='_blank' class=''>Go to Site</a>","field8":"805-526-9323","field9":"Approved"},
{"field1":"25","field2":"Calleguas Municipal Water District","field3":"NULL","field4":"91302","field5":"NULL","field6":"805-526-9323","field7":"<a href=\"http://www.calleguas.com\" target='_blank' class=''>Go to Site</a>","field8":"805-526-9323","field9":"Approved"},
{"field1":"27","field2":"Calleguas Municipal Water District","field3":"NULL","field4":"91304","field5":"NULL","field6":"805-526-9323","field7":"<a href=\"http://www.calleguas.com\" target='_blank' class=''>Go to Site</a>","field8":"805-526-9323","field9":"Approved"},
{"field1":"29","field2":"Calleguas Municipal Water District","field3":"NULL","field4":"91307","field5":"NULL","field6":"805-526-9323","field7":"<a href=\"http://www.calleguas.com\" target='_blank' class=''>Go to Site</a>","field8":"805-526-9323","field9":"Approved"},
{"field1":"31","field2":"Calleguas Municipal Water District","field3":"NULL","field4":"91311","field5":"NULL","field6":"805-526-9323","field7":"<a href=\"http://www.calleguas.com\" target='_blank' class=''>Go to Site</a>","field8":"805-526-9323","field9":"Approved"},
{"field1":"34","field2":"City of Thousand Oaks","field3":"City of Thousand Oaks","field4":"91320","field5":"NULL","field6":"888-237-1333","field7":"<a href=\"http://www.ci.thousand-oaks.ca.us/city_hall/default.asp\" target='_blank' class=''>Go to Site</a>","field8":"888-237-1333","field9":"Approved"},
{"field1":"36","field2":"City of Thousand Oaks","field3":"City of Thousand Oaks","field4":"91360","field5":"NULL","field6":"888-237-1333","field7":"<a href=\"http://www.ci.thousand-oaks.ca.us/city_hall/default.asp\" target='_blank' class=''>Go to Site</a>","field8":"888-237-1333","field9":"Approved"},
{"field1":"37","field2":"City of Thousand Oaks","field3":"City of Thousand Oaks","field4":"91361","field5":"NULL","field6":"888-237-1333","field7":"<a href=\"http://www.ci.thousand-oaks.ca.us/city_hall/default.asp\" target='_blank' class=''>Go to Site</a>","field8":"888-237-1333","field9":"Approved"},
{"field1":"39","field2":"Calleguas Municipal Water District","field3":"NULL","field4":"91367","field5":"NULL","field6":"805-526-9323","field7":"<a href=\"http://www.calleguas.com\" target='_blank' class=''>Go to Site</a>","field8":"805-526-9323","field9":"Approved"},
{"field1":"40","field2":"Calleguas Municipal Water District","field3":"NULL","field4":"91377","field5":"NULL","field6":"805-526-9323","field7":"<a href=\"http://www.calleguas.com\" target='_blank' class=''>Go to Site</a>","field8":"805-526-9323","field9":"Approved"},
{"field1":"41","field2":"Calleguas Municipal Water District","field3":"NULL","field4":"91382","field5":"NULL","field6":"805-526-9323","field7":"<a href=\"http://www.calleguas.com\" target='_blank' class=''>Go to Site</a>","field8":"805-526-9323","field9":"Approved"},
{"field1":"42","field2":"Calleguas Municipal Water District","field3":"NULL","field4":"93001","field5":"NULL","field6":"805-526-9323","field7":"<a href=\"http://www.calleguas.com\" target='_blank' class=''>Go to Site</a>","field8":"805-526-9323","field9":"Approved"},
{"field1":"43","field2":"City of Camarillo","field3":"City of Camarillo","field4":"93010","field5":"NULL","field6":"805-388-5373","field7":"<a href=\"http://www.ci.camarillo.ca.us/\" target='_blank' class=''>Go to Site</a>","field8":"805-388-5373","field9":"Approved"},
{"field1":"44","field2":"Crestview Mutual Water Company","field3":"Crestview Mutual Water Company","field4":"93010","field5":"NULL","field6":"805-482-2001","field7":"/nowebsitezip.html","field8":"805-482-2001","field9":"Approved"},
{"field1":"45","field2":"Cal American Water Company","field3":"Cal American Water","field4":"93010","field5":"NULL","field6":"888-237-1333","field7":"<a href=\"http://www.calamwater.com/awpr1/caaw/default.html\" target='_blank' class=''>Go to Site</a>","field8":"888-237-1333","field9":"Approved"},
{"field1":"46","field2":"Camrosa Water District","field3":"Camrosa Water District","field4":"93010","field5":"NULL","field6":"805-388-0206","field7":"<a href=\"http://camrosa.com\" target='_blank' class=''>Go to Site</a>","field8":"805-388-0206","field9":"Approved"},
{"field1":"47","field2":"Camrosa Water District","field3":"Camrosa Water District","field4":"93011","field5":"NULL","field6":"805-388-0206","field7":"<a href=\"http://camrosa.com\" target='_blank' class=''>Go to Site</a>","field8":"805-388-0206","field9":"Approved"},
{"field1":"48","field2":"City of Camarillo","field3":"City of Camarillo","field4":"93011","field5":"NULL","field6":"805-388-5373","field7":"<a href=\"http://www.ci.camarillo.ca.us/\" target='_blank' class=''>Go to Site</a>","field8":"805-388-5373","field9":"Approved"},
{"field1":"49","field2":"Camrosa Water District","field3":"Camrosa Water District","field4":"93012","field5":"NULL","field6":"805-388-0206","field7":"<a href=\"http://camrosa.com\" target='_blank' class=''>Go to Site</a>","field8":"805-388-0206","field9":"Approved"},
{"field1":"50","field2":"City of Camarillo","field3":"City of Camarillo","field4":"93012","field5":"NULL","field6":"805-388-5373","field7":"<a href=\"http://www.ci.camarillo.ca.us/\" target='_blank' class=''>Go to Site</a>","field8":"805-388-5373","field9":"Approved"},
{"field1":"51","field2":"Ventura County Water Works","field3":"Ventura County Water Works","field4":"93020","field5":"NULL","field6":"805-583-6736","field7":"<a href=\"http://portal.countyofventura.org/\" target='_blank' class=''>Go to Site</a>","field8":"805-583-6736","field9":"Approved"},
{"field1":"52","field2":"Ventura County Water Works","field3":"Ventura County Water Works","field4":"93021","field5":"NULL","field6":"805-583-6736","field7":"<a href=\"http://portal.countyofventura.org/\" target='_blank' class=''>Go to Site</a>","field8":"805-583-6736","field9":"Approved"},
{"field1":"53","field2":"Calleguas Municipal Water District","field3":"NULL","field4":"93030","field5":"NULL","field6":"805-526-9323","field7":"<a href=\"http://www.calleguas.com\" target='_blank' class=''>Go to Site</a>","field8":"805-526-9323","field9":"Approved"},
{"field1":"54","field2":"Calleguas Municipal Water District","field3":"NULL","field4":"93033","field5":"NULL","field6":"805-526-9323","field7":"<a href=\"http://www.calleguas.com\" target='_blank' class=''>Go to Site</a>","field8":"805-526-9323","field9":"Approved"},
{"field1":"55","field2":"City of Port Hueneme","field3":"City of Port Hueneme","field4":"93035","field5":"NULL","field6":"805-986-6563","field7":"<a href=\"http://www.ci.port-hueneme.ca.us/\" target='_blank' class=''>Go to Site</a>","field8":"805-986-6563","field9":"Approved"},
{"field1":"56","field2":"Channel Islands Beach Community Service District","field3":"NULL","field4":"93035","field5":"NULL","field6":"805-985-6021","field7":"<a href=\"http://www.channelislandsbeachcsd.com\" target='_blank' class=''>Go to Site</a>","field8":"805-985-6021","field9":"Approved"},
{"field1":"57","field2":"Calleguas Municipal Water District","field3":"NULL","field4":"93036","field5":"NULL","field6":"805-526-9323","field7":"<a href=\"http://www.calleguas.com\" target='_blank' class=''>Go to Site</a>","field8":"805-526-9323","field9":"Approved"},
{"field1":"58","field2":"Calleguas Municipal Water District","field3":"NULL","field4":"93041","field5":"NULL","field6":"805-526-9323","field7":"<a href=\"http://www.calleguas.com\" target='_blank' class=''>Go to Site</a>","field8":"805-526-9323","field9":"Approved"},
{"field1":"59","field2":"City of Port Hueneme","field3":"City of Port Hueneme","field4":"93041","field5":"NULL","field6":"805-986-6563","field7":"<a href=\"http://www.ci.port-hueneme.ca.us/\" target='_blank' class=''>Go to Site</a>","field8":"805-986-6563","field9":"Approved"},
{"field1":"60","field2":"City of Port Hueneme","field3":"City of Port Hueneme","field4":"93042","field5":"NULL","field6":"805-986-6563","field7":"<a href=\"http://www.ci.port-hueneme.ca.us/\" target='_blank' class=''>Go to Site</a>","field8":"805-986-6563","field9":"Approved"},
{"field1":"61","field2":"Calleguas Municipal Water District","field3":"NULL","field4":"93043","field5":"NULL","field6":"805-526-9323","field7":"<a href=\"http://www.calleguas.com\" target='_blank' class=''>Go to Site</a>","field8":"805-526-9323","field9":"Approved"},
{"field1":"62","field2":"City of Simi Valley","field3":"City of Simi Valley","field4":"93063","field5":"NULL","field6":"805-583-6400","field7":"<a href=\"http://www.ci.simi-valley.ca.us/\" target='_blank' class=''>Go to Site</a>","field8":"805-583-6400","field9":"Approved"},
{"field1":"63","field2":"Ventura County Water Works","field3":"Ventura County Water Works","field4":"93063","field5":"NULL","field6":"805-583-6736","field7":"<a href=\"http://portal.countyofventura.org/\" target='_blank' class=''>Go to Site</a>","field8":"805-583-6736","field9":"Approved"},
{"field1":"64","field2":"Calleguas Municipal Water District","field3":"NULL","field4":"93064","field5":"NULL","field6":"805-526-9323","field7":"<a href=\"http://www.calleguas.com\" target='_blank' class=''>Go to Site</a>","field8":"805-526-9323","field9":"Approved"},
{"field1":"65","field2":"City of Simi Valley","field3":"City of Simi Valley","field4":"93065","field5":"NULL","field6":"805-583-6400","field7":"<a href=\"http://www.ci.simi-valley.ca.us/\" target='_blank' class=''>Go to Site</a>","field8":"805-583-6400","field9":"Approved"},
{"field1":"66","field2":"Ventura County Water Works","field3":"Ventura County Water Works","field4":"93065","field5":"NULL","field6":"805-583-6736","field7":"<a href=\"http://portal.countyofventura.org/\" target='_blank' class=''>Go to Site</a>","field8":"805-583-6736","field9":"Approved"},
{"field1":"67","field2":"Ventura County Water Works","field3":"Ventura County Water Works","field4":"93066","field5":"NULL","field6":"805-583-6736","field7":"<a href=\"http://portal.countyofventura.org/\" target='_blank' class=''>Go to Site</a>","field8":"805-583-6736","field9":"Approved"},
{"field1":"68","field2":"Central Basin Municipal Water District","field3":"NULL","field4":"90803","field5":"NULL","field6":"310-217-2222","field7":"<a href=\"http://www.centralbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2222","field9":"Approved"},
{"field1":"858","field2":"Central Basin Municipal Water District","field3":"NULL","field4":"91754","field5":"NULL","field6":"310-217-2222","field7":"<a href=\"http://www.centralbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2222","field9":"Approved"},
{"field1":"70","field2":"Central Basin Municipal Water District","field3":"NULL","field4":"90746","field5":"NULL","field6":"310-217-2222","field7":"<a href=\"http://www.centralbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2222","field9":"Approved"},
{"field1":"71","field2":"Central Basin Municipal Water District","field3":"NULL","field4":"90023","field5":"NULL","field6":"310-217-2222","field7":"<a href=\"http://www.centralbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2222","field9":"Approved"},
{"field1":"72","field2":"Central Basin Municipal Water District","field3":"NULL","field4":"90806","field5":"NULL","field6":"310-217-2222","field7":"<a href=\"http://www.centralbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2222","field9":"Approved"},
{"field1":"73","field2":"Central Basin Municipal Water District","field3":"NULL","field4":"90040","field5":"NULL","field6":"310-217-2222","field7":"<a href=\"http://www.centralbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2222","field9":"Approved"},
{"field1":"74","field2":"Central Basin Municipal Water District","field3":"NULL","field4":"90255","field5":"NULL","field6":"310-217-2222","field7":"<a href=\"http://www.centralbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2222","field9":"Approved"},
{"field1":"75","field2":"Central Basin Municipal Water District","field3":"NULL","field4":"90712","field5":"NULL","field6":"310-217-2222","field7":"<a href=\"http://www.centralbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2222","field9":"Approved"},
{"field1":"76","field2":"Central Basin Municipal Water District","field3":"NULL","field4":"90002","field5":"NULL","field6":"310-217-2222","field7":"<a href=\"http://www.centralbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2222","field9":"Approved"},
{"field1":"77","field2":"Central Basin Municipal Water District","field3":"NULL","field4":"90001","field5":"NULL","field6":"310-217-2222","field7":"<a href=\"http://www.centralbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2222","field9":"Approved"},
{"field1":"78","field2":"Central Basin Municipal Water District","field3":"NULL","field4":"90058","field5":"NULL","field6":"310-217-2222","field7":"<a href=\"http://www.centralbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2222","field9":"Approved"},
{"field1":"79","field2":"Central Basin Municipal Water District","field3":"NULL","field4":"90631","field5":"NULL","field6":"310-217-2222","field7":"<a href=\"http://www.centralbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2222","field9":"Approved"},
{"field1":"80","field2":"Central Basin Municipal Water District","field3":"NULL","field4":"90222","field5":"NULL","field6":"310-217-2222","field7":"<a href=\"http://www.centralbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2222","field9":"Approved"},
{"field1":"81","field2":"Central Basin Municipal Water District","field3":"NULL","field4":"90201","field5":"NULL","field6":"310-217-2222","field7":"<a href=\"http://www.centralbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2222","field9":"Approved"},
{"field1":"82","field2":"Central Basin Municipal Water District","field3":"NULL","field4":"90601","field5":"NULL","field6":"310-217-2222","field7":"<a href=\"http://www.centralbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2222","field9":"Approved"},
{"field1":"83","field2":"Central Basin Municipal Water District","field3":"NULL","field4":"90059","field5":"NULL","field6":"310-217-2222","field7":"<a href=\"http://www.centralbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2222","field9":"Approved"},
{"field1":"84","field2":"Central Basin Municipal Water District","field3":"NULL","field4":"90270","field5":"NULL","field6":"310-217-2222","field7":"<a href=\"http://www.centralbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2222","field9":"Approved"},
{"field1":"85","field2":"Central Basin Municipal Water District","field3":"NULL","field4":"90061","field5":"NULL","field6":"310-217-2222","field7":"<a href=\"http://www.centralbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2222","field9":"Approved"},
{"field1":"86","field2":"Central Basin Municipal Water District","field3":"NULL","field4":"90280","field5":"NULL","field6":"310-217-2222","field7":"<a href=\"http://www.centralbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2222","field9":"Approved"},
{"field1":"87","field2":"Central Basin Municipal Water District","field3":"NULL","field4":"90063","field5":"NULL","field6":"310-217-2222","field7":"<a href=\"http://www.centralbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2222","field9":"Approved"},
{"field1":"88","field2":"Central Basin Municipal Water District","field3":"NULL","field4":"90640","field5":"NULL","field6":"310-217-2222","field7":"<a href=\"http://www.centralbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2222","field9":"Approved"},
{"field1":"89","field2":"Central Basin Municipal Water District","field3":"NULL","field4":"90716","field5":"NULL","field6":"310-217-2222","field7":"<a href=\"http://www.centralbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2222","field9":"Approved"},
{"field1":"90","field2":"Central Basin Municipal Water District","field3":"NULL","field4":"90723","field5":"NULL","field6":"310-217-2222","field7":"<a href=\"http://www.centralbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2222","field9":"Approved"},
{"field1":"91","field2":"Central Basin Municipal Water District","field3":"NULL","field4":"90660","field5":"NULL","field6":"310-217-2222","field7":"<a href=\"http://www.centralbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2222","field9":"Approved"},
{"field1":"92","field2":"Central Basin Municipal Water District","field3":"NULL","field4":"90638","field5":"NULL","field6":"310-217-2222","field7":"<a href=\"http://www.centralbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2222","field9":"Approved"},
{"field1":"93","field2":"Central Basin Municipal Water District","field3":"NULL","field4":"90602","field5":"NULL","field6":"310-217-2222","field7":"<a href=\"http://www.centralbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2222","field9":"Approved"},
{"field1":"94","field2":"Central Basin Municipal Water District","field3":"NULL","field4":"90262","field5":"NULL","field6":"310-217-2222","field7":"<a href=\"http://www.centralbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2222","field9":"Approved"},
{"field1":"95","field2":"Central Basin Municipal Water District","field3":"NULL","field4":"90603","field5":"NULL","field6":"310-217-2222","field7":"<a href=\"http://www.centralbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2222","field9":"Approved"},
{"field1":"96","field2":"Central Basin Municipal Water District","field3":"NULL","field4":"90604","field5":"NULL","field6":"310-217-2222","field7":"<a href=\"http://www.centralbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2222","field9":"Approved"},
{"field1":"97","field2":"Central Basin Municipal Water District","field3":"NULL","field4":"90703","field5":"NULL","field6":"310-217-2222","field7":"<a href=\"http://www.centralbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2222","field9":"Approved"},
{"field1":"98","field2":"Central Basin Municipal Water District","field3":"NULL","field4":"90715","field5":"NULL","field6":"310-217-2222","field7":"<a href=\"http://www.centralbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2222","field9":"Approved"},
{"field1":"99","field2":"Central Basin Municipal Water District","field3":"NULL","field4":"90713","field5":"NULL","field6":"310-217-2222","field7":"<a href=\"http://www.centralbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2222","field9":"Approved"},
{"field1":"100","field2":"Central Basin Municipal Water District","field3":"NULL","field4":"90706","field5":"NULL","field6":"310-217-2222","field7":"<a href=\"http://www.centralbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2222","field9":"Approved"},
{"field1":"101","field2":"Central Basin Municipal Water District","field3":"NULL","field4":"90022","field5":"NULL","field6":"310-217-2222","field7":"<a href=\"http://www.centralbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2222","field9":"Approved"},
{"field1":"102","field2":"Central Basin Municipal Water District","field3":"NULL","field4":"90240","field5":"NULL","field6":"310-217-2222","field7":"<a href=\"http://www.centralbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2222","field9":"Approved"},
{"field1":"103","field2":"Central Basin Municipal Water District","field3":"NULL","field4":"90241","field5":"NULL","field6":"310-217-2222","field7":"<a href=\"http://www.centralbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2222","field9":"Approved"},
{"field1":"104","field2":"Central Basin Municipal Water District","field3":"NULL","field4":"90242","field5":"NULL","field6":"310-217-2222","field7":"<a href=\"http://www.centralbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2222","field9":"Approved"},
{"field1":"105","field2":"Central Basin Municipal Water District","field3":"NULL","field4":"90605","field5":"NULL","field6":"310-217-2222","field7":"<a href=\"http://www.centralbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2222","field9":"Approved"},
{"field1":"106","field2":"Central Basin Municipal Water District","field3":"NULL","field4":"90606","field5":"NULL","field6":"310-217-2222","field7":"<a href=\"http://www.centralbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2222","field9":"Approved"},
{"field1":"107","field2":"Central Basin Municipal Water District","field3":"NULL","field4":"90650","field5":"NULL","field6":"310-217-2222","field7":"<a href=\"http://www.centralbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2222","field9":"Approved"},
{"field1":"108","field2":"Central Basin Municipal Water District","field3":"NULL","field4":"90670","field5":"NULL","field6":"310-217-2222","field7":"<a href=\"http://www.centralbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2222","field9":"Approved"},
{"field1":"109","field2":"Central Basin Municipal Water District","field3":"NULL","field4":"90701","field5":"NULL","field6":"310-217-2222","field7":"<a href=\"http://www.centralbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2222","field9":"Approved"},
{"field1":"110","field2":"City of Compton","field3":"City of Compton","field4":"90220","field5":"NULL","field6":"310-605-5595","field7":"<a href=\"http://www.comptoncity.org/\" target='_blank' class=''>Go to Site</a>","field8":"310-605-5595","field9":"Approved"},
{"field1":"111","field2":"City of Compton","field3":"City of Compton","field4":"90222","field5":"NULL","field6":"310-605-5595","field7":"<a href=\"http://www.comptoncity.org/\" target='_blank' class=''>Go to Site</a>","field8":"310-605-5595","field9":"Approved"},
{"field1":"112","field2":"City of Compton","field3":"City of Compton","field4":"90224","field5":"NULL","field6":"310-605-5595","field7":"<a href=\"http://www.comptoncity.org/\" target='_blank' class=''>Go to Site</a>","field8":"310-605-5595","field9":"Approved"},
{"field1":"113","field2":"City of Compton","field3":"City of Compton","field4":"90223","field5":"NULL","field6":"310-605-5595","field7":"<a href=\"http://www.comptoncity.org/\" target='_blank' class=''>Go to Site</a>","field8":"310-605-5595","field9":"Approved"},
{"field1":"114","field2":"City of Compton","field3":"City of Compton","field4":"90221","field5":"NULL","field6":"310-605-5595","field7":"<a href=\"http://www.comptoncity.org/\" target='_blank' class=''>Go to Site</a>","field8":"310-605-5595","field9":"Approved"},
{"field1":"115","field2":"Eastern Municipal Water District","field3":"NULL","field4":"92549","field5":"NULL","field6":"951-928-3777 x4517","field7":"<a href=\"http://www.emwd.org\" target='_blank' class=''>Go to Site</a>","field8":"951-928-3777 x4517","field9":"Approved"},
{"field1":"116","field2":"Eastern Municipal Water District","field3":"NULL","field4":"92220","field5":"NULL","field6":"951-928-3777 x4517","field7":"<a href=\"http://www.emwd.org\" target='_blank' class=''>Go to Site</a>","field8":"951-928-3777 x4517","field9":"Approved"},
{"field1":"117","field2":"Eastern Municipal Water District","field3":"NULL","field4":"92223","field5":"NULL","field6":"951-928-3777 x4517","field7":"<a href=\"http://www.emwd.org\" target='_blank' class=''>Go to Site</a>","field8":"951-928-3777 x4517","field9":"Approved"},
{"field1":"118","field2":"Eastern Municipal Water District","field3":"NULL","field4":"92324","field5":"NULL","field6":"951-928-3777 x4517","field7":"<a href=\"http://www.emwd.org\" target='_blank' class=''>Go to Site</a>","field8":"951-928-3777 x4517","field9":"Approved"},
{"field1":"119","field2":"Eastern Municipal Water District","field3":"NULL","field4":"92373","field5":"NULL","field6":"951-928-3777 x4517","field7":"<a href=\"http://www.emwd.org\" target='_blank' class=''>Go to Site</a>","field8":"951-928-3777 x4517","field9":"Approved"},
{"field1":"120","field2":"Eastern Municipal Water District","field3":"NULL","field4":"92507","field5":"NULL","field6":"951-928-3777 x4517","field7":"<a href=\"http://www.emwd.org\" target='_blank' class=''>Go to Site</a>","field8":"951-928-3777 x4517","field9":"Approved"},
{"field1":"121","field2":"Eastern Municipal Water District","field3":"NULL","field4":"92508","field5":"NULL","field6":"951-928-3777 x4517","field7":"<a href=\"http://www.emwd.org\" target='_blank' class=''>Go to Site</a>","field8":"951-928-3777 x4517","field9":"Approved"},
{"field1":"122","field2":"Eastern Municipal Water District","field3":"NULL","field4":"92518","field5":"NULL","field6":"951-928-3777 x4517","field7":"<a href=\"http://www.emwd.org\" target='_blank' class=''>Go to Site</a>","field8":"951-928-3777 x4517","field9":"Approved"},
{"field1":"123","field2":"Eastern Municipal Water District","field3":"NULL","field4":"92532","field5":"NULL","field6":"951-928-3777 x4517","field7":"<a href=\"http://www.emwd.org\" target='_blank' class=''>Go to Site</a>","field8":"951-928-3777 x4517","field9":"Approved"},
{"field1":"124","field2":"Eastern Municipal Water District","field3":"NULL","field4":"92536","field5":"NULL","field6":"951-928-3777 x4517","field7":"<a href=\"http://www.emwd.org\" target='_blank' class=''>Go to Site</a>","field8":"951-928-3777 x4517","field9":"Approved"},
{"field1":"125","field2":"Eastern Municipal Water District","field3":"NULL","field4":"92539","field5":"NULL","field6":"951-928-3777 x4517","field7":"<a href=\"http://www.emwd.org\" target='_blank' class=''>Go to Site</a>","field8":"951-928-3777 x4517","field9":"Approved"},
{"field1":"126","field2":"Eastern Municipal Water District","field3":"NULL","field4":"92543","field5":"NULL","field6":"951-928-3777 x4517","field7":"<a href=\"http://www.emwd.org\" target='_blank' class=''>Go to Site</a>","field8":"951-928-3777 x4517","field9":"Approved"},
{"field1":"127","field2":"Eastern Municipal Water District","field3":"NULL","field4":"92544","field5":"NULL","field6":"951-928-3777 x4517","field7":"<a href=\"http://www.emwd.org\" target='_blank' class=''>Go to Site</a>","field8":"951-928-3777 x4517","field9":"Approved"},
{"field1":"128","field2":"Eastern Municipal Water District","field3":"NULL","field4":"92545","field5":"NULL","field6":"951-928-3777 x4517","field7":"<a href=\"http://www.emwd.org\" target='_blank' class=''>Go to Site</a>","field8":"951-928-3777 x4517","field9":"Approved"},
{"field1":"129","field2":"Eastern Municipal Water District","field3":"NULL","field4":"92548","field5":"NULL","field6":"951-928-3777 x4517","field7":"<a href=\"http://www.emwd.org\" target='_blank' class=''>Go to Site</a>","field8":"951-928-3777 x4517","field9":"Approved"},
{"field1":"130","field2":"Eastern Municipal Water District","field3":"NULL","field4":"92551","field5":"NULL","field6":"951-928-3777 x4517","field7":"<a href=\"http://www.emwd.org\" target='_blank' class=''>Go to Site</a>","field8":"951-928-3777 x4517","field9":"Approved"},
{"field1":"131","field2":"Eastern Municipal Water District","field3":"NULL","field4":"92553","field5":"NULL","field6":"951-928-3777 x4517","field7":"<a href=\"http://www.emwd.org\" target='_blank' class=''>Go to Site</a>","field8":"951-928-3777 x4517","field9":"Approved"},
{"field1":"132","field2":"Eastern Municipal Water District","field3":"NULL","field4":"92555","field5":"NULL","field6":"951-928-3777 x4517","field7":"<a href=\"http://www.emwd.org\" target='_blank' class=''>Go to Site</a>","field8":"951-928-3777 x4517","field9":"Approved"},
{"field1":"133","field2":"Eastern Municipal Water District","field3":"NULL","field4":"92557","field5":"NULL","field6":"951-928-3777 x4517","field7":"<a href=\"http://www.emwd.org\" target='_blank' class=''>Go to Site</a>","field8":"951-928-3777 x4517","field9":"Approved"},
{"field1":"134","field2":"Eastern Municipal Water District","field3":"NULL","field4":"92562","field5":"NULL","field6":"951-928-3777 x4517","field7":"<a href=\"http://www.emwd.org\" target='_blank' class=''>Go to Site</a>","field8":"951-928-3777 x4517","field9":"Approved"},
{"field1":"135","field2":"Eastern Municipal Water District","field3":"NULL","field4":"92563","field5":"NULL","field6":"951-928-3777 x4517","field7":"<a href=\"http://www.emwd.org\" target='_blank' class=''>Go to Site</a>","field8":"951-928-3777 x4517","field9":"Approved"},
{"field1":"136","field2":"Eastern Municipal Water District","field3":"NULL","field4":"92567","field5":"NULL","field6":"951-928-3777 x4517","field7":"<a href=\"http://www.emwd.org\" target='_blank' class=''>Go to Site</a>","field8":"951-928-3777 x4517","field9":"Approved"},
{"field1":"137","field2":"Eastern Municipal Water District","field3":"NULL","field4":"92570","field5":"NULL","field6":"951-928-3777 x4517","field7":"<a href=\"http://www.emwd.org\" target='_blank' class=''>Go to Site</a>","field8":"951-928-3777 x4517","field9":"Approved"},
{"field1":"138","field2":"Eastern Municipal Water District","field3":"NULL","field4":"92571","field5":"NULL","field6":"951-928-3777 x4517","field7":"<a href=\"http://www.emwd.org\" target='_blank' class=''>Go to Site</a>","field8":"951-928-3777 x4517","field9":"Approved"},
{"field1":"139","field2":"Eastern Municipal Water District","field3":"NULL","field4":"92582","field5":"NULL","field6":"951-928-3777 x4517","field7":"<a href=\"http://www.emwd.org\" target='_blank' class=''>Go to Site</a>","field8":"951-928-3777 x4517","field9":"Approved"},
{"field1":"140","field2":"Eastern Municipal Water District","field3":"NULL","field4":"92583","field5":"NULL","field6":"951-928-3777 x4517","field7":"<a href=\"http://www.emwd.org\" target='_blank' class=''>Go to Site</a>","field8":"951-928-3777 x4517","field9":"Approved"},
{"field1":"141","field2":"Eastern Municipal Water District","field3":"NULL","field4":"92584","field5":"NULL","field6":"951-928-3777 x4517","field7":"<a href=\"http://www.emwd.org\" target='_blank' class=''>Go to Site</a>","field8":"951-928-3777 x4517","field9":"Approved"},
{"field1":"142","field2":"Eastern Municipal Water District","field3":"NULL","field4":"92585","field5":"NULL","field6":"951-928-3777 x4517","field7":"<a href=\"http://www.emwd.org\" target='_blank' class=''>Go to Site</a>","field8":"951-928-3777 x4517","field9":"Approved"},
{"field1":"143","field2":"Eastern Municipal Water District","field3":"NULL","field4":"92586","field5":"NULL","field6":"951-928-3777 x4517","field7":"<a href=\"http://www.emwd.org\" target='_blank' class=''>Go to Site</a>","field8":"951-928-3777 x4517","field9":"Approved"},
{"field1":"144","field2":"Eastern Municipal Water District","field3":"NULL","field4":"92587","field5":"NULL","field6":"951-928-3777 x4517","field7":"<a href=\"http://www.emwd.org\" target='_blank' class=''>Go to Site</a>","field8":"951-928-3777 x4517","field9":"Approved"},
{"field1":"145","field2":"Eastern Municipal Water District","field3":"NULL","field4":"92590","field5":"NULL","field6":"951-928-3777 x4517","field7":"<a href=\"http://www.emwd.org\" target='_blank' class=''>Go to Site</a>","field8":"951-928-3777 x4517","field9":"Approved"},
{"field1":"146","field2":"Eastern Municipal Water District","field3":"NULL","field4":"92591","field5":"NULL","field6":"951-928-3777 x4517","field7":"<a href=\"http://www.emwd.org\" target='_blank' class=''>Go to Site</a>","field8":"951-928-3777 x4517","field9":"Approved"},
{"field1":"147","field2":"Eastern Municipal Water District","field3":"NULL","field4":"92592","field5":"NULL","field6":"951-928-3777 x4517","field7":"<a href=\"http://www.emwd.org\" target='_blank' class=''>Go to Site</a>","field8":"951-928-3777 x4517","field9":"Approved"},
{"field1":"148","field2":"Eastern Municipal Water District","field3":"NULL","field4":"92595","field5":"NULL","field6":"951-928-3777 x4517","field7":"<a href=\"http://www.emwd.org\" target='_blank' class=''>Go to Site</a>","field8":"951-928-3777 x4517","field9":"Approved"},
{"field1":"149","field2":"Eastern Municipal Water District","field3":"NULL","field4":"92596","field5":"NULL","field6":"951-928-3777 x4517","field7":"<a href=\"http://www.emwd.org\" target='_blank' class=''>Go to Site</a>","field8":"951-928-3777 x4517","field9":"Approved"},
{"field1":"150","field2":"Foothill Municipal Water District","field3":"NULL","field4":"91020","field5":"NULL","field6":"818-790-4036","field7":"<a href=\"http://www.fmwd.com/Default.aspx\" target='_blank' class=''>Go to Site</a>","field8":"818-790-4036","field9":"Approved"},
{"field1":"151","field2":"Foothill Municipal Water District","field3":"NULL","field4":"91001","field5":"NULL","field6":"818-790-4036","field7":"<a href=\"http://www.fmwd.com/Default.aspx\" target='_blank' class=''>Go to Site</a>","field8":"818-790-4036","field9":"Approved"},
{"field1":"152","field2":"Foothill Municipal Water District","field3":"NULL","field4":"91103","field5":"NULL","field6":"818-790-4036","field7":"<a href=\"http://www.fmwd.com/Default.aspx\" target='_blank' class=''>Go to Site</a>","field8":"818-790-4036","field9":"Approved"},
{"field1":"153","field2":"Foothill Municipal Water District","field3":"NULL","field4":"91024","field5":"NULL","field6":"818-790-4036","field7":"<a href=\"http://www.fmwd.com/Default.aspx\" target='_blank' class=''>Go to Site</a>","field8":"818-790-4036","field9":"Approved"},
{"field1":"154","field2":"Foothill Municipal Water District","field3":"NULL","field4":"91214","field5":"NULL","field6":"818-790-4036","field7":"<a href=\"http://www.fmwd.com/Default.aspx\" target='_blank' class=''>Go to Site</a>","field8":"818-790-4036","field9":"Approved"},
{"field1":"155","field2":"Foothill Municipal Water District","field3":"NULL","field4":"91011","field5":"NULL","field6":"818-790-4036","field7":"<a href=\"http://www.fmwd.com/Default.aspx\" target='_blank' class=''>Go to Site</a>","field8":"818-790-4036","field9":"Approved"},
{"field1":"156","field2":"Foothill Municipal Water District","field3":"NULL","field4":"91208","field5":"NULL","field6":"818-790-4036","field7":"<a href=\"http://www.fmwd.com/Default.aspx\" target='_blank' class=''>Go to Site</a>","field8":"818-790-4036","field9":"Approved"},
{"field1":"157","field2":"Foothill Municipal Water District","field3":"NULL","field4":"91206","field5":"NULL","field6":"818-790-4036","field7":"<a href=\"http://www.fmwd.com/Default.aspx\" target='_blank' class=''>Go to Site</a>","field8":"818-790-4036","field9":"Approved"},
{"field1":"158","field2":"Foothill Municipal Water District","field3":"NULL","field4":"91107","field5":"NULL","field6":"818-790-4036","field7":"<a href=\"http://www.fmwd.com/Default.aspx\" target='_blank' class=''>Go to Site</a>","field8":"818-790-4036","field9":"Approved"},
{"field1":"159","field2":"Foothill Municipal Water District","field3":"NULL","field4":"91104","field5":"NULL","field6":"818-790-4036","field7":"<a href=\"http://www.fmwd.com/Default.aspx\" target='_blank' class=''>Go to Site</a>","field8":"818-790-4036","field9":"Approved"},
{"field1":"160","field2":"Foothill Municipal Water District","field3":"NULL","field4":"91046","field5":"NULL","field6":"818-790-4036","field7":"<a href=\"http://www.fmwd.com/Default.aspx\" target='_blank' class=''>Go to Site</a>","field8":"818-790-4036","field9":"Approved"},
{"field1":"161","field2":"City of Fullerton","field3":"City of Fullerton","field4":"92831","field5":"NULL","field6":"714-738-6895","field7":"<a href=\"http://www.ci.fullerton.ca.us\" target='_blank' class=''>Go to Site</a>","field8":"714-738-6895","field9":"Approved"},
{"field1":"162","field2":"City of Fullerton","field3":"City of Fullerton","field4":"92832","field5":"NULL","field6":"714-738-6895","field7":"<a href=\"http://www.ci.fullerton.ca.us\" target='_blank' class=''>Go to Site</a>","field8":"714-738-6895","field9":"Approved"},
{"field1":"163","field2":"City of Fullerton","field3":"City of Fullerton","field4":"92833","field5":"NULL","field6":"714-738-6895","field7":"<a href=\"http://www.ci.fullerton.ca.us\" target='_blank' class=''>Go to Site</a>","field8":"714-738-6895","field9":"Approved"},
{"field1":"164","field2":"City of Fullerton","field3":"City of Fullerton","field4":"92834","field5":"NULL","field6":"714-738-6895","field7":"<a href=\"http://www.ci.fullerton.ca.us\" target='_blank' class=''>Go to Site</a>","field8":"714-738-6895","field9":"Approved"},
{"field1":"165","field2":"City of Fullerton","field3":"City of Fullerton","field4":"92835","field5":"NULL","field6":"714-738-6895","field7":"<a href=\"http://www.ci.fullerton.ca.us\" target='_blank' class=''>Go to Site</a>","field8":"714-738-6895","field9":"Approved"},
{"field1":"166","field2":"City of Fullerton","field3":"City of Fullerton","field4":"92836","field5":"NULL","field6":"714-738-6895","field7":"<a href=\"http://www.ci.fullerton.ca.us\" target='_blank' class=''>Go to Site</a>","field8":"714-738-6895","field9":"Approved"},
{"field1":"167","field2":"City of Fullerton","field3":"City of Fullerton","field4":"92837","field5":"NULL","field6":"714-738-6895","field7":"<a href=\"http://www.ci.fullerton.ca.us\" target='_blank' class=''>Go to Site</a>","field8":"714-738-6895","field9":"Approved"},
{"field1":"168","field2":"City of Fullerton","field3":"City of Fullerton","field4":"92838","field5":"NULL","field6":"714-738-6895","field7":"<a href=\"http://www.ci.fullerton.ca.us\" target='_blank' class=''>Go to Site</a>","field8":"714-738-6895","field9":"Approved"},
{"field1":"169","field2":"City of Glendale","field3":"City of Glendale","field4":"91222","field5":"NULL","field6":"818-548-3300","field7":"<a href=\"http://www.GlendaleWaterandPower.com\" target='_blank' class=''>Go to Site</a>","field8":"818-548-3300","field9":"Approved"},
{"field1":"170","field2":"City of Glendale","field3":"City of Glendale","field4":"91209","field5":"NULL","field6":"818-548-3300","field7":"<a href=\"http://www.GlendaleWaterandPower.com\" target='_blank' class=''>Go to Site</a>","field8":"818-548-3300","field9":"Approved"},
{"field1":"171","field2":"City of Glendale","field3":"City of Glendale","field4":"91046","field5":"NULL","field6":"818-548-3300","field7":"<a href=\"http://www.GlendaleWaterandPower.com\" target='_blank' class=''>Go to Site</a>","field8":"818-548-3300","field9":"Approved"},
{"field1":"172","field2":"City of Glendale","field3":"City of Glendale","field4":"91021","field5":"NULL","field6":"818-548-3300","field7":"<a href=\"http://www.GlendaleWaterandPower.com\" target='_blank' class=''>Go to Site</a>","field8":"818-548-3300","field9":"Approved"},
{"field1":"173","field2":"City of Glendale","field3":"City of Glendale","field4":"91011","field5":"NULL","field6":"818-548-3300","field7":"<a href=\"http://www.GlendaleWaterandPower.com\" target='_blank' class=''>Go to Site</a>","field8":"818-548-3300","field9":"Approved"},
{"field1":"174","field2":"City of Glendale","field3":"City of Glendale","field4":"91020","field5":"NULL","field6":"818-548-3300","field7":"<a href=\"http://www.GlendaleWaterandPower.com\" target='_blank' class=''>Go to Site</a>","field8":"818-548-3300","field9":"Approved"},
{"field1":"175","field2":"City of Glendale","field3":"City of Glendale","field4":"91042","field5":"NULL","field6":"818-548-3300","field7":"<a href=\"http://www.GlendaleWaterandPower.com\" target='_blank' class=''>Go to Site</a>","field8":"818-548-3300","field9":"Approved"},
{"field1":"176","field2":"City of Glendale","field3":"City of Glendale","field4":"91103","field5":"NULL","field6":"818-548-3300","field7":"<a href=\"http://www.GlendaleWaterandPower.com\" target='_blank' class=''>Go to Site</a>","field8":"818-548-3300","field9":"Approved"},
{"field1":"177","field2":"City of Glendale","field3":"City of Glendale","field4":"91105","field5":"NULL","field6":"818-548-3300","field7":"<a href=\"http://www.GlendaleWaterandPower.com\" target='_blank' class=''>Go to Site</a>","field8":"818-548-3300","field9":"Approved"},
{"field1":"178","field2":"City of Glendale","field3":"City of Glendale","field4":"91201","field5":"NULL","field6":"818-548-3300","field7":"<a href=\"http://www.GlendaleWaterandPower.com\" target='_blank' class=''>Go to Site</a>","field8":"818-548-3300","field9":"Approved"},
{"field1":"179","field2":"City of Glendale","field3":"City of Glendale","field4":"91202","field5":"NULL","field6":"818-548-3300","field7":"<a href=\"http://www.GlendaleWaterandPower.com\" target='_blank' class=''>Go to Site</a>","field8":"818-548-3300","field9":"Approved"},
{"field1":"180","field2":"City of Glendale","field3":"City of Glendale","field4":"91203","field5":"NULL","field6":"818-548-3300","field7":"<a href=\"http://www.GlendaleWaterandPower.com\" target='_blank' class=''>Go to Site</a>","field8":"818-548-3300","field9":"Approved"},
{"field1":"181","field2":"City of Glendale","field3":"City of Glendale","field4":"91204","field5":"NULL","field6":"818-548-3300","field7":"<a href=\"http://www.GlendaleWaterandPower.com\" target='_blank' class=''>Go to Site</a>","field8":"818-548-3300","field9":"Approved"},
{"field1":"182","field2":"City of Glendale","field3":"City of Glendale","field4":"91205","field5":"NULL","field6":"818-548-3300","field7":"<a href=\"http://www.GlendaleWaterandPower.com\" target='_blank' class=''>Go to Site</a>","field8":"818-548-3300","field9":"Approved"},
{"field1":"183","field2":"City of Glendale","field3":"City of Glendale","field4":"91206","field5":"NULL","field6":"818-548-3300","field7":"<a href=\"http://www.GlendaleWaterandPower.com\" target='_blank' class=''>Go to Site</a>","field8":"818-548-3300","field9":"Approved"},
{"field1":"184","field2":"City of Glendale","field3":"City of Glendale","field4":"91207","field5":"NULL","field6":"818-548-3300","field7":"<a href=\"http://www.GlendaleWaterandPower.com\" target='_blank' class=''>Go to Site</a>","field8":"818-548-3300","field9":"Approved"},
{"field1":"185","field2":"City of Glendale","field3":"City of Glendale","field4":"91208","field5":"NULL","field6":"818-548-3300","field7":"<a href=\"http://www.GlendaleWaterandPower.com\" target='_blank' class=''>Go to Site</a>","field8":"818-548-3300","field9":"Approved"},
{"field1":"186","field2":"City of Glendale","field3":"City of Glendale","field4":"91210","field5":"NULL","field6":"818-548-3300","field7":"<a href=\"http://www.GlendaleWaterandPower.com\" target='_blank' class=''>Go to Site</a>","field8":"818-548-3300","field9":"Approved"},
{"field1":"187","field2":"City of Glendale","field3":"City of Glendale","field4":"91214","field5":"NULL","field6":"818-548-3300","field7":"<a href=\"http://www.GlendaleWaterandPower.com\" target='_blank' class=''>Go to Site</a>","field8":"818-548-3300","field9":"Approved"},
{"field1":"188","field2":"City of Glendale","field3":"City of Glendale","field4":"91221","field5":"NULL","field6":"818-548-3300","field7":"<a href=\"http://www.GlendaleWaterandPower.com\" target='_blank' class=''>Go to Site</a>","field8":"818-548-3300","field9":"Approved"},
{"field1":"189","field2":"City of Glendale","field3":"City of Glendale","field4":"91224","field5":"NULL","field6":"818-548-3300","field7":"<a href=\"http://www.GlendaleWaterandPower.com\" target='_blank' class=''>Go to Site</a>","field8":"818-548-3300","field9":"Approved"},
{"field1":"190","field2":"City of Glendale","field3":"City of Glendale","field4":"91225","field5":"NULL","field6":"818-548-3300","field7":"<a href=\"http://www.GlendaleWaterandPower.com\" target='_blank' class=''>Go to Site</a>","field8":"818-548-3300","field9":"Approved"},
{"field1":"191","field2":"City of Glendale","field3":"City of Glendale","field4":"91226","field5":"NULL","field6":"818-548-3300","field7":"<a href=\"http://www.GlendaleWaterandPower.com\" target='_blank' class=''>Go to Site</a>","field8":"818-548-3300","field9":"Approved"},
{"field1":"192","field2":"Inland Empire Utilities Agency","field3":"NULL","field4":"91708","field5":"NULL","field6":"909-993-1600","field7":"<a href=\"http://www.ieua.org\" target='_blank' class=''>Go to Site</a>","field8":"909-993-1600","field9":"Approved"},
{"field1":"193","field2":"Inland Empire Utilities Agency","field3":"NULL","field4":"91758","field5":"NULL","field6":"909-993-1600","field7":"<a href=\"http://www.ieua.org\" target='_blank' class=''>Go to Site</a>","field8":"909-993-1600","field9":"Approved"},
{"field1":"194","field2":"Inland Empire Utilities Agency","field3":"NULL","field4":"91701","field5":"NULL","field6":"909-993-1600","field7":"<a href=\"http://www.ieua.org\" target='_blank' class=''>Go to Site</a>","field8":"909-993-1600","field9":"Approved"},
{"field1":"195","field2":"Inland Empire Utilities Agency","field3":"NULL","field4":"91709","field5":"NULL","field6":"909-993-1600","field7":"<a href=\"http://www.ieua.org\" target='_blank' class=''>Go to Site</a>","field8":"909-993-1600","field9":"Approved"},
{"field1":"196","field2":"Inland Empire Utilities Agency","field3":"NULL","field4":"91710","field5":"NULL","field6":"909-993-1600","field7":"<a href=\"http://www.ieua.org\" target='_blank' class=''>Go to Site</a>","field8":"909-993-1600","field9":"Approved"},
{"field1":"197","field2":"Inland Empire Utilities Agency","field3":"NULL","field4":"91730","field5":"NULL","field6":"909-993-1600","field7":"<a href=\"http://www.ieua.org\" target='_blank' class=''>Go to Site</a>","field8":"909-993-1600","field9":"Approved"},
{"field1":"198","field2":"Inland Empire Utilities Agency","field3":"NULL","field4":"91737","field5":"NULL","field6":"909-993-1600","field7":"<a href=\"http://www.ieua.org\" target='_blank' class=''>Go to Site</a>","field8":"909-993-1600","field9":"Approved"},
{"field1":"199","field2":"Inland Empire Utilities Agency","field3":"NULL","field4":"91739","field5":"NULL","field6":"909-993-1600","field7":"<a href=\"http://www.ieua.org\" target='_blank' class=''>Go to Site</a>","field8":"909-993-1600","field9":"Approved"},
{"field1":"200","field2":"Inland Empire Utilities Agency","field3":"NULL","field4":"91761","field5":"NULL","field6":"909-993-1600","field7":"<a href=\"http://www.ieua.org\" target='_blank' class=''>Go to Site</a>","field8":"909-993-1600","field9":"Approved"},
{"field1":"201","field2":"Inland Empire Utilities Agency","field3":"NULL","field4":"91762","field5":"NULL","field6":"909-993-1600","field7":"<a href=\"http://www.ieua.org\" target='_blank' class=''>Go to Site</a>","field8":"909-993-1600","field9":"Approved"},
{"field1":"202","field2":"Inland Empire Utilities Agency","field3":"NULL","field4":"91763","field5":"NULL","field6":"909-993-1600","field7":"<a href=\"http://www.ieua.org\" target='_blank' class=''>Go to Site</a>","field8":"909-993-1600","field9":"Approved"},
{"field1":"203","field2":"Inland Empire Utilities Agency","field3":"NULL","field4":"91764","field5":"NULL","field6":"909-993-1600","field7":"<a href=\"http://www.ieua.org\" target='_blank' class=''>Go to Site</a>","field8":"909-993-1600","field9":"Approved"},
{"field1":"204","field2":"Inland Empire Utilities Agency","field3":"NULL","field4":"91784","field5":"NULL","field6":"909-993-1600","field7":"<a href=\"http://www.ieua.org\" target='_blank' class=''>Go to Site</a>","field8":"909-993-1600","field9":"Approved"},
{"field1":"205","field2":"Inland Empire Utilities Agency","field3":"NULL","field4":"91786","field5":"NULL","field6":"909-993-1600","field7":"<a href=\"http://www.ieua.org\" target='_blank' class=''>Go to Site</a>","field8":"909-993-1600","field9":"Approved"},
{"field1":"206","field2":"Inland Empire Utilities Agency","field3":"NULL","field4":"92316","field5":"NULL","field6":"909-993-1600","field7":"<a href=\"http://www.ieua.org\" target='_blank' class=''>Go to Site</a>","field8":"909-993-1600","field9":"Approved"},
{"field1":"207","field2":"Inland Empire Utilities Agency","field3":"NULL","field4":"92335","field5":"NULL","field6":"909-993-1600","field7":"<a href=\"http://www.ieua.org\" target='_blank' class=''>Go to Site</a>","field8":"909-993-1600","field9":"Approved"},
{"field1":"208","field2":"Inland Empire Utilities Agency","field3":"NULL","field4":"92336","field5":"NULL","field6":"909-993-1600","field7":"<a href=\"http://www.ieua.org\" target='_blank' class=''>Go to Site</a>","field8":"909-993-1600","field9":"Approved"},
{"field1":"209","field2":"Inland Empire Utilities Agency","field3":"NULL","field4":"92337","field5":"NULL","field6":"909-993-1600","field7":"<a href=\"http://www.ieua.org\" target='_blank' class=''>Go to Site</a>","field8":"909-993-1600","field9":"Approved"},
{"field1":"210","field2":"Las Virgenes Municipal Water District","field3":"NULL","field4":"91307","field5":"NULL","field6":"818-251-2100","field7":"<a href=\"http://www.lvmwd.com\" target='_blank' class=''>Go to Site</a>","field8":"818-251-2100","field9":"Approved"},
{"field1":"211","field2":"Las Virgenes Municipal Water District","field3":"NULL","field4":"91367","field5":"NULL","field6":"818-251-2100","field7":"<a href=\"http://www.lvmwd.com\" target='_blank' class=''>Go to Site</a>","field8":"818-251-2100","field9":"Approved"},
{"field1":"212","field2":"Las Virgenes Municipal Water District","field3":"NULL","field4":"91311","field5":"NULL","field6":"818-251-2100","field7":"<a href=\"http://www.lvmwd.com\" target='_blank' class=''>Go to Site</a>","field8":"818-251-2100","field9":"Approved"},
{"field1":"213","field2":"Las Virgenes Municipal Water District","field3":"NULL","field4":"91304","field5":"NULL","field6":"818-251-2100","field7":"<a href=\"http://www.lvmwd.com\" target='_blank' class=''>Go to Site</a>","field8":"818-251-2100","field9":"Approved"},
{"field1":"214","field2":"Las Virgenes Municipal Water District","field3":"NULL","field4":"91302","field5":"NULL","field6":"818-251-2100","field7":"<a href=\"http://www.lvmwd.com\" target='_blank' class=''>Go to Site</a>","field8":"818-251-2100","field9":"Approved"},
{"field1":"215","field2":"Las Virgenes Municipal Water District","field3":"NULL","field4":"91364","field5":"NULL","field6":"818-251-2100","field7":"<a href=\"http://www.lvmwd.com\" target='_blank' class=''>Go to Site</a>","field8":"818-251-2100","field9":"Approved"},
{"field1":"216","field2":"Las Virgenes Municipal Water District","field3":"NULL","field4":"91301","field5":"NULL","field6":"818-251-2100","field7":"<a href=\"http://www.lvmwd.com\" target='_blank' class=''>Go to Site</a>","field8":"818-251-2100","field9":"Approved"},
{"field1":"217","field2":"Las Virgenes Municipal Water District","field3":"NULL","field4":"91361","field5":"NULL","field6":"818-251-2100","field7":"<a href=\"http://www.lvmwd.com\" target='_blank' class=''>Go to Site</a>","field8":"818-251-2100","field9":"Approved"},
{"field1":"218","field2":"Las Virgenes Municipal Water District","field3":"NULL","field4":"90290","field5":"NULL","field6":"818-251-2100","field7":"<a href=\"http://www.lvmwd.com\" target='_blank' class=''>Go to Site</a>","field8":"818-251-2100","field9":"Approved"},
{"field1":"219","field2":"Las Virgenes Municipal Water District","field3":"NULL","field4":"90265","field5":"NULL","field6":"818-251-2100","field7":"<a href=\"http://www.lvmwd.com\" target='_blank' class=''>Go to Site</a>","field8":"818-251-2100","field9":"Approved"},
{"field1":"225","field2":"City of Long Beach","field3":"City of Long Beach","field4":"90802","field5":"NULL","field6":"562-570-2300","field7":"<a href=\"http://www.lbwater.org\" target='_blank' class=''>Go to Site</a>","field8":"562-570-2300","field9":"Approved"},
{"field1":"226","field2":"City of Long Beach","field3":"City of Long Beach","field4":"90803","field5":"NULL","field6":"562-570-2300","field7":"<a href=\"http://www.lbwater.org\" target='_blank' class=''>Go to Site</a>","field8":"562-570-2300","field9":"Approved"},
{"field1":"227","field2":"City of Long Beach","field3":"City of Long Beach","field4":"90804","field5":"NULL","field6":"562-570-2300","field7":"<a href=\"http://www.lbwater.org\" target='_blank' class=''>Go to Site</a>","field8":"562-570-2300","field9":"Approved"},
{"field1":"228","field2":"City of Long Beach","field3":"City of Long Beach","field4":"90805","field5":"NULL","field6":"562-570-2300","field7":"<a href=\"http://www.lbwater.org\" target='_blank' class=''>Go to Site</a>","field8":"562-570-2300","field9":"Approved"},
{"field1":"229","field2":"City of Long Beach","field3":"City of Long Beach","field4":"90806","field5":"NULL","field6":"562-570-2300","field7":"<a href=\"http://www.lbwater.org\" target='_blank' class=''>Go to Site</a>","field8":"562-570-2300","field9":"Approved"},
{"field1":"230","field2":"City of Long Beach","field3":"City of Long Beach","field4":"90807","field5":"NULL","field6":"562-570-2300","field7":"<a href=\"http://www.lbwater.org\" target='_blank' class=''>Go to Site</a>","field8":"562-570-2300","field9":"Approved"},
{"field1":"231","field2":"City of Long Beach","field3":"City of Long Beach","field4":"90808","field5":"NULL","field6":"562-570-2300","field7":"<a href=\"http://www.lbwater.org\" target='_blank' class=''>Go to Site</a>","field8":"562-570-2300","field9":"Approved"},
{"field1":"232","field2":"City of Long Beach","field3":"City of Long Beach","field4":"90810","field5":"NULL","field6":"562-570-2300","field7":"<a href=\"http://www.lbwater.org\" target='_blank' class=''>Go to Site</a>","field8":"562-570-2300","field9":"Approved"},
{"field1":"233","field2":"City of Long Beach","field3":"City of Long Beach","field4":"90813","field5":"NULL","field6":"562-570-2300","field7":"<a href=\"http://www.lbwater.org\" target='_blank' class=''>Go to Site</a>","field8":"562-570-2300","field9":"Approved"},
{"field1":"234","field2":"City of Long Beach","field3":"City of Long Beach","field4":"90814","field5":"NULL","field6":"562-570-2300","field7":"<a href=\"http://www.lbwater.org\" target='_blank' class=''>Go to Site</a>","field8":"562-570-2300","field9":"Approved"},
{"field1":"235","field2":"City of Long Beach","field3":"City of Long Beach","field4":"90815","field5":"NULL","field6":"562-570-2300","field7":"<a href=\"http://www.lbwater.org\" target='_blank' class=''>Go to Site</a>","field8":"562-570-2300","field9":"Approved"},
{"field1":"236","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90001","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"237","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90002","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"238","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90003","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"239","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90004","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"240","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90005","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"241","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90006","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"242","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90007","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"243","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90008","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"244","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90010","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"245","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90011","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"246","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90012","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"247","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90013","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"248","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90014","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"249","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90015","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"250","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90016","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"251","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90017","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"252","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90018","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"253","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90019","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"254","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90020","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"255","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90021","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"256","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90023","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"257","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90024","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"258","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90025","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"259","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90026","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"260","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90027","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"261","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90028","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"262","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90029","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"263","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90031","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"264","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90032","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"265","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90033","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"266","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90034","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"267","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90035","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"268","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90036","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"269","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90037","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"270","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90038","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"271","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90039","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"272","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90041","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"273","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90042","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"274","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90043","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"275","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90044","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"276","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90045","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"277","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90046","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"278","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90047","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"279","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90048","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"280","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90049","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"281","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90056","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"282","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90057","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"283","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90058","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"284","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90059","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"285","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90061","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"286","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90062","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"287","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90063","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"288","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90064","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"289","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90065","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"290","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90066","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"291","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90067","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"292","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90068","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"293","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90069","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"294","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90071","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"295","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90073","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"296","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90077","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"297","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90089","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"298","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90094","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"299","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90095","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"300","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90210","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"301","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90211","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"302","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90212","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"303","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90230","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"304","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90232","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"305","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90245","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"306","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90247","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"307","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90248","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"308","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90265","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"309","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90272","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"310","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90275","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"311","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90290","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"312","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90291","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"313","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90292","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"314","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90293","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"315","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90301","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"316","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90302","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"317","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90304","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"318","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90305","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"319","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90402","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"320","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90501","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"321","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90502","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"322","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90710","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"323","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90717","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"324","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90731","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"325","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90732","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"326","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90744","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"327","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90745","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"328","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90810","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"329","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"90813","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"330","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"91030","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"331","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"91040","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"332","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"91042","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"333","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"91105","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"334","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"91201","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"335","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"91202","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"336","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"91204","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"337","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"91205","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"338","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"91206","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"339","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"91208","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"340","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"91214","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"341","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"91302","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"342","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"91303","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"343","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"91304","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"344","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"91306","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"345","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"91307","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"346","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"91311","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"347","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"91316","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"348","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"91324","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"349","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"91325","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"350","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"91326","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"351","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"91330","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"352","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"91331","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"353","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"91335","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"354","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"91340","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"355","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"91342","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"356","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"91343","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"357","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"91344","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"358","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"91345","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"359","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"91352","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"360","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"91356","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"361","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"91364","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"362","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"91367","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"363","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"91401","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"364","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"91402","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"365","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"91403","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"366","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"91405","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"367","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"91406","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"368","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"91411","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"369","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"91423","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"370","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"91436","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"371","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"91501","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"372","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"91504","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"373","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"91505","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"374","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"91506","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"375","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"91510","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"376","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"91601","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"377","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"91602","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"378","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"91604","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"379","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"91605","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"380","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"91606","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"381","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"91607","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"382","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"91608","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"383","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"91801","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"384","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"91803","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"385","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"91765","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"386","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"90703","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"387","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"90631","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"388","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92823","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"389","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92821","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"390","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"90603","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"391","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"90604","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"392","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"90638","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"393","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"90623","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"394","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"90715","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"395","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"90808","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"396","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"90716","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"397","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"90815","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"398","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"90803","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"402","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92886","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"403","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92835","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"404","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92887","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"405","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92845","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"406","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"90740","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"407","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92705","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"408","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92706","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"409","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92683","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"412","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92844","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"413","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92843","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"414","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92780","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"415","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92782","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"416","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92870","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"417","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92833","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"419","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92831","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"421","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"90621","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"422","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92807","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"423","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"90620","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"424","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92808","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"425","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92806","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"427","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92801","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"428","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"90630","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"429","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92865","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"430","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92867","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"431","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92804","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"432","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92802","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"433","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92861","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"434","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92862","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"435","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92869","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"436","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"90720","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"438","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"90680","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"440","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92841","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"441","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92840","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"442","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92868","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"445","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92866","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"446","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92692","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"447","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92688","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"448","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92603","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"449","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92691","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"450","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92697","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"451","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92663","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"452","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92657","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"453","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92602","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"454","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92703","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"456","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92701","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"457","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92647","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"458","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92676","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"459","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92655","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"461","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92649","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"463","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92704","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"466","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92707","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"467","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92708","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"468","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"90743","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"469","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92620","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"470","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92618","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"471","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"90742","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"472","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92606","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"473","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92710","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"474","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92604","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"475","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92610","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"476","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92648","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"477","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92614","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"478","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92646","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"479","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92626","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"480","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92709","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"481","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92679","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"483","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92612","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"484","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92630","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"485","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92627","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"486","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92660","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"487","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92653","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"488","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92625","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"489","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92661","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"490","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92662","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"491","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92651","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"492","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92656","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"493","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92694","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"494","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92677","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"495","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92675","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"496","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92629","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"497","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92673","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"498","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92624","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"499","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92672","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"500","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92882","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"502","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92562","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"503","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"91709","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"504","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92055","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"506","field2":"City of Pasadena","field3":"City of Pasadena","field4":"91102","field5":"NULL","field6":"626-744-6970","field7":"<a href=\"http://www.PWPweb.com\" target='_blank' class=''>Go to Site</a>","field8":"626-744-6970","field9":"Approved"},
{"field1":"507","field2":"City of Pasadena","field3":"City of Pasadena","field4":"91109","field5":"NULL","field6":"626-744-6970","field7":"<a href=\"http://www.PWPweb.com\" target='_blank' class=''>Go to Site</a>","field8":"626-744-6970","field9":"Approved"},
{"field1":"508","field2":"City of Pasadena","field3":"City of Pasadena","field4":"91110","field5":"NULL","field6":"626-744-6970","field7":"<a href=\"http://www.PWPweb.com\" target='_blank' class=''>Go to Site</a>","field8":"626-744-6970","field9":"Approved"},
{"field1":"509","field2":"City of Pasadena","field3":"City of Pasadena","field4":"91114","field5":"NULL","field6":"626-744-6970","field7":"<a href=\"http://www.PWPweb.com\" target='_blank' class=''>Go to Site</a>","field8":"626-744-6970","field9":"Approved"},
{"field1":"510","field2":"City of Pasadena","field3":"City of Pasadena","field4":"91115","field5":"NULL","field6":"626-744-6970","field7":"<a href=\"http://www.PWPweb.com\" target='_blank' class=''>Go to Site</a>","field8":"626-744-6970","field9":"Approved"},
{"field1":"511","field2":"City of Pasadena","field3":"City of Pasadena","field4":"91116","field5":"NULL","field6":"626-744-6970","field7":"<a href=\"http://www.PWPweb.com\" target='_blank' class=''>Go to Site</a>","field8":"626-744-6970","field9":"Approved"},
{"field1":"512","field2":"City of Pasadena","field3":"City of Pasadena","field4":"91117","field5":"NULL","field6":"626-744-6970","field7":"<a href=\"http://www.PWPweb.com\" target='_blank' class=''>Go to Site</a>","field8":"626-744-6970","field9":"Approved"},
{"field1":"513","field2":"City of Pasadena","field3":"City of Pasadena","field4":"91118","field5":"NULL","field6":"626-744-6970","field7":"<a href=\"http://www.PWPweb.com\" target='_blank' class=''>Go to Site</a>","field8":"626-744-6970","field9":"Approved"},
{"field1":"514","field2":"City of Pasadena","field3":"City of Pasadena","field4":"91121","field5":"NULL","field6":"626-744-6970","field7":"<a href=\"http://www.PWPweb.com\" target='_blank' class=''>Go to Site</a>","field8":"626-744-6970","field9":"Approved"},
{"field1":"515","field2":"City of Pasadena","field3":"City of Pasadena","field4":"91123","field5":"NULL","field6":"626-744-6970","field7":"<a href=\"http://www.PWPweb.com\" target='_blank' class=''>Go to Site</a>","field8":"626-744-6970","field9":"Approved"},
{"field1":"516","field2":"City of Pasadena","field3":"City of Pasadena","field4":"91124","field5":"NULL","field6":"626-744-6970","field7":"<a href=\"http://www.PWPweb.com\" target='_blank' class=''>Go to Site</a>","field8":"626-744-6970","field9":"Approved"},
{"field1":"517","field2":"City of Pasadena","field3":"City of Pasadena","field4":"91125","field5":"NULL","field6":"626-744-6970","field7":"<a href=\"http://www.PWPweb.com\" target='_blank' class=''>Go to Site</a>","field8":"626-744-6970","field9":"Approved"},
{"field1":"518","field2":"City of Pasadena","field3":"City of Pasadena","field4":"91126","field5":"NULL","field6":"626-744-6970","field7":"<a href=\"http://www.PWPweb.com\" target='_blank' class=''>Go to Site</a>","field8":"626-744-6970","field9":"Approved"},
{"field1":"519","field2":"City of Pasadena","field3":"City of Pasadena","field4":"91129","field5":"NULL","field6":"626-744-6970","field7":"<a href=\"http://www.PWPweb.com\" target='_blank' class=''>Go to Site</a>","field8":"626-744-6970","field9":"Approved"},
{"field1":"520","field2":"City of Pasadena","field3":"City of Pasadena","field4":"91131","field5":"NULL","field6":"626-744-6970","field7":"<a href=\"http://www.PWPweb.com\" target='_blank' class=''>Go to Site</a>","field8":"626-744-6970","field9":"Approved"},
{"field1":"521","field2":"City of Pasadena","field3":"City of Pasadena","field4":"91175","field5":"NULL","field6":"626-744-6970","field7":"<a href=\"http://www.PWPweb.com\" target='_blank' class=''>Go to Site</a>","field8":"626-744-6970","field9":"Approved"},
{"field1":"523","field2":"City of Pasadena","field3":"City of Pasadena","field4":"91182","field5":"NULL","field6":"626-744-6970","field7":"<a href=\"http://www.PWPweb.com\" target='_blank' class=''>Go to Site</a>","field8":"626-744-6970","field9":"Approved"},
{"field1":"524","field2":"City of Pasadena","field3":"City of Pasadena","field4":"91184","field5":"NULL","field6":"626-744-6970","field7":"<a href=\"http://www.PWPweb.com\" target='_blank' class=''>Go to Site</a>","field8":"626-744-6970","field9":"Approved"},
{"field1":"525","field2":"City of Pasadena","field3":"City of Pasadena","field4":"91001","field5":"NULL","field6":"626-744-6970","field7":"<a href=\"http://www.PWPweb.com\" target='_blank' class=''>Go to Site</a>","field8":"626-744-6970","field9":"Approved"},
{"field1":"526","field2":"City of Pasadena","field3":"City of Pasadena","field4":"91101","field5":"NULL","field6":"626-744-6970","field7":"<a href=\"http://www.PWPweb.com\" target='_blank' class=''>Go to Site</a>","field8":"626-744-6970","field9":"Approved"},
{"field1":"527","field2":"City of Pasadena","field3":"City of Pasadena","field4":"91103","field5":"NULL","field6":"626-744-6970","field7":"<a href=\"http://www.PWPweb.com\" target='_blank' class=''>Go to Site</a>","field8":"626-744-6970","field9":"Approved"},
{"field1":"528","field2":"City of Pasadena","field3":"City of Pasadena","field4":"91104","field5":"NULL","field6":"626-744-6970","field7":"<a href=\"http://www.PWPweb.com\" target='_blank' class=''>Go to Site</a>","field8":"626-744-6970","field9":"Approved"},
{"field1":"529","field2":"City of Pasadena","field3":"City of Pasadena","field4":"91105","field5":"NULL","field6":"626-744-6970","field7":"<a href=\"http://www.PWPweb.com\" target='_blank' class=''>Go to Site</a>","field8":"626-744-6970","field9":"Approved"},
{"field1":"530","field2":"City of Pasadena","field3":"City of Pasadena","field4":"91106","field5":"NULL","field6":"626-744-6970","field7":"<a href=\"http://www.PWPweb.com\" target='_blank' class=''>Go to Site</a>","field8":"626-744-6970","field9":"Approved"},
{"field1":"531","field2":"City of Pasadena","field3":"City of Pasadena","field4":"91107","field5":"NULL","field6":"626-744-6970","field7":"<a href=\"http://www.PWPweb.com\" target='_blank' class=''>Go to Site</a>","field8":"626-744-6970","field9":"Approved"},
{"field1":"532","field2":"San Diego County Water Authority","field3":"NULL","field4":"91901","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"533","field2":"San Diego County Water Authority","field3":"NULL","field4":"91902","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"534","field2":"San Diego County Water Authority","field3":"NULL","field4":"91906","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"535","field2":"San Diego County Water Authority","field3":"NULL","field4":"91910","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"536","field2":"San Diego County Water Authority","field3":"NULL","field4":"91911","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"537","field2":"San Diego County Water Authority","field3":"NULL","field4":"91913","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"538","field2":"San Diego County Water Authority","field3":"NULL","field4":"91914","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"539","field2":"San Diego County Water Authority","field3":"NULL","field4":"91915","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"540","field2":"San Diego County Water Authority","field3":"NULL","field4":"91916","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"541","field2":"San Diego County Water Authority","field3":"NULL","field4":"91917","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"542","field2":"San Diego County Water Authority","field3":"NULL","field4":"91932","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"543","field2":"San Diego County Water Authority","field3":"NULL","field4":"91935","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"544","field2":"San Diego County Water Authority","field3":"NULL","field4":"91941","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"545","field2":"San Diego County Water Authority","field3":"NULL","field4":"91942","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"546","field2":"San Diego County Water Authority","field3":"NULL","field4":"91944","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"547","field2":"San Diego County Water Authority","field3":"NULL","field4":"91945","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"548","field2":"San Diego County Water Authority","field3":"NULL","field4":"91950","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"549","field2":"San Diego County Water Authority","field3":"NULL","field4":"91977","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"550","field2":"San Diego County Water Authority","field3":"NULL","field4":"91978","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"551","field2":"San Diego County Water Authority","field3":"NULL","field4":"92003","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"552","field2":"San Diego County Water Authority","field3":"NULL","field4":"92007","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"553","field2":"San Diego County Water Authority","field3":"NULL","field4":"92008","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"554","field2":"San Diego County Water Authority","field3":"NULL","field4":"92009","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"555","field2":"San Diego County Water Authority","field3":"NULL","field4":"92010","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"556","field2":"San Diego County Water Authority","field3":"NULL","field4":"92011","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"557","field2":"San Diego County Water Authority","field3":"NULL","field4":"92014","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"558","field2":"San Diego County Water Authority","field3":"NULL","field4":"92019","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"559","field2":"San Diego County Water Authority","field3":"NULL","field4":"92020","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"560","field2":"San Diego County Water Authority","field3":"NULL","field4":"92021","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"561","field2":"San Diego County Water Authority","field3":"NULL","field4":"92024","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"562","field2":"San Diego County Water Authority","field3":"NULL","field4":"92025","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"563","field2":"San Diego County Water Authority","field3":"NULL","field4":"92026","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"564","field2":"San Diego County Water Authority","field3":"NULL","field4":"92027","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"565","field2":"San Diego County Water Authority","field3":"NULL","field4":"92028","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"566","field2":"San Diego County Water Authority","field3":"NULL","field4":"92029","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"567","field2":"San Diego County Water Authority","field3":"NULL","field4":"92036","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"568","field2":"San Diego County Water Authority","field3":"NULL","field4":"92037","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"569","field2":"San Diego County Water Authority","field3":"NULL","field4":"92040","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"570","field2":"San Diego County Water Authority","field3":"NULL","field4":"92049","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"571","field2":"San Diego County Water Authority","field3":"NULL","field4":"92051","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"572","field2":"San Diego County Water Authority","field3":"NULL","field4":"92052","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"573","field2":"San Diego County Water Authority","field3":"NULL","field4":"92054","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"574","field2":"San Diego County Water Authority","field3":"NULL","field4":"92055","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"575","field2":"San Diego County Water Authority","field3":"NULL","field4":"92056","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"576","field2":"San Diego County Water Authority","field3":"NULL","field4":"92057","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"577","field2":"San Diego County Water Authority","field3":"NULL","field4":"92058","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"578","field2":"San Diego County Water Authority","field3":"NULL","field4":"92059","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"579","field2":"San Diego County Water Authority","field3":"NULL","field4":"92061","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"580","field2":"San Diego County Water Authority","field3":"NULL","field4":"92064","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"581","field2":"San Diego County Water Authority","field3":"NULL","field4":"92065","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"582","field2":"San Diego County Water Authority","field3":"NULL","field4":"92067","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"583","field2":"San Diego County Water Authority","field3":"NULL","field4":"92069","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"584","field2":"San Diego County Water Authority","field3":"NULL","field4":"92071","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"585","field2":"San Diego County Water Authority","field3":"NULL","field4":"92074","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"586","field2":"San Diego County Water Authority","field3":"NULL","field4":"92075","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"587","field2":"San Diego County Water Authority","field3":"NULL","field4":"92081","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"588","field2":"San Diego County Water Authority","field3":"NULL","field4":"92082","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"589","field2":"San Diego County Water Authority","field3":"NULL","field4":"92083","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"590","field2":"San Diego County Water Authority","field3":"NULL","field4":"92084","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"591","field2":"San Diego County Water Authority","field3":"NULL","field4":"92085","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"592","field2":"San Diego County Water Authority","field3":"NULL","field4":"92088","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"593","field2":"San Diego County Water Authority","field3":"NULL","field4":"92101","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"594","field2":"San Diego County Water Authority","field3":"NULL","field4":"92102","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"595","field2":"San Diego County Water Authority","field3":"NULL","field4":"92103","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"596","field2":"San Diego County Water Authority","field3":"NULL","field4":"92104","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"597","field2":"San Diego County Water Authority","field3":"NULL","field4":"92105","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"598","field2":"San Diego County Water Authority","field3":"NULL","field4":"92106","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"599","field2":"San Diego County Water Authority","field3":"NULL","field4":"92107","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"600","field2":"San Diego County Water Authority","field3":"NULL","field4":"92108","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"601","field2":"San Diego County Water Authority","field3":"NULL","field4":"92109","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"602","field2":"San Diego County Water Authority","field3":"NULL","field4":"92110","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"603","field2":"San Diego County Water Authority","field3":"NULL","field4":"92111","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"604","field2":"San Diego County Water Authority","field3":"NULL","field4":"92113","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"605","field2":"San Diego County Water Authority","field3":"NULL","field4":"92114","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"606","field2":"San Diego County Water Authority","field3":"NULL","field4":"92115","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"607","field2":"San Diego County Water Authority","field3":"NULL","field4":"92116","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"608","field2":"San Diego County Water Authority","field3":"NULL","field4":"92117","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"609","field2":"San Diego County Water Authority","field3":"NULL","field4":"92119","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"610","field2":"San Diego County Water Authority","field3":"NULL","field4":"92120","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"611","field2":"San Diego County Water Authority","field3":"NULL","field4":"92121","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"612","field2":"San Diego County Water Authority","field3":"NULL","field4":"92122","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"613","field2":"San Diego County Water Authority","field3":"NULL","field4":"92123","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"614","field2":"San Diego County Water Authority","field3":"NULL","field4":"92124","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"615","field2":"San Diego County Water Authority","field3":"NULL","field4":"92126","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"616","field2":"San Diego County Water Authority","field3":"NULL","field4":"92127","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"617","field2":"San Diego County Water Authority","field3":"NULL","field4":"92128","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"618","field2":"San Diego County Water Authority","field3":"NULL","field4":"92129","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"619","field2":"San Diego County Water Authority","field3":"NULL","field4":"92130","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"620","field2":"San Diego County Water Authority","field3":"NULL","field4":"92131","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"621","field2":"San Diego County Water Authority","field3":"NULL","field4":"92134","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"622","field2":"San Diego County Water Authority","field3":"NULL","field4":"92135","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"623","field2":"San Diego County Water Authority","field3":"NULL","field4":"92136","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"624","field2":"San Diego County Water Authority","field3":"NULL","field4":"92139","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"625","field2":"San Diego County Water Authority","field3":"NULL","field4":"92140","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"626","field2":"San Diego County Water Authority","field3":"NULL","field4":"92145","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"627","field2":"San Diego County Water Authority","field3":"NULL","field4":"92154","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"628","field2":"San Diego County Water Authority","field3":"NULL","field4":"92173","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"629","field2":"City of San Fernando","field3":"City of San Fernando","field4":"91340","field5":"NULL","field6":"818-898-1223","field7":"<a href=\"http://www.ci.san-fernando.ca.us/\" target='_blank' class=''>Go to Site</a>","field8":"818-898-1223","field9":"Approved"},
{"field1":"630","field2":"City of San Fernando","field3":"City of San Fernando","field4":"91341","field5":"NULL","field6":"818-898-1223","field7":"<a href=\"http://www.ci.san-fernando.ca.us/\" target='_blank' class=''>Go to Site</a>","field8":"818-898-1223","field9":"Approved"},
{"field1":"631","field2":"City of San Fernando","field3":"City of San Fernando","field4":"91344","field5":"NULL","field6":"818-898-1223","field7":"<a href=\"http://www.ci.san-fernando.ca.us/\" target='_blank' class=''>Go to Site</a>","field8":"818-898-1223","field9":"Approved"},
{"field1":"632","field2":"City of San Fernando","field3":"City of San Fernando","field4":"91345","field5":"NULL","field6":"818-898-1223","field7":"<a href=\"http://www.ci.san-fernando.ca.us/\" target='_blank' class=''>Go to Site</a>","field8":"818-898-1223","field9":"Approved"},
{"field1":"633","field2":"City of San Fernando","field3":"City of San Fernando","field4":"91346","field5":"NULL","field6":"818-898-1223","field7":"<a href=\"http://www.ci.san-fernando.ca.us/\" target='_blank' class=''>Go to Site</a>","field8":"818-898-1223","field9":"Approved"},
{"field1":"634","field2":"City of San Marino","field3":"City of San Marino","field4":"91118","field5":"NULL","field6":"888-237-1333","field7":"<a href=\"http://www.calamwater.com/awpr1/caaw/default.html\" target='_blank' class=''>Go to Site</a>","field8":"888-237-1333","field9":"Approved"},
{"field1":"635","field2":"City of San Marino","field3":"City of San Marino","field4":"91108","field5":"NULL","field6":"888-237-1333","field7":"<a href=\"http://www.calamwater.com/awpr1/caaw/default.html\" target='_blank' class=''>Go to Site</a>","field8":"888-237-1333","field9":"Approved"},
{"field1":"636","field2":"City of Santa Ana","field3":"City of Santa Ana","field4":"92701","field5":"NULL","field6":"714-647-3341","field7":"<a href=\"http://www.ci.santa-ana.ca.us/default.asp\" target='_blank' class=''>Go to Site</a>","field8":"714-647-3341","field9":"Approved"},
{"field1":"637","field2":"City of Santa Ana","field3":"City of Santa Ana","field4":"92702","field5":"NULL","field6":"714-647-3341","field7":"<a href=\"http://www.ci.santa-ana.ca.us/default.asp\" target='_blank' class=''>Go to Site</a>","field8":"714-647-3341","field9":"Approved"},
{"field1":"638","field2":"City of Santa Ana","field3":"City of Santa Ana","field4":"92703","field5":"NULL","field6":"714-647-3341","field7":"<a href=\"http://www.ci.santa-ana.ca.us/default.asp\" target='_blank' class=''>Go to Site</a>","field8":"714-647-3341","field9":"Approved"},
{"field1":"639","field2":"City of Santa Ana","field3":"City of Santa Ana","field4":"92710","field5":"NULL","field6":"714-647-3341","field7":"<a href=\"http://www.ci.santa-ana.ca.us/default.asp\" target='_blank' class=''>Go to Site</a>","field8":"714-647-3341","field9":"Approved"},
{"field1":"640","field2":"City of Santa Ana","field3":"City of Santa Ana","field4":"92711","field5":"NULL","field6":"714-647-3341","field7":"<a href=\"http://www.ci.santa-ana.ca.us/default.asp\" target='_blank' class=''>Go to Site</a>","field8":"714-647-3341","field9":"Approved"},
{"field1":"641","field2":"City of Santa Ana","field3":"City of Santa Ana","field4":"92799","field5":"NULL","field6":"714-647-3341","field7":"<a href=\"http://www.ci.santa-ana.ca.us/default.asp\" target='_blank' class=''>Go to Site</a>","field8":"714-647-3341","field9":"Approved"},
{"field1":"642","field2":"City of Santa Ana","field3":"City of Santa Ana","field4":"92704","field5":"NULL","field6":"714-647-3341","field7":"<a href=\"http://www.ci.santa-ana.ca.us/default.asp\" target='_blank' class=''>Go to Site</a>","field8":"714-647-3341","field9":"Approved"},
{"field1":"643","field2":"City of Santa Ana","field3":"City of Santa Ana","field4":"92705","field5":"NULL","field6":"714-647-3341","field7":"<a href=\"http://www.ci.santa-ana.ca.us/default.asp\" target='_blank' class=''>Go to Site</a>","field8":"714-647-3341","field9":"Approved"},
{"field1":"644","field2":"City of Santa Ana","field3":"City of Santa Ana","field4":"92706","field5":"NULL","field6":"714-647-3341","field7":"<a href=\"http://www.ci.santa-ana.ca.us/default.asp\" target='_blank' class=''>Go to Site</a>","field8":"714-647-3341","field9":"Approved"},
{"field1":"645","field2":"City of Santa Ana","field3":"City of Santa Ana","field4":"92707","field5":"NULL","field6":"714-647-3341","field7":"<a href=\"http://www.ci.santa-ana.ca.us/default.asp\" target='_blank' class=''>Go to Site</a>","field8":"714-647-3341","field9":"Approved"},
{"field1":"646","field2":"City of Santa Ana","field3":"City of Santa Ana","field4":"92708","field5":"NULL","field6":"714-647-3341","field7":"<a href=\"http://www.ci.santa-ana.ca.us/default.asp\" target='_blank' class=''>Go to Site</a>","field8":"714-647-3341","field9":"Approved"},
{"field1":"647","field2":"City of Santa Monica","field3":"City of Santa Monica","field4":"90272","field5":"NULL","field6":"866-728-3229","field7":"<a href=\"http://www.smgov.net/\" target='_blank' class=''>Go to Site</a>","field8":"866-728-3229","field9":"Approved"},
{"field1":"648","field2":"City of Santa Monica","field3":"City of Santa Monica","field4":"90049","field5":"NULL","field6":"866-728-3229","field7":"<a href=\"http://www.smgov.net/\" target='_blank' class=''>Go to Site</a>","field8":"866-728-3229","field9":"Approved"},
{"field1":"649","field2":"City of Santa Monica","field3":"City of Santa Monica","field4":"90064","field5":"NULL","field6":"866-728-3229","field7":"<a href=\"http://www.smgov.net/\" target='_blank' class=''>Go to Site</a>","field8":"866-728-3229","field9":"Approved"},
{"field1":"650","field2":"City of Santa Monica","field3":"City of Santa Monica","field4":"90402","field5":"NULL","field6":"866-728-3229","field7":"<a href=\"http://www.smgov.net/\" target='_blank' class=''>Go to Site</a>","field8":"866-728-3229","field9":"Approved"},
{"field1":"651","field2":"City of Santa Monica","field3":"City of Santa Monica","field4":"90291","field5":"NULL","field6":"866-728-3229","field7":"<a href=\"http://www.smgov.net/\" target='_blank' class=''>Go to Site</a>","field8":"866-728-3229","field9":"Approved"},
{"field1":"652","field2":"City of Santa Monica","field3":"City of Santa Monica","field4":"90403","field5":"NULL","field6":"866-728-3229","field7":"<a href=\"http://www.smgov.net/\" target='_blank' class=''>Go to Site</a>","field8":"866-728-3229","field9":"Approved"},
{"field1":"653","field2":"City of Santa Monica","field3":"City of Santa Monica","field4":"90404","field5":"NULL","field6":"866-728-3229","field7":"<a href=\"http://www.smgov.net/\" target='_blank' class=''>Go to Site</a>","field8":"866-728-3229","field9":"Approved"},
{"field1":"654","field2":"City of Santa Monica","field3":"City of Santa Monica","field4":"90405","field5":"NULL","field6":"866-728-3229","field7":"<a href=\"http://www.smgov.net/\" target='_blank' class=''>Go to Site</a>","field8":"866-728-3229","field9":"Approved"},
{"field1":"655","field2":"City of Santa Monica","field3":"City of Santa Monica","field4":"90066","field5":"NULL","field6":"866-728-3229","field7":"<a href=\"http://www.smgov.net/\" target='_blank' class=''>Go to Site</a>","field8":"866-728-3229","field9":"Approved"},
{"field1":"656","field2":"City of Santa Monica","field3":"City of Santa Monica","field4":"90401","field5":"NULL","field6":"866-728-3229","field7":"<a href=\"http://www.smgov.net/\" target='_blank' class=''>Go to Site</a>","field8":"866-728-3229","field9":"Approved"},
{"field1":"657","field2":"Three Valleys Municipal Water District","field3":"NULL","field4":"91702","field5":"NULL","field6":"909-621-5568","field7":"<a href=\"http://www.threevalleys.com\" target='_blank' class=''>Go to Site</a>","field8":"909-621-5568","field9":"Approved"},
{"field1":"658","field2":"Three Valleys Municipal Water District","field3":"NULL","field4":"91711","field5":"NULL","field6":"909-621-5568","field7":"<a href=\"http://www.threevalleys.com\" target='_blank' class=''>Go to Site</a>","field8":"909-621-5568","field9":"Approved"},
{"field1":"659","field2":"Three Valleys Municipal Water District","field3":"NULL","field4":"91722","field5":"NULL","field6":"909-621-5568","field7":"<a href=\"http://www.threevalleys.com\" target='_blank' class=''>Go to Site</a>","field8":"909-621-5568","field9":"Approved"},
{"field1":"660","field2":"Three Valleys Municipal Water District","field3":"NULL","field4":"91723","field5":"NULL","field6":"909-621-5568","field7":"<a href=\"http://www.threevalleys.com\" target='_blank' class=''>Go to Site</a>","field8":"909-621-5568","field9":"Approved"},
{"field1":"661","field2":"Three Valleys Municipal Water District","field3":"NULL","field4":"91724","field5":"NULL","field6":"909-621-5568","field7":"<a href=\"http://www.threevalleys.com\" target='_blank' class=''>Go to Site</a>","field8":"909-621-5568","field9":"Approved"},
{"field1":"662","field2":"Three Valleys Municipal Water District","field3":"NULL","field4":"91740","field5":"NULL","field6":"909-621-5568","field7":"<a href=\"http://www.threevalleys.com\" target='_blank' class=''>Go to Site</a>","field8":"909-621-5568","field9":"Approved"},
{"field1":"663","field2":"Three Valleys Municipal Water District","field3":"NULL","field4":"91741","field5":"NULL","field6":"909-621-5568","field7":"<a href=\"http://www.threevalleys.com\" target='_blank' class=''>Go to Site</a>","field8":"909-621-5568","field9":"Approved"},
{"field1":"664","field2":"Three Valleys Municipal Water District","field3":"NULL","field4":"91744","field5":"NULL","field6":"909-621-5568","field7":"<a href=\"http://www.threevalleys.com\" target='_blank' class=''>Go to Site</a>","field8":"909-621-5568","field9":"Approved"},
{"field1":"665","field2":"Three Valleys Municipal Water District","field3":"NULL","field4":"91745","field5":"NULL","field6":"909-621-5568","field7":"<a href=\"http://www.threevalleys.com\" target='_blank' class=''>Go to Site</a>","field8":"909-621-5568","field9":"Approved"},
{"field1":"666","field2":"Three Valleys Municipal Water District","field3":"NULL","field4":"91748","field5":"NULL","field6":"909-621-5568","field7":"<a href=\"http://www.threevalleys.com\" target='_blank' class=''>Go to Site</a>","field8":"909-621-5568","field9":"Approved"},
{"field1":"667","field2":"Three Valleys Municipal Water District","field3":"NULL","field4":"91750","field5":"NULL","field6":"909-621-5568","field7":"<a href=\"http://www.threevalleys.com\" target='_blank' class=''>Go to Site</a>","field8":"909-621-5568","field9":"Approved"},
{"field1":"668","field2":"Three Valleys Municipal Water District","field3":"NULL","field4":"91765","field5":"NULL","field6":"909-621-5568","field7":"<a href=\"http://www.threevalleys.com\" target='_blank' class=''>Go to Site</a>","field8":"909-621-5568","field9":"Approved"},
{"field1":"669","field2":"Three Valleys Municipal Water District","field3":"NULL","field4":"91766","field5":"NULL","field6":"909-621-5568","field7":"<a href=\"http://www.threevalleys.com\" target='_blank' class=''>Go to Site</a>","field8":"909-621-5568","field9":"Approved"},
{"field1":"670","field2":"Three Valleys Municipal Water District","field3":"NULL","field4":"91767","field5":"NULL","field6":"909-621-5568","field7":"<a href=\"http://www.threevalleys.com\" target='_blank' class=''>Go to Site</a>","field8":"909-621-5568","field9":"Approved"},
{"field1":"671","field2":"Three Valleys Municipal Water District","field3":"NULL","field4":"91768","field5":"NULL","field6":"909-621-5568","field7":"<a href=\"http://www.threevalleys.com\" target='_blank' class=''>Go to Site</a>","field8":"909-621-5568","field9":"Approved"},
{"field1":"672","field2":"Three Valleys Municipal Water District","field3":"NULL","field4":"91773","field5":"NULL","field6":"909-621-5568","field7":"<a href=\"http://www.threevalleys.com\" target='_blank' class=''>Go to Site</a>","field8":"909-621-5568","field9":"Approved"},
{"field1":"673","field2":"Three Valleys Municipal Water District","field3":"NULL","field4":"91789","field5":"NULL","field6":"909-621-5568","field7":"<a href=\"http://www.threevalleys.com\" target='_blank' class=''>Go to Site</a>","field8":"909-621-5568","field9":"Approved"},
{"field1":"674","field2":"Three Valleys Municipal Water District","field3":"NULL","field4":"91791","field5":"NULL","field6":"909-621-5568","field7":"<a href=\"http://www.threevalleys.com\" target='_blank' class=''>Go to Site</a>","field8":"909-621-5568","field9":"Approved"},
{"field1":"675","field2":"Three Valleys Municipal Water District","field3":"NULL","field4":"91792","field5":"NULL","field6":"909-621-5568","field7":"<a href=\"http://www.threevalleys.com\" target='_blank' class=''>Go to Site</a>","field8":"909-621-5568","field9":"Approved"},
{"field1":"676","field2":"City of Torrance","field3":"City of Torrance","field4":"90501","field5":"NULL","field6":"310-781-6900","field7":"<a href=\"https://www.torranceca.gov/our-city/public-works/water-services\" target='_blank' class=''>Go to Site</a>","field8":"310-781-6900","field9":"Approved"},
{"field1":"677","field2":"City of Torrance","field3":"City of Torrance","field4":"90502","field5":"NULL","field6":"310-781-6900","field7":"<a href=\"https://www.torranceca.gov/our-city/public-works/water-services\" target='_blank' class=''>Go to Site</a>","field8":"310-781-6900","field9":"Approved"},
{"field1":"678","field2":"City of Torrance","field3":"City of Torrance","field4":"90503","field5":"NULL","field6":"310-781-6900","field7":"<a href=\"https://www.torranceca.gov/our-city/public-works/water-services\" target='_blank' class=''>Go to Site</a>","field8":"310-781-6900","field9":"Approved"},
{"field1":"679","field2":"City of Torrance","field3":"City of Torrance","field4":"90504","field5":"NULL","field6":"310-781-6900","field7":"<a href=\"https://www.torranceca.gov/our-city/public-works/water-services\" target='_blank' class=''>Go to Site</a>","field8":"310-781-6900","field9":"Approved"},
{"field1":"680","field2":"City of Torrance","field3":"City of Torrance","field4":"90505","field5":"NULL","field6":"310-781-6900","field7":"<a href=\"https://www.torranceca.gov/our-city/public-works/water-services\" target='_blank' class=''>Go to Site</a>","field8":"310-781-6900","field9":"Approved"},
{"field1":"681","field2":"City of Torrance","field3":"City of Torrance","field4":"90506","field5":"NULL","field6":"310-781-6900","field7":"<a href=\"https://www.torranceca.gov/our-city/public-works/water-services\" target='_blank' class=''>Go to Site</a>","field8":"310-781-6900","field9":"Approved"},
{"field1":"682","field2":"City of Torrance","field3":"City of Torrance","field4":"90507","field5":"NULL","field6":"310-781-6900","field7":"<a href=\"https://www.torranceca.gov/our-city/public-works/water-services\" target='_blank' class=''>Go to Site</a>","field8":"310-781-6900","field9":"Approved"},
{"field1":"683","field2":"City of Torrance","field3":"City of Torrance","field4":"90508","field5":"NULL","field6":"310-781-6900","field7":"<a href=\"https://www.torranceca.gov/our-city/public-works/water-services\" target='_blank' class=''>Go to Site</a>","field8":"310-781-6900","field9":"Approved"},
{"field1":"684","field2":"City of Torrance","field3":"City of Torrance","field4":"90710","field5":"NULL","field6":"310-781-6900","field7":"<a href=\"https://www.torranceca.gov/our-city/public-works/water-services\" target='_blank' class=''>Go to Site</a>","field8":"310-781-6900","field9":"Approved"},
{"field1":"685","field2":"City of Torrance","field3":"City of Torrance","field4":"90509","field5":"NULL","field6":"310-781-6900","field7":"<a href=\"https://www.torranceca.gov/our-city/public-works/water-services\" target='_blank' class=''>Go to Site</a>","field8":"310-781-6900","field9":"Approved"},
{"field1":"686","field2":"Upper San Gabriel Valley Municipal Water District","field3":"NULL","field4":"91010","field5":"NULL","field6":"626-443-2297","field7":"<a href=\"http://www.usgvmwd.org\" target='_blank' class=''>Go to Site</a>","field8":"626-443-2297","field9":"Approved"},
{"field1":"687","field2":"Upper San Gabriel Valley Municipal Water District","field3":"NULL","field4":"91702","field5":"NULL","field6":"626-443-2297","field7":"<a href=\"http://www.usgvmwd.org\" target='_blank' class=''>Go to Site</a>","field8":"626-443-2297","field9":"Approved"},
{"field1":"688","field2":"Upper San Gabriel Valley Municipal Water District","field3":"NULL","field4":"91006","field5":"NULL","field6":"626-443-2297","field7":"<a href=\"http://www.usgvmwd.org\" target='_blank' class=''>Go to Site</a>","field8":"626-443-2297","field9":"Approved"},
{"field1":"689","field2":"Upper San Gabriel Valley Municipal Water District","field3":"NULL","field4":"91741","field5":"NULL","field6":"626-443-2297","field7":"<a href=\"http://www.usgvmwd.org\" target='_blank' class=''>Go to Site</a>","field8":"626-443-2297","field9":"Approved"},
{"field1":"690","field2":"Upper San Gabriel Valley Municipal Water District","field3":"NULL","field4":"91007","field5":"NULL","field6":"626-443-2297","field7":"<a href=\"http://www.usgvmwd.org\" target='_blank' class=''>Go to Site</a>","field8":"626-443-2297","field9":"Approved"},
{"field1":"691","field2":"Upper San Gabriel Valley Municipal Water District","field3":"NULL","field4":"91775","field5":"NULL","field6":"626-443-2297","field7":"<a href=\"http://www.usgvmwd.org\" target='_blank' class=''>Go to Site</a>","field8":"626-443-2297","field9":"Approved"},
{"field1":"692","field2":"Upper San Gabriel Valley Municipal Water District","field3":"NULL","field4":"91030","field5":"NULL","field6":"626-443-2297","field7":"<a href=\"http://www.usgvmwd.org\" target='_blank' class=''>Go to Site</a>","field8":"626-443-2297","field9":"Approved"},
{"field1":"693","field2":"Upper San Gabriel Valley Municipal Water District","field3":"NULL","field4":"91740","field5":"NULL","field6":"626-443-2297","field7":"<a href=\"http://www.usgvmwd.org\" target='_blank' class=''>Go to Site</a>","field8":"626-443-2297","field9":"Approved"},
{"field1":"694","field2":"Upper San Gabriel Valley Municipal Water District","field3":"NULL","field4":"91780","field5":"NULL","field6":"626-443-2297","field7":"<a href=\"http://www.usgvmwd.org\" target='_blank' class=''>Go to Site</a>","field8":"626-443-2297","field9":"Approved"},
{"field1":"695","field2":"Upper San Gabriel Valley Municipal Water District","field3":"NULL","field4":"91722","field5":"NULL","field6":"626-443-2297","field7":"<a href=\"http://www.usgvmwd.org\" target='_blank' class=''>Go to Site</a>","field8":"626-443-2297","field9":"Approved"},
{"field1":"696","field2":"Upper San Gabriel Valley Municipal Water District","field3":"NULL","field4":"91706","field5":"NULL","field6":"626-443-2297","field7":"<a href=\"http://www.usgvmwd.org\" target='_blank' class=''>Go to Site</a>","field8":"626-443-2297","field9":"Approved"},
{"field1":"697","field2":"Upper San Gabriel Valley Municipal Water District","field3":"NULL","field4":"91746","field5":"NULL","field6":"626-443-2297","field7":"<a href=\"http://www.usgvmwd.org\" target='_blank' class=''>Go to Site</a>","field8":"626-443-2297","field9":"Approved"},
{"field1":"698","field2":"Upper San Gabriel Valley Municipal Water District","field3":"NULL","field4":"91776","field5":"NULL","field6":"626-443-2297","field7":"<a href=\"http://www.usgvmwd.org\" target='_blank' class=''>Go to Site</a>","field8":"626-443-2297","field9":"Approved"},
{"field1":"699","field2":"Upper San Gabriel Valley Municipal Water District","field3":"NULL","field4":"91732","field5":"NULL","field6":"626-443-2297","field7":"<a href=\"http://www.usgvmwd.org\" target='_blank' class=''>Go to Site</a>","field8":"626-443-2297","field9":"Approved"},
{"field1":"700","field2":"Upper San Gabriel Valley Municipal Water District","field3":"NULL","field4":"91731","field5":"NULL","field6":"626-443-2297","field7":"<a href=\"http://www.usgvmwd.org\" target='_blank' class=''>Go to Site</a>","field8":"626-443-2297","field9":"Approved"},
{"field1":"701","field2":"Upper San Gabriel Valley Municipal Water District","field3":"NULL","field4":"91770","field5":"NULL","field6":"626-443-2297","field7":"<a href=\"http://www.usgvmwd.org\" target='_blank' class=''>Go to Site</a>","field8":"626-443-2297","field9":"Approved"},
{"field1":"702","field2":"Upper San Gabriel Valley Municipal Water District","field3":"NULL","field4":"91723","field5":"NULL","field6":"626-443-2297","field7":"<a href=\"http://www.usgvmwd.org\" target='_blank' class=''>Go to Site</a>","field8":"626-443-2297","field9":"Approved"},
{"field1":"703","field2":"Upper San Gabriel Valley Municipal Water District","field3":"NULL","field4":"91790","field5":"NULL","field6":"626-443-2297","field7":"<a href=\"http://www.usgvmwd.org\" target='_blank' class=''>Go to Site</a>","field8":"626-443-2297","field9":"Approved"},
{"field1":"704","field2":"Upper San Gabriel Valley Municipal Water District","field3":"NULL","field4":"91791","field5":"NULL","field6":"626-443-2297","field7":"<a href=\"http://www.usgvmwd.org\" target='_blank' class=''>Go to Site</a>","field8":"626-443-2297","field9":"Approved"},
{"field1":"705","field2":"Upper San Gabriel Valley Municipal Water District","field3":"NULL","field4":"91733","field5":"NULL","field6":"626-443-2297","field7":"<a href=\"http://www.usgvmwd.org\" target='_blank' class=''>Go to Site</a>","field8":"626-443-2297","field9":"Approved"},
{"field1":"706","field2":"Upper San Gabriel Valley Municipal Water District","field3":"NULL","field4":"91744","field5":"NULL","field6":"626-443-2297","field7":"<a href=\"http://www.usgvmwd.org\" target='_blank' class=''>Go to Site</a>","field8":"626-443-2297","field9":"Approved"},
{"field1":"707","field2":"Upper San Gabriel Valley Municipal Water District","field3":"NULL","field4":"91792","field5":"NULL","field6":"626-443-2297","field7":"<a href=\"http://www.usgvmwd.org\" target='_blank' class=''>Go to Site</a>","field8":"626-443-2297","field9":"Approved"},
{"field1":"708","field2":"Upper San Gabriel Valley Municipal Water District","field3":"NULL","field4":"90660","field5":"NULL","field6":"626-443-2297","field7":"<a href=\"http://www.usgvmwd.org\" target='_blank' class=''>Go to Site</a>","field8":"626-443-2297","field9":"Approved"},
{"field1":"709","field2":"Upper San Gabriel Valley Municipal Water District","field3":"NULL","field4":"91745","field5":"NULL","field6":"626-443-2297","field7":"<a href=\"http://www.usgvmwd.org\" target='_blank' class=''>Go to Site</a>","field8":"626-443-2297","field9":"Approved"},
{"field1":"710","field2":"West Basin Municipal Water District","field3":"NULL","field4":"90293","field5":"NULL","field6":"310-217-2411","field7":"<a href=\"http://www.westbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2411","field9":"Approved"},
{"field1":"711","field2":"West Basin Municipal Water District","field3":"NULL","field4":"90066","field5":"NULL","field6":"310-217-2411","field7":"<a href=\"http://www.westbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2411","field9":"Approved"},
{"field1":"712","field2":"West Basin Municipal Water District","field3":"NULL","field4":"90025","field5":"NULL","field6":"310-217-2411","field7":"<a href=\"http://www.westbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2411","field9":"Approved"},
{"field1":"713","field2":"West Basin Municipal Water District","field3":"NULL","field4":"90046","field5":"NULL","field6":"310-217-2411","field7":"<a href=\"http://www.westbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2411","field9":"Approved"},
{"field1":"714","field2":"West Basin Municipal Water District","field3":"NULL","field4":"90710","field5":"NULL","field6":"310-217-2411","field7":"<a href=\"http://www.westbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2411","field9":"Approved"},
{"field1":"715","field2":"West Basin Municipal Water District","field3":"NULL","field4":"90048","field5":"NULL","field6":"310-217-2411","field7":"<a href=\"http://www.westbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2411","field9":"Approved"},
{"field1":"716","field2":"West Basin Municipal Water District","field3":"NULL","field4":"90503","field5":"NULL","field6":"310-217-2411","field7":"<a href=\"http://www.westbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2411","field9":"Approved"},
{"field1":"717","field2":"West Basin Municipal Water District","field3":"NULL","field4":"90732","field5":"NULL","field6":"310-217-2411","field7":"<a href=\"http://www.westbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2411","field9":"Approved"},
{"field1":"718","field2":"West Basin Municipal Water District","field3":"NULL","field4":"90220","field5":"NULL","field6":"310-217-2411","field7":"<a href=\"http://www.westbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2411","field9":"Approved"},
{"field1":"719","field2":"West Basin Municipal Water District","field3":"NULL","field4":"90221","field5":"NULL","field6":"310-217-2411","field7":"<a href=\"http://www.westbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2411","field9":"Approved"},
{"field1":"720","field2":"West Basin Municipal Water District","field3":"NULL","field4":"90265","field5":"NULL","field6":"310-217-2411","field7":"<a href=\"http://www.westbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2411","field9":"Approved"},
{"field1":"721","field2":"West Basin Municipal Water District","field3":"NULL","field4":"90047","field5":"NULL","field6":"310-217-2411","field7":"<a href=\"http://www.westbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2411","field9":"Approved"},
{"field1":"722","field2":"West Basin Municipal Water District","field3":"NULL","field4":"90008","field5":"NULL","field6":"310-217-2411","field7":"<a href=\"http://www.westbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2411","field9":"Approved"},
{"field1":"723","field2":"West Basin Municipal Water District","field3":"NULL","field4":"90044","field5":"NULL","field6":"310-217-2411","field7":"<a href=\"http://www.westbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2411","field9":"Approved"},
{"field1":"724","field2":"West Basin Municipal Water District","field3":"NULL","field4":"90043","field5":"NULL","field6":"310-217-2411","field7":"<a href=\"http://www.westbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2411","field9":"Approved"},
{"field1":"725","field2":"West Basin Municipal Water District","field3":"NULL","field4":"90502","field5":"NULL","field6":"310-217-2411","field7":"<a href=\"http://www.westbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2411","field9":"Approved"},
{"field1":"726","field2":"West Basin Municipal Water District","field3":"NULL","field4":"90056","field5":"NULL","field6":"310-217-2411","field7":"<a href=\"http://www.westbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2411","field9":"Approved"},
{"field1":"727","field2":"West Basin Municipal Water District","field3":"NULL","field4":"90230","field5":"NULL","field6":"310-217-2411","field7":"<a href=\"http://www.westbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2411","field9":"Approved"},
{"field1":"728","field2":"West Basin Municipal Water District","field3":"NULL","field4":"90069","field5":"NULL","field6":"310-217-2411","field7":"<a href=\"http://www.westbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2411","field9":"Approved"},
{"field1":"729","field2":"West Basin Municipal Water District","field3":"NULL","field4":"90304","field5":"NULL","field6":"310-217-2411","field7":"<a href=\"http://www.westbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2411","field9":"Approved"},
{"field1":"730","field2":"West Basin Municipal Water District","field3":"NULL","field4":"90810","field5":"NULL","field6":"310-217-2411","field7":"<a href=\"http://www.westbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2411","field9":"Approved"},
{"field1":"731","field2":"West Basin Municipal Water District","field3":"NULL","field4":"90303","field5":"NULL","field6":"310-217-2411","field7":"<a href=\"http://www.westbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2411","field9":"Approved"},
{"field1":"732","field2":"West Basin Municipal Water District","field3":"NULL","field4":"90250","field5":"NULL","field6":"310-217-2411","field7":"<a href=\"http://www.westbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2411","field9":"Approved"},
{"field1":"733","field2":"West Basin Municipal Water District","field3":"NULL","field4":"90248","field5":"NULL","field6":"310-217-2411","field7":"<a href=\"http://www.westbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2411","field9":"Approved"},
{"field1":"734","field2":"West Basin Municipal Water District","field3":"NULL","field4":"90247","field5":"NULL","field6":"310-217-2411","field7":"<a href=\"http://www.westbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2411","field9":"Approved"},
{"field1":"735","field2":"West Basin Municipal Water District","field3":"NULL","field4":"90277","field5":"NULL","field6":"310-217-2411","field7":"<a href=\"http://www.westbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2411","field9":"Approved"},
{"field1":"736","field2":"West Basin Municipal Water District","field3":"NULL","field4":"90292","field5":"NULL","field6":"310-217-2411","field7":"<a href=\"http://www.westbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2411","field9":"Approved"},
{"field1":"737","field2":"West Basin Municipal Water District","field3":"NULL","field4":"90746","field5":"NULL","field6":"310-217-2411","field7":"<a href=\"http://www.westbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2411","field9":"Approved"},
{"field1":"738","field2":"West Basin Municipal Water District","field3":"NULL","field4":"90232","field5":"NULL","field6":"310-217-2411","field7":"<a href=\"http://www.westbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2411","field9":"Approved"},
{"field1":"739","field2":"West Basin Municipal Water District","field3":"NULL","field4":"90717","field5":"NULL","field6":"310-217-2411","field7":"<a href=\"http://www.westbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2411","field9":"Approved"},
{"field1":"740","field2":"West Basin Municipal Water District","field3":"NULL","field4":"90290","field5":"NULL","field6":"310-217-2411","field7":"<a href=\"http://www.westbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2411","field9":"Approved"},
{"field1":"741","field2":"West Basin Municipal Water District","field3":"NULL","field4":"90274","field5":"NULL","field6":"310-217-2411","field7":"<a href=\"http://www.westbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2411","field9":"Approved"},
{"field1":"742","field2":"West Basin Municipal Water District","field3":"NULL","field4":"90275","field5":"NULL","field6":"310-217-2411","field7":"<a href=\"http://www.westbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2411","field9":"Approved"},
{"field1":"743","field2":"West Basin Municipal Water District","field3":"NULL","field4":"90245","field5":"NULL","field6":"310-217-2411","field7":"<a href=\"http://www.westbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2411","field9":"Approved"},
{"field1":"744","field2":"West Basin Municipal Water District","field3":"NULL","field4":"90260","field5":"NULL","field6":"310-217-2411","field7":"<a href=\"http://www.westbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2411","field9":"Approved"},
{"field1":"745","field2":"West Basin Municipal Water District","field3":"NULL","field4":"90278","field5":"NULL","field6":"310-217-2411","field7":"<a href=\"http://www.westbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2411","field9":"Approved"},
{"field1":"746","field2":"West Basin Municipal Water District","field3":"NULL","field4":"90305","field5":"NULL","field6":"310-217-2411","field7":"<a href=\"http://www.westbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2411","field9":"Approved"},
{"field1":"747","field2":"West Basin Municipal Water District","field3":"NULL","field4":"90266","field5":"NULL","field6":"310-217-2411","field7":"<a href=\"http://www.westbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2411","field9":"Approved"},
{"field1":"748","field2":"West Basin Municipal Water District","field3":"NULL","field4":"90249","field5":"NULL","field6":"310-217-2411","field7":"<a href=\"http://www.westbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2411","field9":"Approved"},
{"field1":"749","field2":"West Basin Municipal Water District","field3":"NULL","field4":"90301","field5":"NULL","field6":"310-217-2411","field7":"<a href=\"http://www.westbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2411","field9":"Approved"},
{"field1":"750","field2":"West Basin Municipal Water District","field3":"NULL","field4":"90302","field5":"NULL","field6":"310-217-2411","field7":"<a href=\"http://www.westbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2411","field9":"Approved"},
{"field1":"751","field2":"West Basin Municipal Water District","field3":"NULL","field4":"90745","field5":"NULL","field6":"310-217-2411","field7":"<a href=\"http://www.westbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2411","field9":"Approved"},
{"field1":"752","field2":"West Basin Municipal Water District","field3":"NULL","field4":"90254","field5":"NULL","field6":"310-217-2411","field7":"<a href=\"http://www.westbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2411","field9":"Approved"},
{"field1":"753","field2":"West Basin Municipal Water District","field3":"NULL","field4":"90261","field5":"NULL","field6":"310-217-2411","field7":"<a href=\"http://www.westbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2411","field9":"Approved"},
{"field1":"754","field2":"West Basin Municipal Water District","field3":"NULL","field4":"90263","field5":"NULL","field6":"310-217-2411","field7":"<a href=\"http://www.westbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2411","field9":"Approved"},
{"field1":"755","field2":"Western Municipal Water District of Riverside County","field3":"NULL","field4":"91719","field5":"NULL","field6":"951-789-5000","field7":"<a href=\"http://www.wmwd.com\" target='_blank' class=''>Go to Site</a>","field8":"951-789-5000","field9":"Approved"},
{"field1":"756","field2":"Western Municipal Water District of Riverside County","field3":"NULL","field4":"91752","field5":"NULL","field6":"951-789-5000","field7":"<a href=\"http://www.wmwd.com\" target='_blank' class=''>Go to Site</a>","field8":"951-789-5000","field9":"Approved"},
{"field1":"757","field2":"Western Municipal Water District of Riverside County","field3":"NULL","field4":"92501","field5":"NULL","field6":"951-789-5000","field7":"<a href=\"http://www.wmwd.com\" target='_blank' class=''>Go to Site</a>","field8":"951-789-5000","field9":"Approved"},
{"field1":"758","field2":"Western Municipal Water District of Riverside County","field3":"NULL","field4":"92502","field5":"NULL","field6":"951-789-5000","field7":"<a href=\"http://www.wmwd.com\" target='_blank' class=''>Go to Site</a>","field8":"951-789-5000","field9":"Approved"},
{"field1":"759","field2":"Western Municipal Water District of Riverside County","field3":"NULL","field4":"92507","field5":"NULL","field6":"951-789-5000","field7":"<a href=\"http://www.wmwd.com\" target='_blank' class=''>Go to Site</a>","field8":"951-789-5000","field9":"Approved"},
{"field1":"760","field2":"Western Municipal Water District of Riverside County","field3":"NULL","field4":"92513","field5":"NULL","field6":"951-789-5000","field7":"<a href=\"http://www.wmwd.com\" target='_blank' class=''>Go to Site</a>","field8":"951-789-5000","field9":"Approved"},
{"field1":"761","field2":"Western Municipal Water District of Riverside County","field3":"NULL","field4":"92514","field5":"NULL","field6":"951-789-5000","field7":"<a href=\"http://www.wmwd.com\" target='_blank' class=''>Go to Site</a>","field8":"951-789-5000","field9":"Approved"},
{"field1":"762","field2":"Western Municipal Water District of Riverside County","field3":"NULL","field4":"92515","field5":"NULL","field6":"951-789-5000","field7":"<a href=\"http://www.wmwd.com\" target='_blank' class=''>Go to Site</a>","field8":"951-789-5000","field9":"Approved"},
{"field1":"763","field2":"Western Municipal Water District of Riverside County","field3":"NULL","field4":"92516","field5":"NULL","field6":"951-789-5000","field7":"<a href=\"http://www.wmwd.com\" target='_blank' class=''>Go to Site</a>","field8":"951-789-5000","field9":"Approved"},
{"field1":"764","field2":"Western Municipal Water District of Riverside County","field3":"NULL","field4":"92517","field5":"NULL","field6":"951-789-5000","field7":"<a href=\"http://www.wmwd.com\" target='_blank' class=''>Go to Site</a>","field8":"951-789-5000","field9":"Approved"},
{"field1":"765","field2":"Western Municipal Water District of Riverside County","field3":"NULL","field4":"92519","field5":"NULL","field6":"951-789-5000","field7":"<a href=\"http://www.wmwd.com\" target='_blank' class=''>Go to Site</a>","field8":"951-789-5000","field9":"Approved"},
{"field1":"766","field2":"Western Municipal Water District of Riverside County","field3":"NULL","field4":"92521","field5":"NULL","field6":"951-789-5000","field7":"<a href=\"http://www.wmwd.com\" target='_blank' class=''>Go to Site</a>","field8":"951-789-5000","field9":"Approved"},
{"field1":"767","field2":"Western Municipal Water District of Riverside County","field3":"NULL","field4":"92522","field5":"NULL","field6":"951-789-5000","field7":"<a href=\"http://www.wmwd.com\" target='_blank' class=''>Go to Site</a>","field8":"951-789-5000","field9":"Approved"},
{"field1":"768","field2":"Western Municipal Water District of Riverside County","field3":"NULL","field4":"92531","field5":"NULL","field6":"951-789-5000","field7":"<a href=\"http://www.wmwd.com\" target='_blank' class=''>Go to Site</a>","field8":"951-789-5000","field9":"Approved"},
{"field1":"770","field2":"Western Municipal Water District of Riverside County","field3":"NULL","field4":"92532","field5":"NULL","field6":"951-789-5000","field7":"<a href=\"http://www.wmwd.com\" target='_blank' class=''>Go to Site</a>","field8":"951-789-5000","field9":"Approved"},
{"field1":"771","field2":"Western Municipal Water District of Riverside County","field3":"NULL","field4":"92562","field5":"NULL","field6":"951-789-5000","field7":"<a href=\"http://www.wmwd.com\" target='_blank' class=''>Go to Site</a>","field8":"951-789-5000","field9":"Approved"},
{"field1":"772","field2":"Western Municipal Water District of Riverside County","field3":"NULL","field4":"92563","field5":"NULL","field6":"951-789-5000","field7":"<a href=\"http://www.wmwd.com\" target='_blank' class=''>Go to Site</a>","field8":"951-789-5000","field9":"Approved"},
{"field1":"774","field2":"Western Municipal Water District of Riverside County","field3":"NULL","field4":"92564","field5":"NULL","field6":"951-789-5000","field7":"<a href=\"http://www.wmwd.com\" target='_blank' class=''>Go to Site</a>","field8":"951-789-5000","field9":"Approved"},
{"field1":"775","field2":"Western Municipal Water District of Riverside County","field3":"NULL","field4":"92587","field5":"NULL","field6":"951-789-5000","field7":"<a href=\"http://www.wmwd.com\" target='_blank' class=''>Go to Site</a>","field8":"951-789-5000","field9":"Approved"},
{"field1":"776","field2":"Western Municipal Water District of Riverside County","field3":"NULL","field4":"92589","field5":"NULL","field6":"951-789-5000","field7":"<a href=\"http://www.wmwd.com\" target='_blank' class=''>Go to Site</a>","field8":"951-789-5000","field9":"Approved"},
{"field1":"777","field2":"Western Municipal Water District of Riverside County","field3":"NULL","field4":"92591","field5":"NULL","field6":"951-789-5000","field7":"<a href=\"http://www.wmwd.com\" target='_blank' class=''>Go to Site</a>","field8":"951-789-5000","field9":"Approved"},
{"field1":"778","field2":"Western Municipal Water District of Riverside County","field3":"NULL","field4":"92592","field5":"NULL","field6":"951-789-5000","field7":"<a href=\"http://www.wmwd.com\" target='_blank' class=''>Go to Site</a>","field8":"951-789-5000","field9":"Approved"},
{"field1":"779","field2":"Western Municipal Water District of Riverside County","field3":"NULL","field4":"92593","field5":"NULL","field6":"951-789-5000","field7":"<a href=\"http://www.wmwd.com\" target='_blank' class=''>Go to Site</a>","field8":"951-789-5000","field9":"Approved"},
{"field1":"780","field2":"Western Municipal Water District of Riverside County","field3":"NULL","field4":"92860","field5":"NULL","field6":"951-789-5000","field7":"<a href=\"http://www.wmwd.com\" target='_blank' class=''>Go to Site</a>","field8":"951-789-5000","field9":"Approved"},
{"field1":"781","field2":"Western Municipal Water District of Riverside County","field3":"NULL","field4":"92877","field5":"NULL","field6":"951-789-5000","field7":"<a href=\"http://www.wmwd.com\" target='_blank' class=''>Go to Site</a>","field8":"951-789-5000","field9":"Approved"},
{"field1":"782","field2":"Western Municipal Water District of Riverside County","field3":"NULL","field4":"92878","field5":"NULL","field6":"951-789-5000","field7":"<a href=\"http://www.wmwd.com\" target='_blank' class=''>Go to Site</a>","field8":"951-789-5000","field9":"Approved"},
{"field1":"783","field2":"Western Municipal Water District of Riverside County","field3":"NULL","field4":"92880","field5":"NULL","field6":"951-789-5000","field7":"<a href=\"http://www.wmwd.com\" target='_blank' class=''>Go to Site</a>","field8":"951-789-5000","field9":"Approved"},
{"field1":"784","field2":"Western Municipal Water District of Riverside County","field3":"NULL","field4":"92881","field5":"NULL","field6":"951-789-5000","field7":"<a href=\"http://www.wmwd.com\" target='_blank' class=''>Go to Site</a>","field8":"951-789-5000","field9":"Approved"},
{"field1":"785","field2":"Western Municipal Water District of Riverside County","field3":"NULL","field4":"92882","field5":"NULL","field6":"951-789-5000","field7":"<a href=\"http://www.wmwd.com\" target='_blank' class=''>Go to Site</a>","field8":"951-789-5000","field9":"Approved"},
{"field1":"786","field2":"Western Municipal Water District of Riverside County","field3":"NULL","field4":"92883","field5":"NULL","field6":"951-789-5000","field7":"<a href=\"http://www.wmwd.com\" target='_blank' class=''>Go to Site</a>","field8":"951-789-5000","field9":"Approved"},
{"field1":"789","field2":"Western Municipal Water District of Riverside County","field3":"NULL","field4":"92503","field5":"NULL","field6":"951-789-5000","field7":"<a href=\"http://www.wmwd.com\" target='_blank' class=''>Go to Site</a>","field8":"951-789-5000","field9":"Approved"},
{"field1":"790","field2":"Western Municipal Water District of Riverside County","field3":"NULL","field4":"92504","field5":"NULL","field6":"951-789-5000","field7":"<a href=\"http://www.wmwd.com\" target='_blank' class=''>Go to Site</a>","field8":"951-789-5000","field9":"Approved"},
{"field1":"791","field2":"Western Municipal Water District of Riverside County","field3":"NULL","field4":"92505","field5":"NULL","field6":"951-789-5000","field7":"<a href=\"http://www.wmwd.com\" target='_blank' class=''>Go to Site</a>","field8":"951-789-5000","field9":"Approved"},
{"field1":"792","field2":"Western Municipal Water District of Riverside County","field3":"NULL","field4":"92506","field5":"NULL","field6":"951-789-5000","field7":"<a href=\"http://www.wmwd.com\" target='_blank' class=''>Go to Site</a>","field8":"951-789-5000","field9":"Approved"},
{"field1":"794","field2":"Western Municipal Water District of Riverside County","field3":"NULL","field4":"92508","field5":"NULL","field6":"951-789-5000","field7":"<a href=\"http://www.wmwd.com\" target='_blank' class=''>Go to Site</a>","field8":"951-789-5000","field9":"Approved"},
{"field1":"795","field2":"Western Municipal Water District of Riverside County","field3":"NULL","field4":"92509","field5":"NULL","field6":"951-789-5000","field7":"<a href=\"http://www.wmwd.com\" target='_blank' class=''>Go to Site</a>","field8":"951-789-5000","field9":"Approved"},
{"field1":"796","field2":"Western Municipal Water District of Riverside County","field3":"NULL","field4":"92518","field5":"NULL","field6":"951-789-5000","field7":"<a href=\"http://www.wmwd.com\" target='_blank' class=''>Go to Site</a>","field8":"951-789-5000","field9":"Approved"},
{"field1":"797","field2":"Western Municipal Water District of Riverside County","field3":"NULL","field4":"92530","field5":"NULL","field6":"951-789-5000","field7":"<a href=\"http://www.wmwd.com\" target='_blank' class=''>Go to Site</a>","field8":"951-789-5000","field9":"Approved"},
{"field1":"799","field2":"Western Municipal Water District of Riverside County","field3":"NULL","field4":"92553","field5":"NULL","field6":"951-789-5000","field7":"<a href=\"http://www.wmwd.com\" target='_blank' class=''>Go to Site</a>","field8":"951-789-5000","field9":"Approved"},
{"field1":"801","field2":"Western Municipal Water District of Riverside County","field3":"NULL","field4":"92570","field5":"NULL","field6":"951-789-5000","field7":"<a href=\"http://www.wmwd.com\" target='_blank' class=''>Go to Site</a>","field8":"951-789-5000","field9":"Approved"},
{"field1":"802","field2":"Western Municipal Water District of Riverside County","field3":"NULL","field4":"92571","field5":"NULL","field6":"951-789-5000","field7":"<a href=\"http://www.wmwd.com\" target='_blank' class=''>Go to Site</a>","field8":"951-789-5000","field9":"Approved"},
{"field1":"804","field2":"Western Municipal Water District of Riverside County","field3":"NULL","field4":"92590","field5":"NULL","field6":"951-789-5000","field7":"<a href=\"http://www.wmwd.com\" target='_blank' class=''>Go to Site</a>","field8":"951-789-5000","field9":"Approved"},
{"field1":"806","field2":"Western Municipal Water District of Riverside County","field3":"NULL","field4":"92595","field5":"NULL","field6":"951-789-5000","field7":"<a href=\"http://www.wmwd.com\" target='_blank' class=''>Go to Site</a>","field8":"951-789-5000","field9":"Approved"},
{"field1":"808","field2":"Western Municipal Water District of Riverside County","field3":"NULL","field4":"92879","field5":"NULL","field6":"951-789-5000","field7":"<a href=\"http://www.wmwd.com\" target='_blank' class=''>Go to Site</a>","field8":"951-789-5000","field9":"Approved"},
{"field1":"813","field2":"City of Corona","field3":"City of Corona","field4":"92877","field5":"NULL","field6":"951-279-3768","field7":"<a href=\"http://www.discovercorona.com/index.cfm\" target='_blank' class=''>Go to Site</a>","field8":"951-279-3768","field9":"Approved"},
{"field1":"814","field2":"City of Corona","field3":"City of Corona","field4":"92878","field5":"NULL","field6":"951-279-3768","field7":"<a href=\"http://www.discovercorona.com/index.cfm\" target='_blank' class=''>Go to Site</a>","field8":"951-279-3768","field9":"Approved"},
{"field1":"815","field2":"City of Corona","field3":"City of Corona","field4":"92879","field5":"NULL","field6":"951-279-3768","field7":"<a href=\"http://www.discovercorona.com/index.cfm\" target='_blank' class=''>Go to Site</a>","field8":"951-279-3768","field9":"Approved"},
{"field1":"816","field2":"City of Corona","field3":"City of Corona","field4":"92880","field5":"NULL","field6":"951-279-3768","field7":"<a href=\"http://www.discovercorona.com/index.cfm\" target='_blank' class=''>Go to Site</a>","field8":"951-279-3768","field9":"Approved"},
{"field1":"817","field2":"City of Corona","field3":"City of Corona","field4":"92881","field5":"NULL","field6":"951-279-3768","field7":"<a href=\"http://www.discovercorona.com/index.cfm\" target='_blank' class=''>Go to Site</a>","field8":"951-279-3768","field9":"Approved"},
{"field1":"818","field2":"City of Corona","field3":"City of Corona","field4":"92882","field5":"NULL","field6":"951-279-3768","field7":"<a href=\"http://www.discovercorona.com/index.cfm\" target='_blank' class=''>Go to Site</a>","field8":"951-279-3768","field9":"Approved"},
{"field1":"819","field2":"City of Corona","field3":"City of Corona","field4":"92883","field5":"NULL","field6":"951-279-3768","field7":"<a href=\"http://www.discovercorona.com/index.cfm\" target='_blank' class=''>Go to Site</a>","field8":"951-279-3768","field9":"Approved"},
{"field1":"820","field2":"City of Riverside","field3":"City of Riverside","field4":"92501","field5":"NULL","field6":"951-826-5485","field7":"<a href=\"http://www.riversideca.gov/utilities/\" target='_blank' class=''>Go to Site</a>","field8":"951-826-5485","field9":"Approved"},
{"field1":"821","field2":"City of Riverside","field3":"City of Riverside","field4":"92502","field5":"NULL","field6":"951-826-5485","field7":"<a href=\"http://www.riversideca.gov/utilities/\" target='_blank' class=''>Go to Site</a>","field8":"951-826-5485","field9":"Approved"},
{"field1":"822","field2":"City of Riverside","field3":"City of Riverside","field4":"92503","field5":"NULL","field6":"951-826-5485","field7":"<a href=\"http://www.riversideca.gov/utilities/\" target='_blank' class=''>Go to Site</a>","field8":"951-826-5485","field9":"Approved"},
{"field1":"823","field2":"City of Riverside","field3":"City of Riverside","field4":"92504","field5":"NULL","field6":"951-826-5485","field7":"<a href=\"http://www.riversideca.gov/utilities/\" target='_blank' class=''>Go to Site</a>","field8":"951-826-5485","field9":"Approved"},
{"field1":"824","field2":"City of Riverside","field3":"City of Riverside","field4":"92505","field5":"NULL","field6":"951-826-5485","field7":"<a href=\"http://www.riversideca.gov/utilities/\" target='_blank' class=''>Go to Site</a>","field8":"951-826-5485","field9":"Approved"},
{"field1":"825","field2":"City of Riverside","field3":"City of Riverside","field4":"92506","field5":"NULL","field6":"951-826-5485","field7":"<a href=\"http://www.riversideca.gov/utilities/\" target='_blank' class=''>Go to Site</a>","field8":"951-826-5485","field9":"Approved"},
{"field1":"826","field2":"City of Riverside","field3":"City of Riverside","field4":"92507","field5":"NULL","field6":"951-826-5485","field7":"<a href=\"http://www.riversideca.gov/utilities/\" target='_blank' class=''>Go to Site</a>","field8":"951-826-5485","field9":"Approved"},
{"field1":"827","field2":"City of Riverside","field3":"City of Riverside","field4":"92508","field5":"NULL","field6":"951-826-5485","field7":"<a href=\"http://www.riversideca.gov/utilities/\" target='_blank' class=''>Go to Site</a>","field8":"951-826-5485","field9":"Approved"},
{"field1":"828","field2":"City of Riverside","field3":"City of Riverside","field4":"92509","field5":"NULL","field6":"951-826-5485","field7":"<a href=\"http://www.riversideca.gov/utilities/\" target='_blank' class=''>Go to Site</a>","field8":"951-826-5485","field9":"Approved"},
{"field1":"829","field2":"City of Riverside","field3":"City of Riverside","field4":"92518","field5":"NULL","field6":"951-826-5485","field7":"<a href=\"http://www.riversideca.gov/utilities/\" target='_blank' class=''>Go to Site</a>","field8":"951-826-5485","field9":"Approved"},
{"field1":"830","field2":"Jurupa Community Services District","field3":"NULL","field4":"92509","field5":"NULL","field6":"951-685-7434","field7":"<a href=\"http://www.jcsd.us\" target='_blank' class=''>Go to Site</a>","field8":"951-685-7434","field9":"Approved"},
{"field1":"831","field2":"City of Norco","field3":"City of Norco","field4":"92860","field5":"NULL","field6":"951-270-5607","field7":"<a href=\"http://www.ci.norco.ca.us\" target='_blank' class=''>Go to Site</a>","field8":"951-270-5607","field9":"Approved"},
{"field1":"832","field2":"Rubidoux Community Services District","field3":"NULL","field4":"92509","field5":"NULL","field6":"951-684-7580","field7":"<a href=\"http://www.rcsd.org/\" target='_blank' class=''>Go to Site</a>","field8":"951-684-7580","field9":"Approved"},
{"field1":"833","field2":"Rubidoux Community Services District","field3":"NULL","field4":"92519","field5":"NULL","field6":"951-684-7580","field7":"<a href=\"http://www.rcsd.org/\" target='_blank' class=''>Go to Site</a>","field8":"951-684-7580","field9":"Approved"},
{"field1":"834","field2":"Rancho California Water District","field3":"NULL","field4":"92589","field5":"NULL","field6":"951-296-6900","field7":"<a href=\"http://www.ranchowater.com\" target='_blank' class=''>Go to Site</a>","field8":"951-296-6900","field9":"Approved"},
{"field1":"835","field2":"Rancho California Water District","field3":"NULL","field4":"92590","field5":"NULL","field6":"951-296-6900","field7":"<a href=\"http://www.ranchowater.com\" target='_blank' class=''>Go to Site</a>","field8":"951-296-6900","field9":"Approved"},
{"field1":"836","field2":"Rancho California Water District","field3":"NULL","field4":"92591","field5":"NULL","field6":"951-296-6900","field7":"<a href=\"http://www.ranchowater.com\" target='_blank' class=''>Go to Site</a>","field8":"951-296-6900","field9":"Approved"},
{"field1":"837","field2":"Rancho California Water District","field3":"NULL","field4":"92592","field5":"NULL","field6":"951-296-6900","field7":"<a href=\"http://www.ranchowater.com\" target='_blank' class=''>Go to Site</a>","field8":"951-296-6900","field9":"Approved"},
{"field1":"838","field2":"Rancho California Water District","field3":"NULL","field4":"92593","field5":"NULL","field6":"951-296-6900","field7":"<a href=\"http://www.ranchowater.com\" target='_blank' class=''>Go to Site</a>","field8":"951-296-6900","field9":"Approved"},
{"field1":"839","field2":"Rancho California Water District","field3":"NULL","field4":"92562","field5":"NULL","field6":"951-296-6900","field7":"<a href=\"http://www.ranchowater.com\" target='_blank' class=''>Go to Site</a>","field8":"951-296-6900","field9":"Approved"},
{"field1":"840","field2":"Rancho California Water District","field3":"NULL","field4":"92563","field5":"NULL","field6":"951-296-6900","field7":"<a href=\"http://www.ranchowater.com\" target='_blank' class=''>Go to Site</a>","field8":"951-296-6900","field9":"Approved"},
{"field1":"841","field2":"Elsinore Valley Municipal Water District","field3":"NULL","field4":"92530","field5":"NULL","field6":"951-674-3146","field7":"<a href=\"http://www.evmwd.com\" target='_blank' class=''>Go to Site</a>","field8":"951-674-3146","field9":"Approved"},
{"field1":"842","field2":"Elsinore Valley Municipal Water District","field3":"NULL","field4":"92531","field5":"NULL","field6":"951-674-3146","field7":"<a href=\"http://www.evmwd.com\" target='_blank' class=''>Go to Site</a>","field8":"951-674-3146","field9":"Approved"},
{"field1":"843","field2":"Elsinore Valley Municipal Water District","field3":"NULL","field4":"92532","field5":"NULL","field6":"951-674-3146","field7":"<a href=\"http://www.evmwd.com\" target='_blank' class=''>Go to Site</a>","field8":"951-674-3146","field9":"Approved"},
{"field1":"844","field2":"Upper San Gabriel Valley Municipal Water District","field3":"NULL","field4":"91016","field5":"NULL","field6":"626-443-2297","field7":"<a href=\"http://www.usgvmwd.org\" target='_blank' class=''>Go to Site</a>","field8":"626-443-2297","field9":"Approved"},
{"field1":"845","field2":"Upper San Gabriel Valley Municipal Water District","field3":"NULL","field4":"91017","field5":"NULL","field6":"626-443-2297","field7":"<a href=\"http://www.usgvmwd.org\" target='_blank' class=''>Go to Site</a>","field8":"626-443-2297","field9":"Approved"},
{"field1":"846","field2":"Central Basin Municipal Water District","field3":"NULL","field4":"90755","field5":"NULL","field6":"310-217-2222","field7":"<a href=\"http://www.centralbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2222","field9":"Approved"},
{"field1":"847","field2":"City of Thousand Oaks","field3":"NULL","field4":"91362","field5":"NULL","field6":"888-237-1333","field7":"<a href=\"http://www.ci.thousand-oaks.ca.us/city_hall/default.asp\" target='_blank' class=''>Go to Site</a>","field8":"888-237-1333","field9":"Approved"},
{"field1":"848","field2":"Cal American Water Company","field3":"Cal American Water","field4":"91320","field5":"NULL","field6":"888-237-1333","field7":"<a href=\"http://www.calamwater.com/awpr1/caaw/default.html\" target='_blank' class=''>Go to Site</a>","field8":"","field9":"Approved"},
{"field1":"849","field2":"California Water Service Company","field3":"California Water Service Company","field4":"91320","field5":"NULL","field6":"888-237-1333","field7":"<a href=\"http://www.calwater.com/\" target='_blank' class=''>Go to Site</a>","field8":"","field9":"Approved"},
{"field1":"850","field2":"Cal American Water Company","field3":"Cal American Water","field4":"91360","field5":"NULL","field6":"888-237-1333","field7":"<a href=\"http://www.calamwater.com/awpr1/caaw/default.html\" target='_blank' class=''>Go to Site</a>","field8":"","field9":"Approved"},
{"field1":"851","field2":"California Water Service Company","field3":"California Water Service Company","field4":"91360","field5":"NULL","field6":"888-237-1333","field7":"<a href=\"http://www.calwater.com/\" target='_blank' class=''>Go to Site</a>","field8":"","field9":"Approved"},
{"field1":"852","field2":"Cal American Water Company","field3":"Cal American Water","field4":"91361","field5":"NULL","field6":"888-237-1333","field7":"<a href=\"http://www.calamwater.com/awpr1/caaw/default.html\" target='_blank' class=''>Go to Site</a>","field8":"","field9":"Approved"},
{"field1":"853","field2":"California Water Service Company","field3":"California Water Service Company","field4":"91361","field5":"NULL","field6":"888-237-1333","field7":"<a href=\"http://www.calwater.com/\" target='_blank' class=''>Go to Site</a>","field8":"","field9":"Approved"},
{"field1":"854","field2":"Cal American Water Company","field3":"Cal American Water","field4":"91362","field5":"NULL","field6":"888-237-1333","field7":"<a href=\"http://www.calamwater.com/awpr1/caaw/default.html\" target='_blank' class=''>Go to Site</a>","field8":"","field9":"Approved"},
{"field1":"855","field2":"California Water Service Company","field3":"California Water Service Company","field4":"91362","field5":"NULL","field6":"888-237-1333","field7":"<a href=\"http://www.calwater.com/\" target='_blank' class=''>Go to Site</a>","field8":"","field9":"Approved"},
{"field1":"856","field2":"San Diego County Water Authority","field3":"City of San Marcos","field4":"92078","field5":"NULL","field6":"858) 522-6600","field7":"<a href=\"http://www.sdcwa.org/\" target='_blank' class=''>Go to Site</a>","field8":"","field9":"Approved"},
{"field1":"857","field2":"Calleguas Municipal Water District","field3":"NULL","field4":"93035","field5":"NULL","field6":"805-526-9323","field7":"<a href=\"http://www.calleguas.com\" target='_blank' class=''>Go to Site</a>","field8":"805-526-9323","field9":"Approved"},
{"field1":"859","field2":"Central Basin Municipal Water District","field3":"NULL","field4":"90608","field5":"NULL","field6":"310-217-2222","field7":"<a href=\"http://www.centralbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2222","field9":"Approved"},
{"field1":"860","field2":"Central Basin Municipal Water District","field3":"NULL","field4":"90639","field5":"NULL","field6":"310-217-2222","field7":"<a href=\"http://www.centralbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2222","field9":"Approved"},
{"field1":"861","field2":"West Basin Municipal Water District","field3":"NULL","field4":"90747","field5":"NULL","field6":"310-217-2411","field7":"<a href=\"http://www.westbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2411","field9":"Approved"},
{"field1":"862","field2":"City of Long Beach","field3":"City of Long Beach","field4":"90822","field5":"NULL","field6":"562-570-2300","field7":"<a href=\"http://www.lbwater.org\" target='_blank' class=''>Go to Site</a>","field8":"562-570-2300","field9":"Approved"},
{"field1":"863","field2":"City of Long Beach","field3":"City of Long Beach","field4":"90831","field5":"NULL","field6":"562-570-2300","field7":"<a href=\"http://www.lbwater.org\" target='_blank' class=''>Go to Site</a>","field8":"562-570-2300","field9":"Approved"},
{"field1":"864","field2":"City of Long Beach","field3":"City of Long Beach","field4":"90840","field5":"NULL","field6":"562-570-2300","field7":"<a href=\"http://www.lbwater.org\" target='_blank' class=''>Go to Site</a>","field8":"562-570-2300","field9":"Approved"},
{"field1":"865","field2":"City of Burbank","field3":"City of Burbank","field4":"91521","field5":"NULL","field6":"310-285-2436","field7":"<a href=\"http://www.burbankwaterandpower.com/\" target='_blank' class=''>Go to Site</a>","field8":"310-285-2436","field9":"Approved"},
{"field1":"866","field2":"City of Burbank","field3":"City of Burbank","field4":"91522","field5":"NULL","field6":"310-285-2436","field7":"<a href=\"http://www.burbankwaterandpower.com/\" target='_blank' class=''>Go to Site</a>","field8":"310-285-2436","field9":"Approved"},
{"field1":"867","field2":"City of Burbank","field3":"City of Burbank","field4":"91523","field5":"NULL","field6":"310-285-2436","field7":"<a href=\"http://www.burbankwaterandpower.com/\" target='_blank' class=''>Go to Site</a>","field8":"310-285-2436","field9":"Approved"},
{"field1":"868","field2":"City of Los Angeles","field3":"City of Los Angeles","field4":"91521","field5":"NULL","field6":"800-544-4498","field7":"<a href=\"http://www.ladwp.com\" target='_blank' class=''>Go to Site</a>","field8":"800-544-4498","field9":"Approved"},
{"field1":"869","field2":"Central Basin Municipal Water District","field3":"NULL","field4":"91755","field5":"NULL","field6":"310-217-2222","field7":"<a href=\"http://www.centralbasin.org\" target='_blank' class=''>Go to Site</a>","field8":"310-217-2222","field9":"Approved"},
{"field1":"870","field2":"Upper San Gabriel Valley Municipal Water District","field3":"NULL","field4":"91755","field5":"NULL","field6":"626-443-2297","field7":"<a href=\"http://www.usgvmwd.org\" target='_blank' class=''>Go to Site</a>","field8":"626-443-2297","field9":"Approved"},
{"field1":"871","field2":"Three Valleys Municipal Water District","field3":"NULL","field4":"91769","field5":"NULL","field6":"909-621-5568","field7":"<a href=\"http://www.threevalleys.com\" target='_blank' class=''>Go to Site</a>","field8":"909-621-5568","field9":"Approved"},
{"field1":"872","field2":"San Diego County Water Authority","field3":"NULL","field4":"92060","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"873","field2":"San Diego County Water Authority","field3":"NULL","field4":"92091","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"874","field2":"San Diego County Water Authority","field3":"NULL","field4":"92093","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"875","field2":"San Diego County Water Authority","field3":"NULL","field4":"92096","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"876","field2":"San Diego County Water Authority","field3":"NULL","field4":"92118","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"877","field2":"San Diego County Water Authority","field3":"NULL","field4":"92147","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"878","field2":"San Diego County Water Authority","field3":"NULL","field4":"92152","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"879","field2":"San Diego County Water Authority","field3":"NULL","field4":"92161","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"880","field2":"San Diego County Water Authority","field3":"NULL","field4":"92182","field5":"NULL","field6":"800-986-4538","field7":"<a href=\"http://www.sdcwa.org\" target='_blank' class=''>Go to Site</a>","field8":"800-986-4538","field9":"Approved"},
{"field1":"881","field2":"Inland Empire Utilities Agency","field3":"NULL","field4":"92376","field5":"NULL","field6":"909-993-1600","field7":"<a href=\"http://www.ieua.org\" target='_blank' class=''>Go to Site</a>","field8":"909-993-1600","field9":"Approved"},
{"field1":"882","field2":"Municipal Water District of Orange County","field3":"NULL","field4":"92637","field5":"NULL","field6":"714-593-5015","field7":"<a href=\"http://www.mwdoc.com\" target='_blank' class=''>Go to Site</a>","field8":"714-593-5015","field9":"Approved"},
{"field1":"883","field2":"Upper San Gabriel Valley Municipal Water District","field3":"NULL","field4":"91008","field5":"NULL","field6":"626-443-2297","field7":"<a href=\"http://www.usgvmwd.org\" target='_blank' class=''>Go to Site</a>","field8":"626-443-2297","field9":"Approved"},
{"field1":"884","field2":"City of Chino","field3":"Chino","field4":"91710","field5":"NULL","field6":"909 334-3250","field7":"<a href=\"https://www.cityofchino.org/\" target='_blank' class=''>Go to Site</a>","field8":"909 334-3250","field9":"Approved"},
{"field1":"885","field2":"City of Chino","field3":"Chino","field4":"92880","field5":"NULL","field6":"909 334-3250","field7":"<a href=\"https://www.cityofchino.org/\" target='_blank' class=''>Go to Site</a>","field8":"909 334-3250","field9":"Approved"},
{"field1":"886","field2":"City of Chino","field3":"City of Chino","field4":"91708","field5":"NULL","field6":"909 334-3250","field7":"<a href=\"https://www.cityofchino.org/\" target='_blank' class=''>Go to Site</a>","field8":"909 334-3250","field9":"Approved"},
{"field1":"887","field2":"City of Chino Hills","field3":"City of Chino Hills","field4":"91709","field5":"NULL","field6":"909-364-2600","field7":"<a href=\"https://www.chinohills.org/\" target='_blank' class=''>Go to Site</a>","field8":"909-364-2600","field9":"Approved"},
{"field1":"888","field2":"Cucamonga Valley Water District","field3":"NULL","field4":"91701","field5":"NULL","field6":"855-654-2893","field7":"<a href=\"https://www.cvwdwater.com/\" target='_blank' class=''>Go to Site</a>","field8":"855-654-2893","field9":"Approved"},
{"field1":"889","field2":"Cucamonga Valley Water District","field3":"NULL","field4":"91730","field5":"NULL","field6":"855-654-2893","field7":"<a href=\"https://www.cvwdwater.com/\" target='_blank' class=''>Go to Site</a>","field8":"855-654-2893","field9":"Approved"},
{"field1":"890","field2":"Cucamonga Valley Water District","field3":"NULL","field4":"91737","field5":"NULL","field6":"855-654-2893","field7":"<a href=\"https://www.cvwdwater.com/\" target='_blank' class=''>Go to Site</a>","field8":"855-654-2893","field9":"Approved"},
{"field1":"891","field2":"Cucamonga Valley Water District","field3":"NULL","field4":"91739","field5":"NULL","field6":"855-654-2893","field7":"<a href=\"https://www.cvwdwater.com/\" target='_blank' class=''>Go to Site</a>","field8":"855-654-2893","field9":"Approved"},
{"field1":"892","field2":"Cucamonga Valley Water District","field3":"NULL","field4":"91764","field5":"NULL","field6":"855-654-2893","field7":"<a href=\"https://www.cvwdwater.com/\" target='_blank' class=''>Go to Site</a>","field8":"855-654-2893","field9":"Approved"},
{"field1":"893","field2":"Cucamonga Valley Water District","field3":"NULL","field4":"92336","field5":"NULL","field6":"855-654-2893","field7":"<a href=\"https://www.cvwdwater.com/\" target='_blank' class=''>Go to Site</a>","field8":"855-654-2893","field9":"Approved"},
{"field1":"894","field2":"Fontana Water Company","field3":"NULL","field4":"91739","field5":"NULL","field6":"909-822-2201","field7":"<a href=\"https://www.fontanawater.com/\" target='_blank' class=''>Go to Site</a>","field8":"909-822-2201","field9":"Approved"},
{"field1":"895","field2":"Fontana Water Company","field3":"NULL","field4":"92376","field5":"NULL","field6":"909-822-2201","field7":"<a href=\"https://www.fontanawater.com/\" target='_blank' class=''>Go to Site</a>","field8":"909-822-2201","field9":"Approved"},
{"field1":"896","field2":"Fontana Water Company","field3":"NULL","field4":"92316","field5":"NULL","field6":"909-822-2201","field7":"<a href=\"https://www.fontanawater.com/\" target='_blank' class=''>Go to Site</a>","field8":"909-822-2201","field9":"Approved"},
{"field1":"897","field2":"Fontana Water Company","field3":"NULL","field4":"92335","field5":"NULL","field6":"909-822-2201","field7":"<a href=\"https://www.fontanawater.com/\" target='_blank' class=''>Go to Site</a>","field8":"909-822-2201","field9":"Approved"},
{"field1":"898","field2":"Fontana Water Company","field3":"NULL","field4":"92336","field5":"NULL","field6":"909-822-2201","field7":"<a href=\"https://www.fontanawater.com/\" target='_blank' class=''>Go to Site</a>","field8":"909-822-2201","field9":"Approved"},
{"field1":"899","field2":"Fontana Water Company","field3":"NULL","field4":"92337","field5":"NULL","field6":"909-822-2201","field7":"<a href=\"https://www.fontanawater.com/\" target='_blank' class=''>Go to Site</a>","field8":"909-822-2201","field9":"Approved"},
{"field1":"900","field2":"Monte Vista Water District","field3":"NULL","field4":"91710","field5":"NULL","field6":"909-624-0035","field7":"<a href=\"https://www.mvwd.org/\" target='_blank' class=''>Go to Site</a>","field8":"909-624-0035","field9":"Approved"},
{"field1":"901","field2":"Monte Vista Water District","field3":"NULL","field4":"91762","field5":"NULL","field6":"909-624-0035","field7":"<a href=\"https://www.mvwd.org/\" target='_blank' class=''>Go to Site</a>","field8":"909-624-0035","field9":"Approved"},
{"field1":"902","field2":"Monte Vista Water District","field3":"NULL","field4":"91763","field5":"NULL","field6":"909-624-0035","field7":"<a href=\"https://www.mvwd.org/\" target='_blank' class=''>Go to Site</a>","field8":"909-624-0035","field9":"Approved"},
{"field1":"903","field2":"Monte Vista Water District","field3":"NULL","field4":"91766","field5":"NULL","field6":"909-624-0035","field7":"<a href=\"https://www.mvwd.org/\" target='_blank' class=''>Go to Site</a>","field8":"909-624-0035","field9":"Approved"},
{"field1":"904","field2":"City of Ontario","field3":"City of Ontario","field4":"91761","field5":"NULL","field6":"909 395-2000","field7":"<a href=\"https://www.ontarioca.gov/\" target='_blank' class=''>Go to Site</a>","field8":"909 395-2000","field9":"Approved"},
{"field1":"905","field2":"City of Ontario","field3":"City of Ontario","field4":"91762","field5":"NULL","field6":"909 395-2000","field7":"<a href=\"https://www.ontarioca.gov/\" target='_blank' class=''>Go to Site</a>","field8":"909 395-2000","field9":"Approved"},
{"field1":"906","field2":"City of Ontario","field3":"City of Ontario","field4":"91764","field5":"NULL","field6":"909 395-2000","field7":"<a href=\"https://www.ontarioca.gov/\" target='_blank' class=''>Go to Site</a>","field8":"909 395-2000","field9":"Approved"},
{"field1":"907","field2":"City of Upland","field3":"City of Upland","field4":"91784","field5":"NULL","field6":"909 931-4150","field7":"<a href=\"https://www.uplandca.gov/\" target='_blank' class=''>Go to Site</a>","field8":"909 931-4150","field9":"Pending"},
{"field1":"908","field2":"City of Upland","field3":"City of Upland","field4":"91786","field5":"NULL","field6":"909 931-4150","field7":"<a href=\"https://www.uplandca.gov/\" target='_blank' class=''>Go to Site</a>","field8":"909 931-4150","field9":"Pending"}
];

})(jQuery);
