/**
 * @file
 * Faceted Filter - Adds Search Faceted functionality.
 */

$(function ($) {
  'use strict';

  $(window).on('load', initSearchFacetedFilter);

  function initSearchFacetedFilter() {
    $(document).find('.search-post-list-block .faceted-hide-filter').on('click', function (e) {
      e.preventDefault();

      if ($(this).hasClass('active')) {
        $(this).removeClass('active');
      } else {
        $(this).addClass('active');
      }

      if ($(this).closest('.section-padding').find('.tab-container').hasClass('active')) {
        $(this).closest('.section-padding').find('.tab-container').removeClass('active');
      } else {
        $(this).closest('.section-padding').find('.tab-container').addClass('active');
      }
    });

    $( '.search-faceted-filter-block .faceted-hide-filter' ).on( 'click' , function () {
      var one = $( '.search-faceted-filter-block' ).find( '.tab-container' ).attr( 'class' );
      if( one.indexOf( 'd-none' ) == -1 ) {
        $( '.search-faceted-filter-block .tab-container' ).addClass( 'd-none' );
        $( '.search-faceted-filter-block .faceted-hide-filter span' ).html( 'keyboard_arrow_down' );
      } else {
        $( '.search-faceted-filter-block .tab-container' ).removeClass( 'd-none' );
        $( '.search-faceted-filter-block .faceted-hide-filter span' ).html( 'keyboard_arrow_up' );
      }
    });

    $( '.search-faceted-filter-block .btn-radio' ).on( 'click', function () {
      var one = $( this ).attr( 'class' );
      if( one.indexOf( 'active' ) == -1 ) {
        $( this ).addClass( 'active' );
        $( '.material-icons', this ).html( 'close' );
        $( 'input:radio[name="options"]', this ).attr( 'checked', true );
        showResetFilter();
        return false;
      } else {
        $( this ).removeClass( 'active' );
        $( '.material-icons', this ).html( 'check' );
        $( 'input:radio[name="options"]', this ).attr( 'checked', false );
        showResetFilter();
        return false;
      }
    });

    $( '.search-post-list-block .btn-radio' ).on( 'click', function () {
      var one = $( this ).attr( 'class' );
      if( one.indexOf( 'active' ) == -1 ) {
        $( this ).addClass( 'active' );
        $( '.material-icons', this ).html( 'close' );
        $( 'input:radio[name="options"]', this ).attr( 'checked', true );
        showResetFilter();
        return false;
      } else {
        $( this ).removeClass( 'active' );
        $( '.material-icons', this ).html( 'check' );
        $( 'input:radio[name="options"]', this ).attr( 'checked', false );
        showResetFilter();
        return false;
      }
    });

    function showResetFilter() {
      var cont = 0;
      var cont2 = 0;

      $( '.search-faceted-filter-block .btn-radio' ).each( function () {
        var one = $( this ).attr( 'class' );
        if( one.indexOf( 'active' ) == -1 ) {
          // empty
        } else {
          cont++;
        }
      });

      $( '.search-post-list-block .btn-radio' ).each( function () {
        var one = $( this ).attr( 'class' );
        if( one.indexOf( 'active' ) == -1 ) {
          // empty
        } else {
          cont2++;
        }
      });

      if(cont2 >= 1) {
        $( '.search-post-list-block .apply-search a' ).removeClass( 'd-none' );
      } else {
        $( '.search-post-list-block .apply-search a' ).addClass( 'd-none' );
      }

      if(cont >= 1) {
        $( '.search-faceted-filter-block .apply-search a' ).removeClass( 'd-none' );
      } else {
        $( '.search-faceted-filter-block .apply-search a' ).addClass( 'd-none' );
      }
    }

    $( '.search-faceted-filter-block .reset-filter' ).on( 'click', function ( event ) {
      event.preventDefault();
      $( '.search-faceted-filter-block .btn-radio' ).removeClass( 'active' );
      $( '.search-faceted-filter-block .btn-radio .material-icons').html( 'check' );
      $( 'input:radio[name="options"]').attr( 'checked', false );
      showResetFilter();
    });

    $( '.search-post-list-block .reset-filter' ).on( 'click', function ( event ) {
      event.preventDefault();
      $( '.search-post-list-block .btn-radio' ).removeClass( 'active' );
      $( '.search-post-list-block .btn-radio .material-icons').html( 'check' );
      $( 'input:radio[name="options"]').attr( 'checked', false );
      showResetFilter();
    });
  }
});
