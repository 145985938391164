/**
 * @file
 * Project Page - Add functionality to Read More Module Block.
 */

$(function ($) {
  'use strict';

  $(window).on('load', initReadMoreModule());

  function initReadMoreModule() {
    $(document).on('click', '.hide-filter', function () {

      /**
       * Changes the content to show or hide.
       */
      if ($(this).closest('.content').find('.wysiwyg-field').hasClass('body-hidden')) {
        $(this).closest('.content').find('.wysiwyg-field').removeClass('body-hidden');
        $(this).find('.two').removeClass('d-none');
        $(this).find('.one').addClass('d-none');
      } else {
        $(this).closest('.content').find('.wysiwyg-field').addClass('body-hidden');
        $(this).find('.two').addClass('d-none');
        $(this).find('.one').removeClass('d-none');
      }
    });

    $(document).on('click', '.btn-arrow-below', function () {
      if ($(this).closest('.content').find('.btn-arrow-below').hasClass('active')) {
        $(this).closest('.content').find('.btn-arrow-below').removeClass('active');
      } else {
        $(this).closest('.content').find('.btn-arrow-below').addClass('active');
      }
    });
  }
});
