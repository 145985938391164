/**
 * @file
 * Adds Ajax Form Functionality.
 */

 $.fn.selectpicker.Constructor.BootstrapVersion = '4';

(function ($) {

  'use strict';

  $(window).on('load', function () {
    $(document).find('.umbraco-forms-form').each(function () {
      formInit($(this));
    });
  });

  /*
   * Function to initialize form logic.
   */
  function formInit(form) {
    var selects = $(form).find('select');
    $(selects).each(function () {
      selectInit($(this));
    });

    var datePickers = $(form).find('.date input[type="text"]');
    $(datePickers).each(function () {
      dateInit($(this));
    });
  }

  function dateInit(date) {
    $(date).datetimepicker({
      format: 'L'
    });
  }

  /*
   * Function to initialize select input.
   */
  function selectInit(select) {
    $(select).selectpicker();

    $(select).on('hidden.bs.select', function (e) {
      var target = e.target;
      var parent = $(target).closest('.bootstrap-select');
      var valueSelected = $(parent).find('.dropdown-toggle').attr('title');

      if (valueSelected != 'Select an option') {
        $(target).closest('.umbraco-forms-field-wrapper').addClass('success');
        $(target).closest('.umbraco-forms-field-wrapper').removeClass('error');
      } else {
        $(target).closest('.umbraco-forms-field-wrapper').addClass('error');
        $(target).closest('.umbraco-forms-field-wrapper').removeClass('success');
      }

      $(target).closest('.umbraco-forms-field-wrapper').find('.label-field').html(valueSelected);
    });
  }

  /*
   * Function to detect changes on a div.
   */
  $('body').on('DOMSubtreeModified', '.field-validation-error', function() {
    $(this).closest('.dropdown').find('.umbraco-forms-field-wrapper').addClass('error');
  });

  /*
   * Function to initialize date input.
   */
  function datePickerInit(datepicker) {
    $(datepicker).datepicker({
      format: $(datepicker).attr('placeholder').toLowerCase()
    });
  }

  /**
   * Click event to create a submission with Ajax Functionality.
   */
  $(document).on('submit', '.umbraco-forms-form form', function (e) {
    e.preventDefault();

    var pageID = $(this).closest('html').attr('data-page-id');
    var formContainer = $(this).closest('.newsroom-updates');

    // Get form
    var form = $(this)[0];

    var checkboxList = [];

    $(form).find('.checkboxlist .checkbox-item').each(function () {
      if ($(this).find('input:checked').val() != undefined) {
        checkboxList.push($(this).find('input:checked').val());
      }
    });

    var email = $(form).find('input[type="email"]').val();

    // FormData object
    var data = new FormData(form);
    data.append('pageID', pageID);
    data.append('categories', checkboxList);
    data.append('email', email);

    validateAsEmail(email);
    if (validateAsEmail(email)) {
      $.ajax({
        url: "/umbraco/Api/Forms/InsertData",
        data: data,
        cache: false,
        processData: false,
        contentType: false,
        type: 'POST',
        success: function (dataofconfirm) {
          if (dataofconfirm) {
            if ($(formContainer).length > 0) {
              $(formContainer).find('.formulate-wrapper').remove();
              $(formContainer).find('.sign-body').html("<h3 class='h4'>Thank You!</h3>");
            } else {
              $(form).html("<h3 class='h4'>Thank You!</h3>");
            }
          }
        },
        error: function (error) {
          console.error(error);
        }
      });

      // Clear form
      $(form).find('input[type="text"]').val('');

      // Clear checkboxes
      $(form).find('.checkboxlist .checkbox-item input').prop('checked', false);

      // Clear selects
      $(form).find('select').selectpicker('val', '');

      // Clear datepicker
      $(form).find('.date input[type="text"]').val('');

      // Clear textarea
      $(form).find('textarea').val('');

      // Clear error messages
      $(form).find('.email .field-validation-error').html('').removeClass('field-validation-error').addClass('field-validation-valid');
    } else {
      $(form).find('input[type="email"]').addClass('input-validation-error');
      $(form).find('.email .field-validation-valid').html('Please enter a valid email address.').addClass('field-validation-error');
    }
  });

  function validateAsEmail(email) {
    var re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!re.test(String(email).toLowerCase())) {
      return false;
    }

    return true;
  }
})(jQuery);
