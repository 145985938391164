/**
 * @file
 * Adds Slider Functionality for the Slider at Landing Page.
 */

(function ($) {

  'use strict';

  /**
   * Attach Slider Functionality to Hero Slider Landing Page.
   */
  $(window).on('load', function () {
    $(document).find('.block-slider').each(heroSliderInit);
  });

  /**
   * Initializes Slider for Hero Slider Landing Page.
   */
  function heroSliderInit(index, item) {
    var slides = $(item).find('.slide-items .slide-item');
    if ($(slides).length > 1) {
      var params = {
        slides: slides,
        swipe: true,
        carouselVisible: 1,
        carouselFluid: false,
        speed: 350,
        fx: 'scrollHorz',
        paused: true,
        allowWrap: true,
        log: false,
        pager: $(item).find('.dots'),
        pagerActiveClass: 'pager-active',
        pagerTemplate: '<button data-page="{{slideNum}}" class="pager-dots"><span class="screen-reader-text">.</span></button>'
      };

      $(item).find('.slide-items').cycle(params);
    }

    $($(item).find('.slide-arrows .arrow-left')).on('click', function (e) {
      e.preventDefault();

      $(this).closest('.block-slider').find('.slide-items').cycle('prev');
    });

    $($(item).find('.slide-arrows .arrow-right')).on('click', function (e) {
      e.preventDefault();

      $(this).closest('.block-slider').find('.slide-items').cycle('next');
    });
  }

})(jQuery);
