/**
 * @file
 * Faceted Filters - Adds Faceted Filters Block functionality on videos content.
 */
(function ($) {

  //'use strict';

  /**
  * Attach Faceted Filters Block Init function to Window Load event.
  */
  $(document).on('load', $(document).find('.videos3ColBlock').each(videosThreeColumns));

  /**
   * Setups lightbox.
   */
  var maxWidth = 345;
  var maxHeight = 194;
  var widthwindows = $(document).width();

  if (widthwindows > 375 && widthwindows < 768){
    var sumWidth = widthwindows - 375;
    maxWidth = maxWidth + sumWidth;
    maxHeight = Math.round((194 * maxWidth) / 345);
    maxWidth = maxWidth + 'px';
    maxHeight = maxHeight + 'px';
  }

  if (widthwindows >= 768){
    maxWidth = '95%';
    maxHeight = '85%';
  }

  var cboxOptions = {
    iframe: true,
    width: '95%',
    height: '85%',
    maxWidth: maxWidth,
    maxHeight: maxHeight,
    fixed: true,
    closeButton: true
  };

  function videosThreeColumns(index, item) {
    var element = $(item).find('.video-player-block-list .block-image-text .block-image-container .video-img-container .video-image a');
    $(element).colorbox(cboxOptions);

    $(element).on('click', function (e) {
      e.preventDefault();

      $(this).colorbox(cboxOptions);
    });
  }

})(jQuery);

